import { useQuery } from '@tanstack/react-query';
import React from 'react'
import sql from '../../common/sql';
import { NoutatiEntity } from '../../../../backend/src/shared/entity';
import { useParams } from 'react-router-dom';
import { useLang } from '../../hooks/useLang';
import PageTitle from '../../components/PageTitle';
import { useTranslate } from '../../common/lang';
import Loading from '../../components/Loading/Loading';
import ImageViewer from '../../components/ImageViewer/ImageViewer';

const ContactDetail = () => {
    const { lang } = useLang();
    const translate = useTranslate();
    const pageTitle = translate('Noutăți Admin');
    PageTitle(pageTitle);
    const { id } = useParams<{ id: string }>();
    const [model, setModel] = React.useState<NoutatiEntity>({} as NoutatiEntity);
    const { isLoading, data } = useQuery({
        queryKey: ["NoutatiEntity", id],
        queryFn: () => sql.getEntity("dbo.Noutati", +(id || 0)) as Promise<NoutatiEntity>,
    });

    if (!isLoading && data && model.titlu === undefined) {
        setModel(data);
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div>
            <div className="container">
                <ImageViewer className='mb-4 row w-100' images={JSON.parse(model.poza || "[]")} />

                <div className="mt-4">
                    <h2 className="fw-bold text-primary title-font" dangerouslySetInnerHTML={{ __html: lang === 'ro' ? (model.titlu ?? "") : (model.titlu_en ?? "") }}></h2>
                    <p className="text-muted">{new Date(model.data_adaugarii).toLocaleDateString(navigator.language, { day: '2-digit', month: 'long' })}</p>
                    <p className="lead my-custom-text-style body-font" dangerouslySetInnerHTML={{ __html: lang === 'ro' ? (model.descriere_lunga ?? "") : (model.descriere_lunga_en ?? "") }}></p>

                </div>
            </div>
        </div>
    )
}

export default ContactDetail