import { useQuery } from '@tanstack/react-query';
import React from 'react'
import sql from '../../common/sql';
import { QAEntity } from '../../../../backend/src/shared/entity';
import { useParams } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import { useTranslate } from '../../common/lang';
import Loading from '../../components/Loading/Loading';

const QAAdminDetail = () => {
    const translate = useTranslate();
    const pageTitle = translate('Noutăți Admin');
    PageTitle(pageTitle);
    const { id } = useParams<{ id: string }>();
    const [model, setModel] = React.useState<QAEntity>({} as QAEntity);
    const { isLoading, data } = useQuery({
        queryKey: ["QAEntity", id],
        queryFn: () => sql.getEntity("dbo.QA", +(id || 0)) as Promise<QAEntity>,
    });

    const handleSave = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        await sql.saveEntity("dbo.QA", model);
        window.location.href = "/admin/qa";
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setModel({ ...model, [name]: value });
    }

    if (!isLoading && data && model.Intrebare === undefined) {
        setModel(data);
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <form>
            {/* Grid */}

            <label>
                Intrebare RO
                <textarea
                    name="Intrebare"
                    placeholder="Intrebare..."
                    required
                    rows={5}
                    value={model.Intrebare || ""}
                    onChange={handleChange}
                />
            </label>

            <label htmlFor="raspuns">
                Raspuns RO
                <textarea
                    name="Raspuns"
                    placeholder="Raspuns..."
                    required
                    rows={5}
                    value={model.Raspuns || ""}
                    onChange={handleChange}
                />
            </label>

            <label htmlFor="Intrebare_en">Intrebare EN
                <textarea
                    name="Intrebare_en"
                    required
                    rows={5}
                    placeholder="Intrebare EN ..."
                    value={model.Intrebare_en || ""}
                    onChange={handleChange}
                />

            </label>
            <label htmlFor="message">Raspuns EN
                <textarea
                    name="Raspuns_en"
                    placeholder="Raspuns EN ..."
                    required
                    rows={5}
                    value={model.Raspuns_en || ""}
                    onChange={handleChange}
                >
                </textarea>
            </label>
            <button type="submit" onClick={handleSave}>
                Salvează
            </button>
        </form>
    )
}

export default QAAdminDetail