import React from 'react';

// Define a type for your section data
type SectionData = {
  id: number;
  title: string;
  description: string;
  backgroundImage: string;
};

// Props for the HoverBoxSection component
interface HoverBoxProps {
  sections: SectionData[];
}

// The HoverBoxSection component
const HoverBoxSection: React.FC<HoverBoxProps> = ({ sections }) => {
  return (
    <div className="container clearfix">
      <div className="row clearfix">
        {sections.map((section) => (
          <div key={section.id} className="wpb_column vc_column_container_hoverbox">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div className="vc-hoverbox-wrapper vc-hoverbox-shape--square vc-hoverbox-align--center vc-hoverbox-direction--default vc-hoverbox-width--100">
                  <div className="vc-hoverbox">
                    <div className="vc-hoverbox-inner">
                      <div
                        className="vc-hoverbox-block vc-hoverbox-front"
                        style={{ backgroundImage: `url(${section.backgroundImage})` }}
                      >
                        <div className="vc-hoverbox-block-inner vc-hoverbox-front-inner"></div>
                      </div>
                      <div className="vc-hoverbox-block vc-hoverbox-back">
                        <div className="vc-hoverbox-block-inner vc-hoverbox-back-inner">
                          <p style={{ textAlign: 'center' }}>
                            <span style={{ color: '#ffffff' }}>
                              <strong>{section.title}</strong>
                            </span>
                          </p>
                          <p style={{ textAlign: 'center' }}>
                            <span style={{ color: '#ffffff' }}>{section.description}</span>
                          </p>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HoverBoxSection;
