import React, { ChangeEventHandler } from 'react'
import Fa from '../FontAwesome'
import './FileUpload.scss';
import Lang from '../Lang';
import { Labels } from '../../common/lang';
import { FileAttach } from '../../common/types';
import { Link } from 'react-router-dom';

const FileUpload = ({ files = [] as FileAttach[], multiple = true, title = "Atașează fișiere" as Labels, readonly = false, className = "img-thumbnail", accept = "image/png, image/gif, image/jpeg", onChange = (files: FileAttach[]) => { } }) => {
    const [loading, setLoading] = React.useState<string | boolean>(false);

    const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        upload(e.dataTransfer.files);
    }
    const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    }
    const handleChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
        upload(e.target.files!);
    }
    const handleRemove = (file: FileAttach) => {
        setLoading("se șterge ...");
        // unlink file from server
        fetch('/api/unlink', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ filepath: file.filepath })
        })
            .then(res => res.text())
            .catch(console.error)
            .finally(() => {
                setLoading(false);
                onChange(files.filter(f => f !== file));
            });
    }
    const upload = async (fileList: FileList) => {
        if (!fileList) return;
        setLoading('se atașează ...');
        const uploaded: FileAttach[] = [];
        for (const file of Array.from(fileList)) {
            const fileAttach = await sendFile(file);
            uploaded.push(fileAttach);
        }
        setLoading(false);
        onChange(files.concat(uploaded));
    }
    const images = files.filter(f => f.filepath.match(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i));
    const otherFiles = files.filter(f => !f.filepath.match(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i));
    return (
        <div>
            <div className={`upload-dropzone py-4 ${multiple ? 'multiple' : ''}`} onDrop={handleDrop} onDragEnter={handleDrag} onDragOver={handleDrag}>
                {loading && <i><Fa light='spin fa-sun-bright fa-2xl fa-fw text-primary' /> {loading}</i>}
                {files.length === 0 && readonly === false &&
                    <i><Lang label='Trage fișierele aici sau folosește' />
                        <label className='btn btn-link m-0 p-0 pb-1'>
                            <span><Fa light='paperclip fa-fw fa-xl' /><Lang label={title} /></span>
                            <input id="attachment" type="file" multiple={multiple} accept={accept} onChange={handleChange} />
                        </label>
                    </i>
                }
                {files.length > 0 && !loading && multiple && readonly === false && <label className='btn btn-link'>
                    <i><Fa light='paperclip fa-fw fa-xl' /><Lang label={title} /></i>
                    <input id="attachment" type="file" multiple={multiple} accept={accept} onChange={handleChange} />
                </label>}
                {
                    otherFiles.length > 0 &&
                    <div className={`row ${(readonly || multiple===false) ? '' : 'mt-5'}`}>
                        <ul className='nav'>
                            {otherFiles.map((f, i) =>
                                <li key={i} className='nav-item'>
                                    <a className='nav-link' href={`/${f.filepath}`} target="_blank" rel="noreferrer"
                                        onClick={e => readonly || [e.preventDefault(), handleRemove(f)]}>
                                        {readonly === false && <i><Fa solid='xmark fa-2xl text-danger fafw' /></i>} {f.filename}
                                    </a>
                                </li>

                            )}
                        </ul>
                    </div>
                }
                <div className={`row ${(readonly || multiple===false) ? '' : 'mt-5'}`}>
                    {images.map((f, i) =>
                        <div className={`mb-4 position-relative ${images.length > 1 ? "col-md-4" : ""}`} key={i}>
                            <img src={`/${f.filepath}`} alt={f.filename} className={className} />
                            {!readonly &&
                                <Link to="/" className="img-remove-icon position-top-left" onClick={e => [e.preventDefault(), handleRemove(f)]}>
                                    <i><Fa solid='xmark fa-2xl text-danger' /></i>
                                </Link>
                            }
                        </div>)
                    }
                </div>
            </div>
        </div>
    )
}

export default FileUpload;

const sendFile = async (file: File): Promise<FileAttach> => {
    const uri = '/api/upload';
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        const fd = new FormData();
        xhr.open("POST", uri, true);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                const response = JSON.parse(xhr.responseText).attachment;
                resolve({ filename: response.originalFilename, filepath: response.newFilename }); // handle response.
            } else if (xhr.readyState === 4 && xhr.status !== 200) {
                reject(xhr.responseText);
            }
        };
        fd.append('attachment', file);
        xhr.send(fd);
    });
}

export function filepath(attach = '[{}]' as FileAttach | undefined | string) {
    try {
        if (!attach) return '';
        if (typeof attach === 'string') attach = JSON.parse(attach);
        attach = (attach as any)[0] || attach;
        return (attach as FileAttach).filepath || '';
    } catch (e) {
        return '';
    }
}