import { publish } from "../common/events";
import { Labels } from "../common/lang";

const setModal = (
  content: JSX.Element | Labels | null = null,
  title: JSX.Element | Labels = "" as Labels,
  action?: JSX.Element
) => {
  publish("DialogMessageChanged", content && { content, title, action });
};

export function useModal() {
  return { setModal };
}
