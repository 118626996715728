import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { FormContext } from './Form';
import Lang from './Lang';

const ReviewLeaf = ({ leaf = 0, setLeaf = null as null | ((leaf: number) => void) }) => {
    const formValid = useContext(FormContext);
    const leafs = [1, 2, 3, 4, 5, 6];
    if (setLeaf === null) return (
        <>
            {leafs.map((l, index) => (
                <span key={index} className={`${leaf >= l ? '' : 'opacity-50'}`}>🌿</span>
            ))}
        </>
    );

    return (
        <div>
            <div className='mb-5 py-2 text-large'>
                <input type="number" className='visually-hidden' value={leaf} onChange={e => setLeaf(+e.target.value)} required min={1} max={6} />  {/* pentru validare cu Form.tsx */}
                {
                    leafs.map((l, index) => (
                        <Link key={index} className={`${leaf >= l ? '' : 'opacity-50'}`} to='/' onClick={e => [e.preventDefault(), setLeaf(l)]}> 🌿 </Link>
                    ))
                }
                {!(leaf || formValid) && <small className='text-danger'>&nbsp;<Lang label='« Oferă un scor ca număr de frunze!' /></small>}
            </div>
        </div>
    )
}

export default ReviewLeaf