import React from 'react';
import Lang from './Lang';


const AboutSustainCircuit2 = () => {
  return (
    <section className="l-section wpb_row height_medium with_img">
      <div
        className="l-section-img"
        role="img"
        data-img-width="1375"
        data-img-height="368"
        style={{ backgroundImage: 'url(/images/bg-despre-sustain-2.jpg)', backgroundRepeat: 'no-repeat' }}
      ></div>
      <div className="l-section-h i-cf">
        <div className="g-cols vc_row via_flex valign_top type_default stacking_default">
          <div className="vc_col-sm-6 wpb_column vc_column_container">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div className="wpb_text_column">
                  <div className="wpb_wrapper">
                    <h2><strong><Lang label="DESPRE SUSTAIN CIRCUIT"></Lang></strong></h2>
                    
        <p>
          <Lang label="Actualmente, IMM-urile, în special cele din zonele rurale, au un acces limitat la programe educaționale privind digitalizarea, la trecerea la servicii mai prietenoase cu planeta și la planuri de afaceri mai durabile." ></Lang>
        </p>
        <p>
        <Lang label="Sustain Circuit este un demers cuprinzător, conceput pentru a permite IMM-urilor din zonele rurale, în special cele din din N-V României, să se dezvolte prin transformare digitală și să adopte practici durabile. Prin îmbinarea unor soluții digitale eficiente cu strategii sustenabile, creăm un traseu pentru ca întreprinderile să se dezvolte, reducând în același timp la minimum impactul lor asupra mediului."></Lang>

        </p>
        <p>
        <Lang label="Sustain Circuit va urma 3 direcții principale de acțiune: schimbul de bune practici în domeniul inovării ecologice și al economiei circulare pentru a promova noi modele de afaceri; dobândirea de competențe verzi pentru a introduce factori critici de sustenabilitate  și dezvoltarea de competențe digitale pentru a dezvolta noi oportunități, prezența pe noi piețe și segmente și pentru a crește veniturile."></Lang>
        </p>
        <p>
          <b><Lang label="Resursele Sustain Circuit"></Lang></b>
        </p>
        <ul>
          <li>
          <Lang label="Platforma Sustain Circuit cu scopul de a prezenta întreprinderile mici și mijlocii (IMM-uri) sustenabile și de a sensibiliza consumatorii cu privire la importanța acestora și a economiei circulare."></Lang>
          </li>
          <li>
          <Lang label="Harta sustenabilității Sustain Circuit construită pe baza cunoștințelor și experiențelor împărtășite"></Lang>
          </li>
          <li>
          <Lang label="Cursul de digitalizare pentru a ajuta IMM-urile să se extindă în online"></Lang>
          </li>
          <li>
          <Lang label="Cursul de inițiere în economie circulară și valori sustenabile"></Lang> 
          </li>
          <li>
          <Lang label="Programul de mentorat Sustain Circuit"></Lang> 
          </li>
        </ul>
        <p>
        <Lang label="Sustain Circuit este un proiect finanțat cu sprijinul granturilor acordate de Islanda, Liechtenstein și Norvegia prin mecanismul financiar SEE 2014-2021, în cadrul Programului Dezvoltarea IMM-urilor din România și cu sprijinul granturilor acordate prin mecanismul financiar Norvegian 2014-2021, în cadrul Programului Dezvoltarea IMM-urilor din România."></Lang>
        </p>
        <br></br>
                  </div>
                </div>
                <div className="w-btn-wrapper align_none ">
                  <a className="w-btn us-btn-style_2" href="/newsletter">
                    <span className="w-btn-label"><Lang label="Abonează-te la newsletter"></Lang></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="vc_col-sm-6 wpb_column vc_column_container">
            <div className="vc_column-inner">
              <div className="wpb_wrapper"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSustainCircuit2;
