import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import sql from "../../common/sql";
import { NoutatiEntity } from "../../../../backend/src/shared/entity";
import { Link, useParams } from "react-router-dom";
import Lang from "../../components/Lang";
import PageTitle from "../../components/PageTitle";
import { useLang } from "../../hooks/useLang";
import { useTranslate } from "../../common/lang";
import Loading from "../../components/Loading/Loading";
import ImageViewer from "../../components/ImageViewer/ImageViewer";
import Fa from "../../components/FontAwesome";
import { FileAttach } from "../../common/types";

// Să încarce informații actualizate pe platformă (știri, articole)
// Platforma va permite încărcarea de conținut, precum știri, articole, informații pentru public.

const NoutatiIndex = () => {
  const { fullText = '%' } = useParams();
  const { lang } = useLang();
  const translate = useTranslate();
  const pageTitle = translate("Noutăți");
  PageTitle(pageTitle);

  const { isLoading, data: model } = useQuery({
    queryKey: ["NoutatiIndex", fullText, lang],
    queryFn: () => sql.fulltextNoutatiQuery({ fullText, lang }) as Promise<NoutatiEntity[]>,
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="container">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          {model && model.length ? model.map((item, index) => (
            <div className="col" key={index}>
              <div className="card h-100">
                <ImageViewer images={(JSON.parse(item.poza || "[]") as FileAttach[]).filter((e,i)=>i==0)} />
                <div className="card-body">
                  <h5 className="card-title fw-bold">
                    <Link
                      to={`/noutati/${item.id}`}
                      className="fw-bold text-primary title-font"
                      dangerouslySetInnerHTML={{
                        __html:
                          lang === "ro"
                            ? item.titlu ?? ""
                            : item.titlu_en ?? "",
                      }}
                    ></Link>
                  </h5>
                  <p className="card-text">
                    {new Date(item.data_adaugarii).toLocaleDateString(
                      navigator.language,
                      { day: "2-digit", month: "long" }
                    )}
                  </p>
                  <p className="lead my-custom-text-style body-font">
                  {lang === "ro"
                              ? item.descriere_scurta ?? ""
                              : item.descriere_scurta_en ?? "" }
                  </p>
                </div>
              </div>
            </div>
          )) :
            <h5>
              <Lang label="😔 Nu am găsit nici un articol cu acest subiect sau conținut!" />
            </h5>
          }
        </div>
      </div>
    </div>
  );


};

export default NoutatiIndex;
