import React, { HTMLInputTypeAttribute, useContext } from 'react'
import { Labels, useTranslate } from '../../common/lang'
import Lang from '../Lang'
import { Editor } from '@tinymce/tinymce-react';
import './FormComposer.scss'
import { FileAttach, FormControl, FormOptions, Options } from '../../common/types';
import { FormContext } from '../Form';
import FileUploadDialog from '../FileUpload/FileUploadDialog';

const FormElement = ({ label = "" as Labels, description = "" as Labels, options = [] as FormOptions, value = "", formControl = "input" as FormControl, inputType="text" as HTMLInputTypeAttribute, onChange = (value: string) => { }, required = true, hideWhen = false }) => {
    const translate = useTranslate();
    const [unique] = React.useState(Math.random().toString(36).substring(7));
    const [touched, setTouched] = React.useState(false);
    const valid = useContext(FormContext);
    const validate = (!valid || touched) ? "validate" : "";
    const [files, setFiles] = React.useState(null as FileAttach[] | null);
    const [callback] = React.useState({} as any);
    const pattern = formControl === 'input' && inputType === 'email' ? "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" : undefined;
    const handleChange = (files: FileAttach[] | null) => {
        if (callback.success) {
            const baseUrl = window.location.protocol + '//' + window.location.host;
            callback.success((files || []).map(f => ({ title: f.filename, value: `${baseUrl}/${f.filepath}` })));
        }
        setFiles(null);
    }
    if (hideWhen) return null;
    if (formControl === 'readonly' && options.length)
        return <label className='readonly'>
            <div className='text-muted'><i><small>{translate(label)}</small></i></div>
            <div className='text-large'> {options.find(e => e.value === value)?.label}</div>
        </label>;
    if (formControl === 'readonly')
        return <label className='readonly'>
            <div className='text-muted'><i><small>{translate(label)}</small></i></div>
            <div className='text-large' dangerouslySetInnerHTML={{ __html: value }}></div>
        </label>;
    return (
        <Lang label={label} required={required}>
            {formControl === 'textarea' && <>
                <textarea rows={3} value={value} onChange={e => onChange(e.target.value)} required={required} onFocus={e => setTouched(true)} className={validate}></textarea>
                {description && <small className='form-text text-muted'>{translate(description)}</small>}</>
            }
            {formControl === 'input' && <input type={inputType} pattern={pattern} value={value} onChange={e => onChange(e.target.value)} required={required} onFocus={e => setTouched(true)} className={validate} />}
            {formControl === 'tinymce' &&
                <Editor
                    value={value}
                    init={{
                        height: 300,
                        menubar: false,
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
                            'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                        ],
                        toolbar: `
                            undo redo | formatselect | bold italic backcolor | 
                            alignleft aligncenter alignright alignjustify | 
                            bullist numlist outdent indent | removeformat | link image`,
                        link_list: function (success: CallableFunction) { // called on link dialog open
                            setFiles([]); // open upload file dialog
                            console.log('link_list', success);
                            callback.success = success;
                        },
                        image_list: function (success: CallableFunction) { // called on link dialog open
                            setFiles([]); // open upload file dialog
                            console.log('image_list', success);
                            callback.success = success;
                        },
                    }}
                    onEditorChange={onChange}
                />
            }
            {files && <FileUploadDialog files={files} onChange={handleChange} />}
            {
                ['options', 'yesno'].includes(formControl) &&
                <fieldset>
                    {!valid && !value && <small className='form-text text-danger'>{translate('Alegeți o opțiune!')}</small>}
                    {
                        (options.length ? options : Options.YesNo)
                            .map((option, index) => <div key={index} className="form-check">
                                <label className="form-check-label" htmlFor={`options_${option.value}_${unique}`}>
                                    <input required={required} className="form-check-input" type="radio" name={`optionsRadios_${unique}`} id={`options_${option.value}_${unique}`} value={option.value} checked={value === option.value} onChange={e => onChange(e.target.value)} />
                                    {translate(option.label as Labels)}
                                </label>
                            </div>)
                    }
                </fieldset>
            }
        </Lang>
    )
}

export default FormElement