import { GoogleMap, LoadScript } from '@react-google-maps/api';

const MapContainer = ({ lat = 47.658, lng = 23.575, zoom = 12, onMapClick = (e: google.maps.MapMouseEvent) => { }, children = undefined as JSX.Element | undefined }) => {
  
  const mapStyles = {
    height: '80vh',
    width: '100%',
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyB0yDppabp_06WSnnRpCdfTo6mPuaIpSIA">
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={zoom}
        center={{ lat, lng }}
        onClick={onMapClick}
        options={
          {
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            zoomControl: true,
            scaleControl: true,
            rotateControl: true,
            scrollwheel: true,
            draggable: true,
            disableDoubleClickZoom: false,
            maxZoom: 20,
            minZoom: 0,
            clickableIcons: true,
            gestureHandling: "auto",
            keyboardShortcuts: true,
          }}
      >
        {children && children}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapContainer;
