import React from "react";
import SearchBox from "./SearchBox";
import "./Search.scss";
import { useDebouncedCallback } from "use-debounce";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useLang } from "../../hooks/useLang";
import sql from "../../common/sql";
import { NoutatiEntity } from "../../../../backend/src/shared/entity";
import { useTranslate } from "../../common/lang";
import Lang from "../../components/Lang";

const SearchContainer = ({
  backgroundImage = "/images/sustain-background.jpg",
}) => {
  const [search, setSearch] = React.useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { lang } = useLang();

  const handleSearch = useDebouncedCallback(
    () => navigate(`/noutati-search/${search || "%"}`),
    500
  );
  const { isLoading, data } = useQuery({
    queryKey: ["NoutatiIndex", lang],
    queryFn: () =>
      sql.fulltextNoutatiQuery({ fullText: "%", lang }) as Promise<
        NoutatiEntity[]
      >,
  });
  const allTags = (
    data
      ?.filter((e) => (lang === "ro" ? e.tags : e.tags_en))
      .filter((e, i) => i < 5)
      .map((e) => (lang === "ro" ? e.tags : e.tags_en)) || []
  )
    .join(",")
    .split(",");

  const uniqueTags = [...new Set(allTags.map((item) => item))];
  if (location.pathname.includes("/map")) return null;
  return (
    <div
      className="d-flex flex-column justify-content-end align-items-start"
      role="search"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className="container">
        <h1 className="text-light">
        
      
        </h1>

        <form action="" role="search">
          <SearchBox
            value={search}
            onChange={(e) => [setSearch(e), handleSearch()]}
          />
          <div className="mt-3 d-flex justify-content-between">
            <div className="d-flex flex-wrap">
              {uniqueTags.map((tag, i) => (
                <span
                  key={i}
                  className="badge rounded-pill text-bg-success opacity-75 p-2 px-3 me-2"
                >
                  <Link
                    className="text-light opacity-75-"
                    to={`/noutati-search/${tag}`}
                    onClick={() => setSearch(tag)}
                  >
                    {tag}
                  </Link>
                </span>
              ))}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchContainer;
