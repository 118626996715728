import { Labels } from "../common/lang";
import { FormDataItem, Options } from "../common/types";

const goals: Array<{ logo: string, title: Labels, sample: Labels, recenzie: Array<FormDataItem> }> = [
    {
        logo: '/images/E_SDG-goals_icons-individual-rgb-02.png',
        title: 'OBIECTIVUL 2: FĂRĂ FOAMETE',
        sample: `(Exemple de acțiuni: furnizorii tăi sunt producători locali, îți cultivi o parte din alimente singur(ă), ai o varietate de opțiuni vegetariene în meniu)`,
        recenzie: [
            {
                label: 'În ce măsură contribuie această afacere la asigurarea unor opțiuni alimentare nutritive și sustenabile? (oferă doar fructe/legume de sezon, își cultivă propriile legume și fructe, face alegeri responsabile în ceea ce privește fructele de mare, pune accent pe un meniu bazat pe plante)',
                control: "options",
                options: Options.ZeroToHero,
            },
            {
                label: 'În ce măsură contribuie această afacere pentru a reduce risipa de alimente? (donarea surplusului de alimente, compostarea, evitarea pregătirii unei cantități excesive, cum ar fi bufetele suedeze)',
                control: "options",
                options: Options.ZeroToHero,
            },
            {
                label: 'În ce măsură sprijină această afacere producătorii locali? (procurarea ingredientelor de la fermierii locali pentru a sprijini agricultura la scară mică și pentru a reduce distanța parcursă de alimente)',
                control: "options",
                options: Options.ZeroToHero,
            },
        ]
    },
    {
        logo: '/images/E_SDG-goals_icons-individual-rgb-05.png',
        title: 'OBIECTIVUL 5: EGALITATE DE GEN',
        sample: `(Exemple de acțiuni: ești conștient(ă) cu privire la stereotipurile de gen și cum să le eviți)`,
        recenzie: [
            {
                label: 'Pe baza observațiilor dumneavoastră, cât de divers era personalul acestei întreprinderi (număr echilibrat de femei și bărbați, persoane din categorii vulnerabile)?',
                control: "options",
                options: Options.ZeroToHero,
            },
            {
                label: 'Această afacere are femei în poziții de conducere?',
                control: "options",
                options: Options.YesNoOptional,
            }
        ]
    },
    {
        logo: '/images/E_SDG-goals_icons-individual-rgb-06.png',
        title: 'OBIECTIVUL 6: APĂ CURATĂ ȘI SANITAȚIE',
        sample: `(Exemple de acțiuni: depui eforturi pentru utilizarea eficientă a apei, tratarea apelor reziduale, tehnologii de reciclare și reutilizare)`,
        recenzie: [
            {
                label: 'În ce măsură credeți că această întreprindere acordă prioritate utilizării și conservării responsabile a apei? (are instalate echipamente de economisire a apei, cum ar fi robinete, toalete și dușuri cu debit redus, sisteme de colectare a apei de ploaie, utilizează apă reciclată în diverse scopuri nepotabile, cum ar fi amenajarea grădinii, spălarea toaletelor)',
                control: "options",
                options: Options.ZeroToHero,
            },
            {
                label: 'Credeți că această afacere ia măsuri pentru a minimiza poluarea apei? (evită utilizarea paielor, paharelor de unică folosință, a sticlelor de apă sau a tacâmurilor din plastic, încurajează oaspeții să refolosească prosoapele și lenjeria de pat, sensibilizează turiștii cu privire la importanța conservării apei și a prevenirii poluării, deține etichete ecologice și certificări de turism durabil)',
                control: "options",
                options: Options.ZeroToHero,
            }
        ]
    },
    {
        logo: '/images/E_SDG-goals_icons-individual-rgb-07.png',
        title: 'OBIECTIVUL 7: ENERGIE CURATĂ ȘI LA PREȚURI ACCESIBILE',
        sample: `(Exemple de acțiuni: utilizezi baterii reîncărcabile, ai programe automate de închidere a aerului condiționat/sistemelor de încălzire atunci când nu sunt necesare, utilizezi panouri solare pentru încălzire și electricitate)`,
        recenzie: [
            {
                label: 'În ce măsură credeți că această afacere încorporează surse de energie curată și regenerabilă în operațiunile sale? (panouri solare, centrale geotermale, energie din biomasă generată din materiale organice, cum ar fi lemnul, reziduuri agricole, deșeuri de biomasă, turbine eoliene)',
                control: "options",
                options: Options.ZeroToHero,
            },
            {
                label: 'În ce măsură credeți că această afacere face eforturi pentru a reduce consumul de energie sau pentru a promova eficiența energetică? (utilizarea de aparate și sisteme de iluminat eficiente din punct de vedere energetic, contoare inteligente disponibile, senzori și sisteme de automatizare pentru a monitoriza și controla consumul de energie în timp real - pentru sistemul de încălzire, ventilație)',
                control: "options",
                options: Options.ZeroToHero,
            }
        ]
    },
    {
        logo: '/images/E_SDG-goals_icons-individual-rgb-08.png',
        title: 'OBIECTIVUL 8: MUNCĂ DECENTĂ ȘI CREȘTERE ECONOMICĂ',
        sample: `(Exemple de acțiuni: promovezi turismul durabil care creează locuri de muncă și promovează cultura și produsele locale, oferi remunerare egală pentru munca de valoare egală, promovezi un mediu de lucru sigur și securizat pentru toți lucrătorii, inclusiv lucrătorii migranți)`,
        recenzie: [
            {
                label: 'În ce măsură credeți că această afacere sprijină creșterea economică și crearea de locuri de muncă în cadrul comunității (angajații erau în principal rezidenți din acea regiune)?',
                control: "options",
                options: Options.ZeroToHero,
            },
            {
                label: 'Ați observat dacă angajații din această întreprindere sunt expuși la riscuri sau protecție insuficientă la locul de muncă?',
                control: "options",
                options: Options.YesNoOptional,
            }
        ]
    },
    {
        logo: '/images/E_SDG-goals_icons-individual-rgb-09.png',
        title: 'OBIECTIVUL 9: INDUSTRIE, INOVAȚIE ȘI INFRASTRUCTURĂ',
        sample: `(Exemple de acțiuni: te implici în cercetarea, dezvoltarea și implementarea de produse, servicii și modele de afaceri pentru a furniza o infrastructură durabilă și rezistentă, investești în înbunătățirea și modernizarea infrastructurii și a activelor industriale în cadrul operațiunilor proprii și al lanțului de aprovizionare pentru a le face durabile și rezistente.)`,
        recenzie: [
            {
                label: 'În ce măsură credeți că această afacere face investiții sau are inițiative desfășurate pentru a îmbunătăți infrastructura și pentru a sprijini dezvoltarea durabilă?',
                control: "options",
                options: Options.ZeroToHero,
            }
        ]
    },
    {
        logo: '/images/E_SDG-goals_icons-individual-rgb-11.png',
        title: 'OBIECTIVUL 11: ORAȘE ȘI COMUNITĂȚI SUSTENABILE',
        sample: `(Exemple de acțiuni: sprijini inițiative, asociații sau fundații implicate în dezvoltarea orașelor și comunităților sustenabile prin donații sau alte resurse, participi la construcția sau renovarea locuințelor, la amenajarea spațiilor publice sau a spațiilor verzi, încurajezi transportul sustenabil – biciclete, mașini electrice)`,
        recenzie: [
            {
                label: 'În ce măsură credeți că această afacere contribuie la crearea unui mediu durabil și favorabil incluziunii în zonele urbane?',
                control: "options",
                options: Options.ZeroToHero,
            },
            {
                label: 'În ce măsură credeți că această afacere ia măsuri pentru a răspunde nevoilor comunității sau pentru a promova sustenabilitatea mediului?',
                control: "options",
                options: Options.ZeroToHero,
            }
        ]
    },
    {
        logo: '/images/E_SDG-goals_icons-individual-rgb-12.png',
        title: 'OBIECTIVUL 12: CONSUM ȘI PRODUCȚIE RESPONSABILE',
        sample: `Exemple de acțiuni: faci achiziții de la companii despre care știi că au practici sustenabile și care nu dăunează mediului, alegi/ oferi produse reutilizabile, reduci consumul de ambalaje, ești atent la managementul ecologic al substanțelor chimice și a tuturor deșeurilor pe parcursul ciclului de viață al acestora )`,
        recenzie: [
            {
                label: 'Cât de ecologică credeți că este această afacere prin oferta sa de produse și servicii? (cafea, ceai și ciocolată din comerț echitabil provenite de la producători responsabili din punct de vedere ecologic și social; fructe și legume organice cultivate fără pesticide sau îngrășăminte sintetice; oferă biciclete, e-bikes și scutere de închiriat în locul mașinilor; produsele lor sunt fabricate din bumbac organic, cânepă, bambus sau materiale reciclate)?',
                control: "options",
                options: Options.ZeroToHero,
            },
            {
                label: 'În ce măsură credeți că această afacere promovează consumul sustenabil și reducerea deșeurilor? (opțiuni prietenoase cu mediul înconjurător, cum ar fi bunuri organice, de proveniență locală sau produse în mod etic, utilizarea unui număr minim de ambalaje, optarea pentru materiale reciclabile sau biodegradabile sau oferirea de opțiuni fără ambalaje, programe de reciclare disponibile, inițiative de compostare sau eforturi pentru a minimiza generarea de deșeuri)',
                control: "options",
                options: Options.ZeroToHero,
            }
        ]
    },
];

const review: Array<FormDataItem> = [
    {
        label: 'Platforma are un design atractiv (culori, design, fontul și culoarea textului, imagini).',
        control: 'options',
        options: Options.OneToFive,
    },
    {
        label: 'Platforma este ușor de navigat și de utilizat.',
        control: 'options',
        options: Options.OneToFive,
    },
    {
        label: 'Resursele disponibile pe platformă sunt utile și ușor de înțeles.',
        control: 'options',
        options: Options.OneToFive,
    },
    {
        label: 'Traseul sustenabilității și harta sunt ușor de navigat pe platformă.',
        control: 'options',
        options: Options.OneToFive,
    },
    {
        label: 'Platforma este accesibilă și adaptată pentru persoanele cu dizabilități.',
        control: 'options',
        options: Options.OneToFive,
    },
    {
        label: 'Vă rugăm să descrieți punctele forte și punctele slabe ale platformei care vizează promovarea de afaceri sustenabile.',
        control: 'tinymce'
    },
    {
        label: 'Ce secțiuni ați îmbunătăți pe platformă și în ce mod?',
        control: 'tinymce'
    },
    {
        label: 'A fost ușor să vă înregistrați afacerea pe platformă.',
        control: 'options',
        options: Options.OneToFive,
    },

]


const profile: Array<FormDataItem> = [
    {
        label: '1.	Care sunt activitățiile concrete prin care afacerea ta contribuie la îndeplinirea obiectivelor menționate mai sus?',
        description: 'Te rugăm să le descrii. (ex. afacerea mea combate poluarea cu plastic prin folosirea exclusivă a paielor din carton în activitatea zilnică)',
        value: '',
        control: 'textarea',
    },
    {
        label: '2.	Îți dorești ca în viitorul apropiat să implementezi alte activități corelate cu atitudinea sustenabilă a afacerii tale? Dacă da, care ar fi acestea?',
        control: 'textarea',
        value: '',
    },
    {
        label: '3.	Dispune afacerea ta de o strategie în ceea ce privește sustenabilitatea?',
        control: 'yesno',
        value: '',
    },
    {
        label: '4.	Realizezi în prezent o analiză a impactului pe care îl are afacerea ta din punct de vedere social, economic și al mediului înconjurător?',
        control: 'yesno',
        value: '',
    },
    {
        label: '5.	Ai vreo certificare care atestă demersurile sustenabile pe care le întreprinde afacerea ta? Daca da, care sunt acelea? (ex. <a target="_blank" href="https://environment.ec.europa.eu/topics/circular-economy/eu-ecolabel_en">EU Ecolabel - Home (europa.eu)</a> )',
        control: 'textarea',
        value: '',
    }
]



export const useData = () => {
    return { goals, profile, review }
}