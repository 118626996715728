import { Link } from "react-router-dom";
import Input from "../../components/Input";
import LoginContainer from "../../components/LoginContainer";
import { SyntheticEvent, useState } from "react";
import ErrorBox from "../../components/ErrorBox";
import Title from "../../components/Title";
import { SqlError } from "../../common/sql";
import { passwordRecover } from "../../common/user";

const PasswordRecoverPage = () => {
  const [email, setEmail] = useState("");
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState<SqlError>();
  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    passwordRecover(email)
      .then(() => {
        setCompleted(true);
      })
      .catch(setError);
  };
  return (
    <LoginContainer>
      <form>
        <Title>Recuperare parolă</Title>
        {!completed && (
          <fieldset>
            <Input
              type="email"
              name="email"
              required
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError(undefined);
              }}
            >
              Adresa de email
            </Input>
            <p>
              <small>Vei primi un email cu link pentru resetare parolă</small>
            </p>
            <ErrorBox>{error}</ErrorBox>
            <button type="submit" onClick={handleSubmit}>
              Trimite email
            </button>
          </fieldset>
        )}
        {completed && <span>
          Un email a fost trimis la {email}. Verifică dacă ai primit email-ul în Inbox sau în folderul Spam (email nedorit)
        </span>}
      </form>
      <p>
        <small>
          <Link to="/login"> înapoi la conectare</Link>
        </small>
      </p>
    </LoginContainer>
  );
};

export default PasswordRecoverPage;
