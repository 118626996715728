import { useQuery } from '@tanstack/react-query';
import React from 'react'
import sql from '../../common/sql';
import { ContactEntity } from '../../../../backend/src/shared/entity';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';

const ContactDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [model, setModel] = React.useState<ContactEntity>({} as ContactEntity);
  const { isLoading, data } = useQuery({
    queryKey: ["ContactEntity", id],
    queryFn: () => sql.getEntity("dbo.Contact", +(id || 0)) as Promise<ContactEntity>,
  });

  const handleSave = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    await sql.saveEntity("dbo.Contact", model);
    window.location.href = "/contact";
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setModel({ ...model, [name]: value });
  }

  if (!isLoading && data && model.Nume === undefined) {
    setModel(data);
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <form>
      {/* Grid */}
      <div className="grid">
        <label>
          Nume și prenume
          <input
            type="text"
            name="Nume"
            placeholder="Nume și prenume..."
            required
            value={model.Nume || ""}
            onChange={handleChange}
          />
        </label>

        <label htmlFor="lastname">
          Telefon
          <input
            type="text"
            name="Telefon"
            placeholder="Telefon..."
            required
            value={model.Telefon || ""}
            onChange={handleChange}
          />
        </label>
      </div>

      <label htmlFor="email">Adresa de Email
        <input
          type="email"
          name="Email"
          required
          placeholder="Email..."
          value={model.Email || ""}
          onChange={handleChange}
        />
        <small>We'll never share your email with anyone else.</small>
      </label>
      <label htmlFor="message">Message
        <textarea
          name="Message"
          placeholder="Message..."
          required
          rows={5}
          value={model.Message || ""}
          onChange={handleChange}
        >
        </textarea>
      </label>
      <button type="submit" onClick={handleSave}>
        Salvează
      </button>
    </form>
  )
}

export default ContactDetail