import { Link } from "react-router-dom";
import AccountConfig from "../../components/AccountConfig";
import { useUser } from "../../hooks/useUser";
import ProfilOrganizatie from "./ProfilOrganizatie";
import AdminIndexPage from "../admin/AdminIndexPage";

const AccountIndex = () => {
  const { user } = useUser();

  if (user.role === "admin") return <> <AccountConfig /> <AdminIndexPage /> </>;
  if (user.role === "user") return <> <AccountConfig /> <ProfilOrganizatie /> </>;
  return (
    <>
      <p>
        Trebuie să fii autentificat pentru a accesa{" "}
        <strong>Setări utilizator</strong> !
      </p>
      <Link to="/login">Autentificare</Link>
    </>
  );
};

export default AccountIndex;
