import React from "react";
import { useLang } from "../hooks/useLang";
import Lang from "./Lang";
import SearchContainer from "./SearchSite/SearchContainer";
import AboutSustainCircuit2 from "./AboutSustainCircuit2";
import WordSlider from "./WordSlider";
const words = ["Susține", "Dezvoltă", "Creează", "Susține"];
const wordsEN = ["Support", "Develop", "Create", "Support"];

const AboutSustainCircuit = () => {
  const { lang } = useLang();
  const wordsToUse = lang === 'en' ? wordsEN : words;
  return (
    <div>
      <h3>
        <Lang label="Pornește într-o călătorie verde de neuitat!" />{" "}
      </h3>
      <br></br>

      <p>
        <Lang label="Explorează selecția noastră de destinații turistice durabile, unde peisaje uluitoare se întâlnesc cu practici de călătorie responsabile. De la cabane / pensiuni construite în mijlocul naturii, la proiecte deconservare conduse de comunități, fiecare destinație de pe lista noastră este un exemplu al sustenabilității." />
      </p>
      <p>
        <Lang label="Descoperă frumusețea țării noastre într-un mod care protejează și îmbunătățește mediul înconjurător." />
      </p>
      <p>
        <Lang label="Aventura ta începe aici!" />
      </p>
      
      <div
        className="d-flex flex-column justify-content-end align-items-start border p-3 rounded-2"
        role="search"
        style={{
          backgroundImage: "url(/images/bg-home-search.jpg)",
        }}
      >
        <div className="container">
          <h1 className="text-light">
            <WordSlider words={wordsToUse} /> <Lang label="un viitor verde, competitiv și incluziv!" />
            
            <br></br>
            <br></br>
          </h1>
        </div>
      </div>
      <p>
        <Lang label="Având un impact direct asupra mediului înconjurător, turismul joacă un rol important în menținerea peisajelor verzi și nealterate. Așadar, pentru ca generațiile viitoare să se poată bucura de aceleași cadre autohtone pline de viață și tradiție, o planificare responsabilă a călătoriilor, cât și dezvoltarea unor experiențe turistice sustenabile sunt necesare." />
      </p>
      <p>
        <Lang label="Turismul durabil ține cont de trei aspecte centrale: susținerea beneficiilor economice și sociale ale comunităților locale, protejarea patrimoniului cultural și protejarea mediului natural. O activitate turistică responsabilă ar trebui să aibă un impact pozitiv asupra acestor trei direcții și în același timp, să reducă impactul negativ asupra resurselor naturale și a comunității locale." />
      </p>
      <p>
        <Lang label="Harta Sustain Circuit te poate ghida în realizarea unor alegeri sustenabile cu privire la elementele principale ale unei călătorii prin intermediul unei interfețe interactive ce cuprinde: unități de cazare, activități de recreere și experiențe culinare. Fiecare profil înregistrat pe platformă aparține unei afaceri care se ghidează după anumite principii sustenabile și contribuie activ prin activitatea sa la Obiectiectivele Dezvoltării Durabile. Explorând harta, vei avea posibilitatea să iți construiești propriul circuit sustenabil în funcție de interesele tale." />
      </p>
      <div className="about-sustain border p-3 rounded-2">
      <AboutSustainCircuit2 /> 
      </div>
     </div>
  );
};

export default AboutSustainCircuit;
