import { useQuery } from '@tanstack/react-query';
import React from 'react'
import sql from '../../common/sql';
import { OrganizatieEntity } from '../../../../backend/src/shared/entity';
import Loading from '../../components/Loading/Loading';
import { Link } from 'react-router-dom';
import Fa from '../../components/FontAwesome';
import Lang from '../../components/Lang';
import { useTranslate } from '../../common/lang';
import PageTitle from '../../components/PageTitle';

const AdminEvaluareProfilSumar = () => {
    const translate = useTranslate();
    const pageTitle = translate("Evaluare profil organizație");
    PageTitle(pageTitle);
    const { isLoading, data } = useQuery({
        queryKey: ["evaluareProfilOrganizatieQuery"],
        queryFn: () => sql.evaluareProfilOrganizatieQuery() as Promise<OrganizatieEntity[]>,
        refetchOnWindowFocus: false,
    });
    if (isLoading || !data) return <Loading />;
    return (
        <table>
            <thead>
                <tr>
                    <th className='text-center'><Lang label='Aprobat' /></th>
                    <th><Lang label='Organizație' /></th>
                    <th><Lang label='Contact' /></th>
                    <th><Lang label='Descriere' /></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {data.map((row, index) => <tr key={index}>
                    <td className='text-center'>
                        <Link to={`/admin/evaluare-profil/${row.Id}`}>
                            {row.Inrolat === null ?
                                <> <Fa solid='circle-question fa-xl' /><br /> <Lang label='Evaluează' /></> :
                                <>
                                    <Fa solid={row.Inrolat ? 'check-double text-success fa-xl' : 'hand text-danger fa-xl'} /> <br />
                                    <Lang label={row.Inrolat ? 'Aprobat' : 'Respins'} />
                                </>}
                        </Link>
                    </td>
                    <td>{row.Nume}</td>
                    <td>{row.Telefon}<br />{row.Email}</td>
                    <td dangerouslySetInnerHTML={{ __html: row.Promovare || row.Descriere }}></td>
                    <td className='text-center'><Link to={`/admin/completare-profil/${row.Id}`}><Fa light='money-check-pen fa-xl' /><Lang label='Modifică' /></Link></td>
                </tr>)}
            </tbody>
        </table>
    )
}

export default AdminEvaluareProfilSumar