import React, { useState } from 'react'
import Lang from './Lang';
import Fa from './FontAwesome';

const Disclaimer = () => {
    const [visible, setVisible] = useState(true);
    return (
        visible ? <nav>
            <div className="w-100 alert m-0" role="alert">
                <div className="text-center">
                    <Fa solid='circle-info fa-2xl fa-fw' />
                    <Lang label="În timp ce ne străduim să prezentăm numai întreprinderi durabile pe platforma Sustain Circuit, nu ne putem asuma nici o responsabilitate, expresă sau implicită, cu privire la acuratețea datelor furnizate de acestea." />
                </div>
            </div>
        </nav> : null
    )   
}

export default Disclaimer