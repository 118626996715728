import React, { useEffect, useState } from 'react'
import { Waypoint } from '../common/types';
import { DirectionsRenderer, DirectionsService, InfoWindow, Marker } from '@react-google-maps/api';
import { useQuery } from '@tanstack/react-query';
import sql from '../common/sql';
import { OrganizatieEntity } from '../../../backend/src/shared/entity';
import Loading from './Loading/Loading';
import { filepath } from './FileUpload/FileUpload';
import Lang from './Lang';
import { Link } from 'react-router-dom';
import ObjectiveSelector from './Selectors/ObjectiveSelector';
import Fa from './FontAwesome';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { useTranslate } from '../common/lang';
const defaultPoints = '[4,1002,3,2,1003,5,6]';
const MapCircuit = () => {
    const [selectedWaypoints, setSelectedWaypoints] = useState<number[]>(JSON.parse(localStorage.getItem('selectedWaypoints') || defaultPoints));
    const [waypoint, setWaypoint] = useState<Waypoint | null>(null);
    const [response, setResponse] = useState(null);
    const [routeInfo, setRouteInfo] = useState({ distance: "", duration: "" });
    const translate = useTranslate();

    // const defaultCircuit = useQuery({
    //     queryKey: ["defaultCircuitQuery"],
    //     queryFn: () => sql.defaultCircuitQuery() as Promise<[OrganizatieEntity]>,
    //     refetchOnWindowFocus: false,
    // });

    const organizatiiAprobate = useQuery({
        queryKey: ["profilAprobatOrganizatieQuery"],
        queryFn: () => sql.profilAprobatOrganizatieQuery() as Promise<[OrganizatieEntity]>,
        refetchOnWindowFocus: false,
    });

    const directionsCallback = (res: any) => {
        if (res !== null && response === null) {
            setResponse(res);
        }
    };

    useEffect(() => {
        localStorage.setItem('selectedWaypoints', JSON.stringify(selectedWaypoints?.length ? selectedWaypoints : JSON.parse(defaultPoints)));
        if (!selectedWaypoints?.length) setSelectedWaypoints(JSON.parse(defaultPoints));
    }, [selectedWaypoints]);

    if (organizatiiAprobate.isLoading) return <Loading />;

    // lat between 45.0 and 50.0
    // lng between 21.0 and 26.0
    const coordonateValide = (point: { lat: number, lng: number }) => point.lat && point.lng && point.lat > 43.0 && point.lat < 50.0 && point.lng > 21.0 && point.lng < 28.0;

    const locations = organizatiiAprobate
        .data?.filter(p => coordonateValide(JSON.parse(p.Locatie)))
        .map((item) => {
            return {
                location: JSON.parse(item.Locatie),
                name: item.Nume,
                sigla: filepath(item.Sigla),
                promovare: item.Promovare || item.Descriere,
                obiectiv: item.Obiectiv,
                rezervare: item.Rezervare
                    ? <a href={item.Rezervare} target="_blank" rel="noreferrer"><Lang label="Fă o rezervare" /></a>
                    : <a href={`tel:${item.Telefon}`}><Lang label='Rezervări la telefon ' /> {item.Telefon}</a>,
                id: item.Id,
                website: item.Website,
                marker: `/images/markers/${item.Marker}`
            }
        });

    const waypoints = organizatiiAprobate
        .data?.filter(p => JSON.parse(p.Locatie).lat).filter(p => (selectedWaypoints.length ? selectedWaypoints : JSON.parse(defaultPoints)).includes(p.Id))
        .map((item) => {
            return {
                location: JSON.parse(item.Locatie),
                name: item.Nume,
                sigla: filepath(item.Sigla),
                promovare: item.Promovare || item.Descriere,
                obiectiv: item.Obiectiv,
                rezervare: item.Rezervare
                    ? <a href={item.Rezervare} target="_blank" rel="noreferrer"><Lang label="Fă o rezervare" /></a>
                    : <a href={`tel:${item.Telefon}`}><Lang label='Rezervări la telefon ' /> {item.Telefon}</a>,
                id: item.Id,
                website: item.Website,
                marker: `/images/markers/${item.Marker}`
            }
        });

    if (selectedWaypoints?.length) {
        waypoints?.sort((a, b) => selectedWaypoints.indexOf(a.id) - selectedWaypoints.indexOf(b.id));
    }

    const filterWaypoints = (waypoints: Waypoint[]) => {
        const points = waypoints.filter(e => e.location.lat && e.location.lng).map(e => ({ location: e.location, stopover: false }));
        return points;
    }

    const originPoint = (waypoints: Partial<Waypoint>[]) => {
        return waypoints[0].location || { lat: 0, lng: 0 };
    }
    const destinationPoint = (waypoints: Partial<Waypoint>[]) => {
        return waypoints[waypoints.length - 1].location || { lat: 0, lng: 0 };
    }
    console.log(waypoints);

    function routeDistanceAndDuration(travelMode = google.maps.TravelMode.DRIVING) {
        let distance = 0;
        let duration = 0;
        if (!(waypoints && waypoints.length)) return;
        const waypointsObjects = waypoints.map(waypoint => ({
            location: waypoint.location,
            stopover: true, // Allow stop at waypoint
        }));
        function formatDuration(durationInMinutes: number) {
            const hours = Math.floor(durationInMinutes / 60);
            const minutes = durationInMinutes % 60;
            let hoursText = translate(hours === 1 ? "oră" : "ore"); // Correctly handle singular and plural
            return hours > 0 ? `${hours} ${hoursText} ${translate("și")} ${minutes.toFixed(0)} minute` : `${minutes.toFixed(0)} minute`;
        }
        new google.maps.DirectionsService().route(
            {
                origin: waypointsObjects[0].location,
                waypoints: waypointsObjects,
                destination: waypointsObjects[waypointsObjects.length - 1].location, // Use the last waypoint as destination
                travelMode: travelMode,
            },
            (response, status) => {
                if (status === "OK" && response) {
                    new google.maps.DirectionsRenderer().setDirections(response);
                    const route = response.routes[0];
                    const legs = route.legs;
                    // Display route information including distances and durations
                    // displayRouteInfo(legs);
                    for (let i = 0; i < legs.length; i++) {
                        const leg = legs[i];
                        distance += leg.distance?.value || 0;
                        duration += leg.duration?.value || 0;
                        // console.log(`Leg ${i}: ${distance} and ${duration}`);
                    }
                    // console.log(`Total distance: ${distance} and total duration: ${duration}`);
                    const info = { distance: `${(distance / 1000).toFixed(0)} km`, duration: formatDuration(duration / 60) };
                    if (info.distance !== routeInfo.distance && info.duration !== routeInfo.duration)
                        setRouteInfo(info);
                } else {
                    console.error(status, response);
                }
            }
        );
    }
    routeDistanceAndDuration();
    console.log(waypoints);
    return (
        <>
            <MyCustomCircuit {...{ routeInfo, waypoints: organizatiiAprobate.data?.filter(p => selectedWaypoints.includes(p.Id)), selectedWaypoints, setSelectedWaypoints: (e) => [setSelectedWaypoints(e), setResponse(null)] }} />
            {waypoints && <DirectionsService options={{
                destination: destinationPoint(filterWaypoints(waypoints)),
                origin: originPoint(filterWaypoints(waypoints)),
                travelMode: google.maps.TravelMode.DRIVING,
                waypoints: filterWaypoints(waypoints)
            }} callback={directionsCallback} />}
            {response && <DirectionsRenderer directions={response} />}
            {locations?.map(item => {
                return (
                    <div key={item.name}>
                        <Marker
                            position={item.location}
                            icon={{
                                url: '/images/markers/marker-container.png',
                                scaledSize: new window.google.maps.Size(44.8, 50.4)
                            }}
                            onClick={(e) => [e.domEvent.preventDefault(), setWaypoint(item)]}
                        />
                        <Marker
                            position={item.location}
                            icon={{
                                url: item.marker,
                                scaledSize: new window.google.maps.Size(25.2, 25.2),
                                size: new window.google.maps.Size(23.8, 42),
                                origin: new window.google.maps.Point(1, 2),
                            }}
                            onClick={(e) => [e.domEvent.preventDefault(), setWaypoint(item)]}
                        />

                    </div>
                );
            })}

            {waypoint && (
                <InfoWindow
                    position={waypoint.location}
                    onCloseClick={() => setWaypoint(null)}
                >
                    <div>
                        <header className='d-flex align-items-center'>
                            <img src={waypoint.sigla} className='img img-sigla' alt="" />
                            <small>
                                <b className='text-large'>{waypoint.name}</b>
                                {waypoint.website && <a className='nav nav-link p-0' href={waypoint.website} target="_blank" rel="noreferrer"><Lang label='Vezi detalii pe website-ul locației' /></a>}
                            </small>
                        </header>
                        <hr />
                        <p dangerouslySetInnerHTML={{ __html: waypoint.promovare || waypoint.name }}></p>
                        {/* <p><small>{waypoint.location.lat}, {waypoint.location.lng}</small></p> */}
                        <p>
                            <ObjectiveSelector readonly="icons" value={waypoint.obiectiv} />
                        </p>
                        <p>
                            <div className='d-flex justify-content-between'>
                                <Link to={`/vizualizare-profil/${waypoint.id}`}><Lang label="Vizualizare profil complet" /></Link>
                                <div>{waypoint.rezervare}</div>
                                {selectedWaypoints.includes(waypoint.id)
                                    ? <Link to='' onClick={e => [e.preventDefault(), setResponse(null), setWaypoint(null), setSelectedWaypoints(selectedWaypoints.filter(i => i !== waypoint.id))]}> <Fa solid='location-minus text-danger' /><Lang label="Elimină din traseul meu" /></Link>
                                    : <Link to='' onClick={e => [e.preventDefault(), setResponse(null), setWaypoint(null), setSelectedWaypoints([...selectedWaypoints, waypoint.id])]}><Fa regular='location-plus' /><Lang label="Adaugă la traseul meu" /></Link>}
                            </div>
                        </p>
                    </div>
                </InfoWindow>
            )}
        </>
    )
}

export default MapCircuit

const MyCustomCircuit = ({ routeInfo = { distance: "", duration: "" }, waypoints = [] as OrganizatieEntity[], selectedWaypoints = [] as number[], setSelectedWaypoints = (val: number[]) => { } }) => {
    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }
        const newWaypoints = Array.from(waypoints);
        const [removed] = newWaypoints.splice(result.source.index, 1);
        newWaypoints.splice(result.destination.index, 0, removed);
        setSelectedWaypoints(newWaypoints.map((waypoint) => waypoint.Id));
    };
    const params = { locations: selectedWaypoints.join(',') };
    const downloadMyCircuit = `/api/report/${btoa(JSON.stringify({ params, template: 'myCircuit' }))}`;
    return (
        <div className="card position-absolute top-0 start-0 m-4">
            <div className="card-body">
                <div className="card-title">
                    <div className='d-flex justify-content-between'>
                        <span className='h6'><Fa light="location-dot fa-lg" /> <Lang label="Traseul meu" /></span>
                      <Link to='' onClick={e=>[e.preventDefault(), setSelectedWaypoints([])]}><Fa solid='rotate-right' /></Link>
                    </div>
                </div>
                <hr />
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="waypoints">
                        {(provided: any) => (
                            <ul className='nav flex-column ps-0' ref={provided.innerRef} {...provided.droppableProps}>
                                {waypoints.sort((a, b) => selectedWaypoints.indexOf(a.Id) - selectedWaypoints.indexOf(b.Id)).map((item, index) => (
                                    <Draggable key={item.Id.toString()} draggableId={item.Id.toString()} index={index}>
                                        {(provided: any) => (
                                            <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <div>
                                                    <input type="checkbox" checked={selectedWaypoints.includes(item.Id)} onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setSelectedWaypoints([...selectedWaypoints, item.Id]);
                                                        } else {
                                                            setSelectedWaypoints(selectedWaypoints.filter(i => i !== item.Id));
                                                        }
                                                    }} />
                                                    <label className='m-0 ms-2'>{item.Nume}</label>
                                                </div>
                                            </li>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <hr />
            <div className="card-body">
                <h6 className="card-title"><Fa light="road fa-lg fa-fw" /> {routeInfo.distance}, {routeInfo.duration}</h6>
                <a href={downloadMyCircuit} target='_blank' rel='noreferrer'><Fa duotone='file-pdf fa-lg fa-fw' /><Lang label='Descarcă traseul meu' /></a>
            </div>
        </div>
    );
};

