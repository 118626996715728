import { Labels } from '../../common/lang'
import Fa from '../FontAwesome'
import Lang from '../Lang'
import './Loading.scss'

const Loading = ({ message = "" as Labels }) => {
  if (message)
    return (
      <div className='loading text-xx-large'>
        <h4>
          <Fa solid='triangle-exclamation fa-2xl text-warning' /> <Lang label={message} />
        </h4>
      </div>
    )
  return (
    <div className='loading text-xx-large'>
      <Fa duotone='sun-bright fa-spin fa-2xl' />
    </div>
  )
}

export default Loading