import React from 'react'
import { useQuery } from '@tanstack/react-query';
import sql from '../../common/sql';
import { RecenzieEntity } from '../../../../backend/src/shared/entity';
import Loading from '../../components/Loading/Loading';
import Lang from '../../components/Lang';
import ForeignKeySelector from '../../components/Selectors/ForeignKeySelector';
import ObjectiveSelector from '../../components/Selectors/ObjectiveSelector';
import { Link, useParams } from 'react-router-dom';
import { useTranslate } from '../../common/lang';
import Phones from '../../components/Phones';
import Form from '../../components/Form';
import Emails from '../../components/Emails';
import { toast } from 'sonner';
import ImageCarusel from '../../components/ImageCarusel/ImageCarusel';
import { useModal } from '../../hooks/useModal';
import RecenzieAdaugare from '../../components/Recenzie/RecenzieAdaugare';
import RecenzieVizualizare from '../../components/Recenzie/RecenzieVizualizare';
import Fa from '../../components/FontAwesome';
import { useData } from '../../hooks/useData';

const ProfilOrganizatieVizualizare = () => {
  const { idOrganizatie = null } = useParams<{ idOrganizatie: string }>();
  const [review, setReview] = React.useState(false); // true while writing a review
  const [saving, setSaving] = React.useState(false); // true while saving a review
  const [model, setModel] = React.useState({} as RecenzieEntity);
  const translate = useTranslate();
  const { setModal } = useModal();
  const { isLoading: isLoadingProfil, data: organizatie } = useQuery({
    queryKey: ["organizatieQuery", idOrganizatie],
    queryFn: () => sql.getEntity('dbo.Organizatie', +(idOrganizatie || '')),
    refetchOnWindowFocus: false,
  });
  const { goals } = useData();
  const { isLoading: isLoadingRecenzie, data: recenzie } = useQuery({
    queryKey: ["recenzieQuery", idOrganizatie],
    queryFn: () => sql.getEntity('dbo.Recenzie', 0),
    refetchOnWindowFocus: false,
  });

  if (isLoadingProfil || isLoadingRecenzie || saving) return <Loading />;
  if (!(organizatie && recenzie)) return <Loading message='Ceva nu a mers bine 😔 ... încercați refresh la pagina sau reveniți mai târziu!' />;

  const handleSave = () => {
    if (!model.IdOrganizatie) return;
    setSaving(true);
    sql.saveEntity("dbo.Recenzie", model)
      .then(e => [setReview(false), setModal("Mulțumim pentru recenzie! Vom verifica conținutul și o vom publica în cel mai scurt timp posibil.")])
      .catch(e => [console.error(e), toast.error(translate("Eroare la salvarea formularului!"))])
      .finally(() => setSaving(false));
  }

  if (recenzie && !model.IdOrganizatie && organizatie.Id)
    setModel({
      ...recenzie,
      IdOrganizatie: organizatie.Id,
      Raspuns: JSON.stringify(goals.filter((_, id) => organizatie.Obiectiv.split(',').includes(`${id}`)).reduce((acc, cur) => acc.concat(cur.recenzie), [] as any[]))
    });

  return (
    review
      ? <RecenzieAdaugare nume={organizatie.Nume} model={model} setModel={setModel} handleSave={handleSave} handleCancel={() => setReview(false)} />
      : <Form>
        <div className='d-flex'>
          <img className='img-preview d-block me-3' src={'/' + (JSON.parse(organizatie.Sigla || '[]')[0])?.filepath} alt={translate('Siglă organizație')} />
          <small>
            <b className='text-large'>{organizatie.Nume}</b>
            {organizatie.Website && <a className='nav nav-link p-0' href={organizatie.Website} target="_blank" rel="noreferrer"><Lang label='Vezi detalii pe website-ul locației' /></a>}
          </small>
        </div>

        <Lang label='Adresa'>
          <div className='d-md-flex'>
            <span className='me-3'>{organizatie.Adresa}</span>
            {organizatie.Rezervare
              ? <a href={organizatie.Rezervare} target="_blank" rel="noreferrer"><Lang label="Fă o rezervare" /></a>
              : <><Lang label='Rezervări la telefon ' /> <Phones phones={organizatie.Telefon} /></>
            }
          </div>
        </Lang>

        <div className='d-flex mb-3'>
          <Phones phones={organizatie.Telefon} />
          <Emails emails={organizatie.Email} />
        </div>

        <div className='row' dangerouslySetInnerHTML={{ __html: organizatie.Descriere || organizatie.Promovare }}></div>

        <Lang label='Domenii de activitate'>
          <ForeignKeySelector tableSource='dbo.Domeniu' readonly={true} value={organizatie.Domeniu} />
        </Lang>

        <ObjectiveSelector value={organizatie.Obiectiv} readonly={true} /> <br />

        <Lang label='Galerie foto'>
          <ImageCarusel images={organizatie.Galerie} />
        </Lang>

        <RecenzieVizualizare nume={organizatie.Nume} idOrganizatie={organizatie.Id} />
        <p className='my-4'>
          <Link to='' onClick={e => [e.preventDefault(), setReview(true)]}>
            <Fa solid='book-journal-whills fa-fw fa-xl' /><Lang label='Scrie o recenzie 🌿🌿🌿🌿🌿' />
          </Link>
        </p>
      </Form>
  )
}

export default ProfilOrganizatieVizualizare

