import { useQuery } from '@tanstack/react-query';
import React from 'react'
import sql from '../../common/sql';
import { ContactEntity } from '../../../../backend/src/shared/entity';
import { Link } from 'react-router-dom';
import Fa from '../../components/FontAwesome';
import Loading from '../../components/Loading/Loading';

const ContactIndex = () => {
  const [model, setModel] = React.useState([] as ContactEntity[]);
  const { isLoading, data } = useQuery({
    queryKey: ["ContactIndex"],
    queryFn: () => sql.contactListQuery() as Promise<ContactEntity[]>,
  });

  if (!isLoading && data && data.length > 0 && !model.length) {
    setModel(data);
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <h1>Contact</h1>
      <table>
        <thead>
          <tr>
            <th>Nume și prenume</th>
            <th>Telefon</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {model.map((item, index) => (
            <tr key={index}>
              <td>
                <Link to={`/contact/${item.Id}`}>{item.Nume}</Link>
              </td>
              <td>{item.Telefon}</td>
              <td>{item.Email}</td>
            </tr>
          ))}
          <tr>
            <td colSpan={3}>
              <Link to="/contact/0" role='button'><Fa light='user-plus'/>Adaugă contact nou</Link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ContactIndex