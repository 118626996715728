import React, { useState } from 'react'
import Lang from './Lang';
import Fa from './FontAwesome';

const InProgressAlert = () => {
    // read and write to local storage
    const [visible, setVisible] = useState(localStorage.getItem('inProgressAlert') === 'true' ? true : false);
    const closeAlert = () => {
        localStorage.setItem('inProgressAlert', 'false');
        setVisible(false);
    }
    // const [visible, setVisible] = useState(true);
    return (
        visible ? <nav>
            <div className="w-100 alert alert-warning m-0" role="alert">
                <div className="container">
                    <Fa solid='screwdriver-wrench fa-2xl fa-fw' />
                    <Lang label="Momentan, site-ul Sustain Circuit se află în construcție, pentru a vă putea oferi o experiență îmbunătățită. Între timp, vă invităm să rămâneți informați în legătură cu ultimele noutăți ale proiectului prin intermediul rețelelor noastre de socializare: " />
                    <a href="https://www.facebook.com/sustaincircuit" className='btn btn-link pt-0' target="_blank" rel="noopener noreferrer"><Fa regular='square-facebook fa-brands fa-lg'/>Facebook</a> | 
                    <a href="https://www.linkedin.com/company/sustain-circuit/" className='btn btn-link pt-0' target="_blank" rel="noopener noreferrer"><Fa regular='linkedin fa-brands fa-lg'/>LinkedIn</a>
                    <a href='/' className='btn btn-link float-end m-0 p-0' onClick={e => [e.preventDefault(), closeAlert()]}><Fa solid='xmark fa-xl text-danger' /></a>
                </div>
            </div>
        </nav> : null
    )
    
}


export default InProgressAlert