import React from 'react'
import FormComposer from '../../components/FormComposer/FormComposer'
import { useQuery } from '@tanstack/react-query';
import sql from '../../common/sql';
import { RecenzieEntity } from '../../../../backend/src/shared/entity';
import Loading from '../../components/Loading/Loading';
import Lang from '../../components/Lang';
import Fa from '../../components/FontAwesome';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslate } from '../../common/lang';
import PageTitle from '../../components/PageTitle';
import ImageCarusel from '../../components/ImageCarusel/ImageCarusel';
import ReviewLeaf from '../../components/ReviewLeaf';
import { Options } from '../../common/types';
import FormElement from '../../components/FormComposer/FormElement';

const AdminEvaluareRecenzieOrganizatie = () => {
  const translate = useTranslate();
  const pageTitle = translate("Aprobă / Respinge recenzie organizație");
  PageTitle(pageTitle);
  const { idRecenzie } = useParams();
  const navigate = useNavigate();

  const [model, setModel] = React.useState<RecenzieEntity>({} as RecenzieEntity);
  const [saving, setSaving] = React.useState(false);

  const { isLoading: isLoadingRecenzie, data: recenzie } = useQuery({
    queryKey: ["getEntityRecenzieQuery", idRecenzie],
    queryFn: () => sql.getEntity('dbo.Recenzie', +(idRecenzie || -1)),
    refetchOnWindowFocus: false,
  });

  const { isLoading: isLoadingOrganizatie, data: organizatie } = useQuery({
    queryKey: ["getEntityOrganizatieQuery", model.IdOrganizatie],
    queryFn: () => sql.getEntity('dbo.Organizatie', +(model.IdOrganizatie || -1)),
    refetchOnWindowFocus: false,
  });

  const handleApprove = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    model.Aprobat = true;
    handleSave(event);
  }
  const handleReject = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    model.Aprobat = false;
    handleSave(event);
  }

  const handleSave = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.preventDefault();
    setSaving(true);
    sql.saveEntity("dbo.Recenzie", model)
      .then(() => navigate('/admin/evaluare-recenzie'))
      .catch(e => console.error(e))
      .finally(() => setSaving(false));
  }

  if (isLoadingOrganizatie || isLoadingRecenzie || saving) return <Loading />;
  if (!(recenzie && organizatie)) return <Loading message='Ceva nu a mers bine 😔 ... încercați refresh la pagina sau reveniți mai târziu!' />;

  if (recenzie && model.Id !== recenzie.Id) {
    setModel(recenzie); return null;
  }

  return (
    <form>
      <p className='text-large'>
        {model.Aprobat !== null && <Fa solid={model.Aprobat ? 'check-double text-success fa-xl me-2' : 'hand text-danger fa-xl me-2'} />}
        {model.Nume ? model.Nume : translate('Recenzie anonimă')} - {organizatie.Nume}
        <small> <ReviewLeaf leaf={+model.Valoare.split(',')[1]} /></small>
      </p>
      <FormElement label='Cât de mulțumit/ă sunteți de produsele/serviciile oferite de această afacere?'
        value={model.Valoare.split(',')[0]}
        formControl='readonly'
        options={Options.Overall} />

      <FormComposer items={model.Raspuns} readonly />
      <FormElement label="Doriți să adăugați alte informații despre experiența cu serviciile / produsele oferite de această afacere?"
        value={model.Review}
        formControl='readonly'
      />
      <ImageCarusel images={JSON.parse(model.Galerie || '[]')} />

      {model.Aprobat === null ? <>
        <button className='btn btn-success my-4' onClick={handleApprove}><Fa light='check-double fa-xl' /> <Lang label='Aprobă recenzia' /></button>
        <button className='btn btn-danger' onClick={handleReject}><Fa light='ban fa-xl' /> <Lang label='Respinge recenzia' /></button>
      </> :
        <Link className='btn btn-primary' to="/admin/evaluare-recenzie"><Fa light='caret-left fa-xl' /> <Lang label='Înapoi la listă' /></Link>
      }
    </form>

  )
}

export default AdminEvaluareRecenzieOrganizatie
