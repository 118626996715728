import React from 'react'
import FileUpload from './FileUpload'
import { FileAttach } from '../../common/types';
import { useTranslate } from '../../common/lang';

const FileUploadDialog = ({
    files = [] as FileAttach[],
    onChange = (files: FileAttach[] | null) => { }
}) => {
    const translate = useTranslate();
    return (
        <div className="modal fade show d-block" tabIndex={-1}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="btn-close" aria-label="Close" onClick={(e) => [e.preventDefault(), onChange(null)]}></button>
                    </div>
                    <div className="modal-body">
                        {
                            <FileUpload files={files} onChange={onChange} multiple={false} accept='*'/>
                        }
                    </div>
                    <p className="mt-0 pe-3">
                        <button type="button" className="btn btn-dark float-end" onClick={(e) => [e.preventDefault(), onChange(null)]}>{translate("Închide")}</button>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default FileUploadDialog