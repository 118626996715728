import React from 'react'
import Fa from '../FontAwesome'
import { Link } from 'react-router-dom'

const PartnerLink = ({ icon = "", link = "", href = "" }) => {
    return (
        <div className='py-1 text-smaller'>
            <Fa regular={`${icon} fa-fw fa-xl text-muted`} />
            <Link to={href} target='_blank'>{link} &nbsp;</Link>
        </div>
    )
}

export default PartnerLink