import React from 'react'
import FormComposer from '../../components/FormComposer/FormComposer'
import { useQuery } from '@tanstack/react-query';
import sql from '../../common/sql';
import { OrganizatieEntity } from '../../../../backend/src/shared/entity';
import Loading from '../../components/Loading/Loading';
import Lang from '../../components/Lang';
import Fa from '../../components/FontAwesome';
import FormElement from '../../components/FormComposer/FormElement';
import { useNavigate, useParams } from 'react-router-dom';
import ForeignKeySelector from '../../components/Selectors/ForeignKeySelector';
import { useTranslate } from '../../common/lang';
import ObjectiveSelector from '../../components/Selectors/ObjectiveSelector';
import PageTitle from '../../components/PageTitle';

const AdminEvaluareProfilOrganizatie = () => {
  const translate = useTranslate();
  const pageTitle = translate("Evaluare profil organizație");
  PageTitle(pageTitle);
  const { idOrganizatie } = useParams();
  const navigate = useNavigate();
  const [model, setModel] = React.useState<OrganizatieEntity>({} as OrganizatieEntity);
  const [saving, setSaving] = React.useState(false);

  const { isLoading, data } = useQuery({
    queryKey: ["getEntityOrganizatieQuery", idOrganizatie],
    queryFn: () => sql.getEntity('dbo.Organizatie', +(idOrganizatie || -1)),
    refetchOnWindowFocus: false,
  });

  const handleApprove = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    model.Inrolat = true;
    handleSave(event);
  }
  const handleReject = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    model.Inrolat = false;
    handleSave(event);
  }

  const handleSave = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setSaving(true);
    sql.saveEntity("dbo.Organizatie", model)
      .then(() => navigate('/admin/evaluare-profil'))
      .catch(e => console.error(e))
      .finally(() => setSaving(false));
  }

  if (!isLoading && data && model !== data) {
    setModel(data);
  }

  if (isLoading) return <Loading />;

  return (
    <form>
      <legend><Fa regular='address-card fa-fw' /> <Lang label="Profil Organizație" /></legend>
      <div className='d-flex'>
        <img className='img-preview d-block me-3' src={'/' + (JSON.parse(model.Sigla || '[]')[0])?.filepath} alt={translate('Siglă organizație')} />
        <div>
          <FormElement label="Denumire organizație" value={model.Nume} formControl="readonly" />
          <a href={model.Website}></a>
        </div>
      </div>
      <FormElement label="Adrese de email (separate prin virgulă)"
        value={model.Email}
        formControl="readonly"
      />
      <FormElement label="Numere de telefon (separate prin virgulă)" value={model.Telefon}
        formControl="readonly"
      />
      <FormElement label="Adresa" value={model.Adresa}
        formControl="readonly"
      />

      <label className='readonly'>
        <div className='text-muted'><i><small><Lang label='Domenii de activitate' /></small></i></div>
        <div className='text-large'>
          <ForeignKeySelector tableSource='dbo.Domeniu' readonly={true} value={model.Domeniu} onChange={e => setModel({ ...model, Domeniu: e })} />
        </div>
      </label>

      <FormElement label={"Scurtă descriere a afacerii (150 cuvinte)"}
        value={model.Descriere}
        formControl="readonly"
      />
      <FormElement label="Vreau ca datele de contact să fie vizibile și pentru ceilalți antreprenori înregistrați în platformă ?"
        value={model.ContactPublic ? 'Da' : 'Nu'}
        formControl="readonly"
      />

      <Lang label='Vă rugăm să analizați obiectivele dezvoltării durabile descrise mai jos și să bifați acele obiective la care afacerea dumneavoastră contribuie în mod activ'>
        <ObjectiveSelector value={model.Obiectiv} readonly={model.Inrolat === null} onChange={e => setModel({ ...model, Obiectiv: e })} />
      </Lang>
      <FormComposer readonly={true} items={JSON.parse(model.Profil||'{}')} />

      {model.Inrolat === null && <>
        <button className='btn btn-success my-4' onClick={handleApprove}><Fa light='check-double fa-xl' /> <Lang label='Aprobă cererea de înrolare' /></button>
        <button className='btn btn-danger' onClick={handleReject}><Fa light='ban fa-xl' /> <Lang label='Respinge cererea de înrolare' /></button>
      </>}
      {saving && <Loading />}
    </form>
  )
}

export default AdminEvaluareProfilOrganizatie
