import React from 'react'
import { Location } from '../../common/types'
import Fa from '../FontAwesome'
import Lang from '../Lang'
import MapContainer from '../MapContainer'
import { Marker } from '@react-google-maps/api'
import { useTranslate } from '../../common/lang'
import { useDebounce } from "use-debounce";
const LocationPicker = ({ location = {} as Location, readonly = false, onChange = (location: Location) => { } }) => {
    const actual = location.lat ? `${location.lat}, ${location.lng}` : ``;
    const center: Location = actual ? location : { lat: 47.658, lng: 23.575 };
    const [mapOpen, setMapOpen] = React.useState(false);
    const [text, setText] = React.useState('');
    const [address] = useDebounce(text, 1000);
    const handleMapClick = (e: google.maps.MapMouseEvent) => {
        if (e.latLng) onChange({ lat: e.latLng.lat(), lng: e.latLng.lng() });
        setMapOpen(false);
    }
    const translate = useTranslate();
    // cauta locatia pe harta dupa adresa
    React.useEffect(() => {
        if (address) {
            console.log('searching for address', address);
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ address }, (results, status) => {
                if (status === 'OK' && results && results[0] && results[0].geometry && results[0].geometry.location) {
                    const { lat, lng } = results[0].geometry.location;
                    onChange({ lat: lat(), lng: lng() });
                }
            })
        }
    }, [address]);
    if (readonly) return <i>{actual || <Lang label="Nici o locație selectată" />}</i>
    return (
        <>
            <a href='/' className='nav nav-link ps-0' onClick={e => [e.preventDefault(), setMapOpen(prev => !prev)]}>
                {actual || <Lang label="Alege locația pe hartă" />} <Fa duotone='location-dot fa-xl' />
            </a>
            {
                mapOpen &&
                <div className='position-relative d-flex'>
                    <MapContainer {...center} zoom={14} onMapClick={handleMapClick} >
                        <Marker
                            position={center}
                            onClick={(e) => [e.domEvent.preventDefault(), handleMapClick(e)]}
                        />
                    </MapContainer>
                    <input type="text"
                        placeholder={translate("Caută adresă sau denumire obiectiv turistic...")}
                        className="form-control m-3 position-absolute top-0 start-0 w-75"
                        value={text}
                        onChange={e => setText(e.target.value)}
                    />
                </div>
            }
        </>
    )
}

export default LocationPicker