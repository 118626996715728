import React from 'react';

interface WordSliderProps {
    words: string[];
  }

  const WordSlider: React.FC<WordSliderProps> = ({ words }) => {
    const colors = ['rgba(41,114,46,255)', 'rgba(68,94,202,255)', 'Gray'];
    return (
      <div className="border-slider">
        <div className="ms-slider">
          <ul className="ms-slider__words">
            {words.map((word, index) => (
              <li
              key={index}
              className="ms-slider__word"
              // Apply the color dynamically based on the word's index
              // If there are more words than colors, it will cycle through the colors
              style={{ 
                color: colors[index % colors.length],
                fontWeight: 'bold'
              }}
            >
              {word}
            </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };
  
  export default WordSlider;
