import React from 'react'
import { EntityName } from '../../../../backend/src/shared/entity'
import sql from '../../common/sql';
import { useQuery } from '@tanstack/react-query';
import Loading from '../Loading/Loading';
import { useTranslate } from '../../common/lang';

const ForeignKeySelector = ({ tableSource = "dbo.Domeniu" as EntityName, readonly = false, value = "", onChange = (values: string) => { } }) => {
    const values = value.split(',').map(x => parseInt(x)).filter(x => x);
    const { isLoading, data } = useQuery({
        queryKey: [tableSource],
        queryFn: () => sql.getEntity(tableSource, -1) as Promise<any>,
        refetchOnWindowFocus: false,
    });
    const translate = useTranslate();
    if (isLoading || !data) return <Loading />;
    if (readonly) return <ul className='ps-0 flex-column'>
        {values.map((id, i) => <li key={i}>{data.find((x: any) => x.Id === id)?.Nume}</li>)}
    </ul>
    return (
        <ul className='nav flex-column'>
            {
                data.map((item: any) => <li className='nav-item' key={item.Id}>
                    <label htmlFor={item.Id}>
                        <input type="checkbox" id={item.Id} checked={values.includes(item.Id)} onChange={e => {
                            if (values.includes(item.Id)) {
                                values.splice(values.indexOf(item.Id), 1);
                            } else {
                                values.push(item.Id);
                            }
                            onChange(values.join(','));
                        }} /> &nbsp;
                        {item.Marker && <img src={`/images/markers/${item.Marker}`} className='img-icon-24' alt="" />} {translate(item.Nume)}
                    </label>
                </li>)
            }
        </ul>
    )
}

export default ForeignKeySelector