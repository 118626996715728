import { Labels } from "./lang";

export type Location = { lat: number, lng: number };

export type Waypoint = {
  location: Location;
  name: string;
  sigla: string;
  promovare: string;
  obiectiv: string;
  rezervare: JSX.Element;
  id: number;
  website: string;
  marker: string;
};

export type FileAttach = { filename: string, filepath: string };

export type FormDataItem = {
  label: Labels;
  description?: Labels;
  control: FormControl;
  options?: FormOptions;
  value?: string;
}

export type FormControl = "input" | "textarea" | "tinymce" | "readonly" | "options" | "yesno" | 'leaf';
export type FormOptions = Array<{ label: string, value: string }>;
export const Options: Record<"YesNo" | "YesNoOptional" | "OneToFour" | "OneToFive" | "ZeroToHero" | "Overall", Array<{ value: string, label: Labels }>>
  = {
  YesNo: [{ value: '1', label: 'Da' }, { value: '-1', label: 'Nu' }],
  YesNoOptional: [{ value: '1', label: 'Da' }, { value: '-1', label: 'Nu' }, { value: '0', label: 'Nu sunt sigur/ă' }],
  OneToFour: [{ value: '1', label: '1 - total dezacord' }, { value: '2', label: '2 - dezacord' }, { value: '3', label: '3 - de acord' }, { value: '4', label: '4 - total de acord' }],
  OneToFive: [{ value: '1', label: '1 - total dezacord' }, { value: '2', label: '2 - dezacord' }, { value: '3', label: '3 - neutru' }, { value: '4', label: '4 - de acord' }, { value: '5', label: '5 - total de acord' }],
  ZeroToHero: [{ value: '1', label: 'mult sub așteptări' }, { value: '2', label: 'puțin sub așteptări' }, { value: '3', label: 'îndepliniște așteptările' }, { value: '4', label: 'moderat peste așteptări' }, { value: '5', label: 'mult peste așteptări' }, { value: '0', label: 'Nu sunt sigur/ă' }],
  Overall: [{ value: '4', label: 'În mare măsură' }, { value: '3', label: 'Într-o oarecare măsură' }, { value: '2', label: 'Foarte puțin' }, { value: '1', label: 'Deloc' }]
};
