import { useQuery } from '@tanstack/react-query';
import React from 'react'
import sql from '../../common/sql';
import { RecenzieEntity } from '../../../../backend/src/shared/entity';
import Loading from '../Loading/Loading';
import FormComposer from '../FormComposer/FormComposer';
import { useTranslate } from '../../common/lang';
import ImageCarusel from '../ImageCarusel/ImageCarusel';
import Lang from '../Lang';
import FormElement from '../FormComposer/FormElement';
import { Options } from '../../common/types';
import ReviewLeaf from '../ReviewLeaf';

const RecenzieVizualizare = ({ idOrganizatie = 0, nume = "" }) => {
    const translate = useTranslate();
    const [expanded, setExpanded] = React.useState(-1);
    const { isLoading: isLoadingRecenzii, data: recenzii } = useQuery({
        queryKey: ["getRecenzieByIdOrganizatieQuery", idOrganizatie],
        queryFn: () => sql.getRecenzieByIdOrganizatieQuery({ idOrganizatie }) as Promise<RecenzieEntity[]>,
        refetchOnWindowFocus: false,
    });
    if (isLoadingRecenzii) return <Loading />;
    if (!recenzii) return <Loading message='Ceva nu a mers bine 😔 ... încercați refresh la pagina sau reveniți mai târziu!' />;
    const reviewAverage = Math.round(recenzii?.reduce((acc, recenzie) => acc + +recenzie.Valoare.split(',')[1], 0) / recenzii.length);
    return (
        <div>
            <i className='text-large'>
                <b>{`${nume} ( ${recenzii.length} ${translate('Recenzii completate')} )`} <ReviewLeaf leaf={reviewAverage} /></b>
            </i>
            {
                recenzii.map((recenzie, index) => (
                    <div className="accordion" id="accordionPanelsStayOpenExample" key={index}>
                        <div className="accordion-item">
                            <h2 className="accordion-header" onClick={e => [e.preventDefault(), setExpanded(expanded === index ? -1 : index)]}>
                                <button className={`accordion-button ${expanded !== index && "collapsed"}`} type="button" data-bs-toggle="collapse">
                                    <span>{recenzie.Nume ? translate('Recenzie de la ') + recenzie.Nume : translate('Recenzie anonimă')}
                                        <small> <ReviewLeaf leaf={+recenzie.Valoare.split(',')[1]} /></small>
                                        <p><small><i>({recenzie.Start.toString().substring(0, 16).replace('T', ' ')})</i></small></p>
                                    </span>
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseOne" className={`accordion-collapse collapse ${expanded === index && 'show'}`}>
                                <div className="accordion-body">
                                    <FormElement label='Cât de mulțumit/ă sunteți de produsele/serviciile oferite de această afacere?'
                                        value={recenzie.Valoare.split(',')[0]}
                                        formControl='readonly'
                                        options={Options.Overall} />
                                    <FormComposer items={recenzie.Raspuns} readonly />
                                    <p>
                                        <small>
                                            <Lang label='Alte informații' />
                                        </small>
                                    </p>
                                    <p dangerouslySetInnerHTML={{ __html: recenzie.Review }}></p>
                                    <ImageCarusel images={recenzie.Galerie} />
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default RecenzieVizualizare