import React from 'react'
import Lang from './Lang';
import Fa from './FontAwesome';

const DisclaimerProfile = () => {
    return (
        <div className="w-100 alert alert-primary m-0" role="alert">
            <div className="container opacity-75-">
                <Fa solid='circle-info fa-2xl fa-fw' />
                <small>
                    <Lang label='De ce vă solicităm datele de contact în cadrul rețelei Sustain Circuit? Atunci când vă împărtășiți datele de contact, le oferiți colegilor din rețea posibilitatea de a intra în contact cu dumneavoastră. Acest lucru ajută la crearea de sinergii și la amplificarea impactului eforturilor noastre colective în direcția sustenabilității. Fie că sunteți în căutare de colaboratori pentru proiecte, de resurse pentru a vă sprijini inițiativele sau pur și simplu doriți să faceți schimb de idei și de expertiză, rețeaua Sustain Circuit facilitează o comunicare și o colaborare fără probleme.' />
                </small>
            </div>
        </div>
    )
}

export default DisclaimerProfile