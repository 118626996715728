import React from 'react'
import { FileAttach } from '../../common/types'

const ImageViewer = ({ images = [] as FileAttach[], className = "img-thumbnail"}) => {
  return <>
  { images.map((f, i) => <div key={i}>
    <img src={`/${f.filepath}`} alt={f.filename}  className={className} />
  </div>)}
  </>
}

export default ImageViewer