import { useLang } from "../hooks/useLang";

export type language = "ro" | "en";

const labels = {
  Acasă: "Home",
  Traseu: "Circuit",
  "Traseul meu": "My Circuit",
  Noutăți: "News",
  "Noutăți Admin": {
    en: "News Admin",
  },
  // Noutati
  Imagine: {
    en: "Image",
  },
  Titlu: {
    en: "Title",
  },
  "Titlu EN": {
    en: "Title EN",
  },
  "Descriere scurta RO": {
    en: "Short description RO",
  },
  "Descriere scurta EN": {
    en: "Short description EN",
  },
  "Descriere lunga RO": {
    en: "Long description RO",
  },
  "Descriere lunga EN": {
    en: "Long description EN",
  },
  Publicat: {
    en: "Published",
  },
  Da: {
    en: "Yes",
  },
  Nu: {
    en: "No",
  },
  "Data adaugării": {
    en: "Date added",
  },
  "Adaugat de": {
    en: "Added by",
  },
  Intrebări: {
    en: "Questions",
  },
  // Pagina de Q&A Admin
  "Intrebări Admin": {
    en: "Questions Admin",
  },
  // Pagina de feedback
  "Formular feedback": {
    en: "Feedback form",
  },
  // Pagina de profil organizatie
  "Profil Organizație": {
    en: "Organization Profile",
  },
  "Sustain Circuit Admin": {
    en: "Sustain Circuit Admin",
  },
  "Home": {
    en: "Home",
  },
  "Harta": {
    en: "Map",
  },
  "Evaluare profil organizație": {
    en: "Organization profile evaluation",
  },
  "Denumire organizație": {
    en: "Organization name",
  },
  "Adrese de email (separate prin virgulă)": {
    en: "Email addresses (separated by comma)",
  },
  "Numere de telefon (separate prin virgulă)": {
    en: "Phone numbers (separated by comma)",
  },
  Adresa: "Address",
  "Scurtă descriere a afacerii (150 cuvinte)": {
    en: "Brief description of the business (150 words)",
  },
  "Descriere detaliată/completă a afacerii și serviciilor oferite": {
    en: "Detailed/complete description of the business and services offered",
  },
  "Adresă website rezervări(opțional)": {
    en: "Reservation website address(optional)"
  },
  "Te-ai înregistrat cu succes! Pentru validarea contului, te rugăm să completezi următorul chestionar":
  {
    en: "You have successfully registered! To validate your account, please complete the following questionnaire",
  },
  "Cererea dumneavoastră de înrolare a fost respinsă, puteți să o modificați și să o trimiteți din nou.":
  {
    en: "Your enrollment request has been rejected, you can modify it and send it again.",
  },
  "Cererea dumneavoastră de înrolare a fost trimisă, veți fi notificat când va fi aprobată/respinsă.":
  {
    en: "Your enrollment request has been sent, you will be notified when it is approved/rejected.",
  },
  "Cererea dumneavoastră de înrolare a fost aprobată.": {
    en: "Your enrollment request has been approved.",
  },
  "Panou de administrare": {
    en: "Admin panel",
  },
  "Organizații înrolate": {
    en: "Organization enrolled",
  },
  "Aprobă / Respinge": {
    en: "Approve / Reject",
  },
  "Aprobă / Respinge recenzie organizație": {
    en: "Approve / Reject organization review"
  },
  "Conturi de utilizator": {
    en: "User accounts",
  },
  "Notificare": {
    en: "Notification",
  },
  "Închide": {
    en: "Close",
  },
  Salvează: {
    en: "Save",
  },
  "Renunță": {
    en: "Cancel",
  },
  "Scrie o recenzie 🌿🌿🌿🌿🌿": {
    en: "Write a review 🌿🌿🌿🌿🌿",
  },
  "Recenzie de la ": {
    en: "Review from ",
  },
  "Recenzii completate": {
    en: "Completed reviews",
  },
  "Recenzie anonimă": {
    en: "Anonymous review",
  },
  "Mulțumim pentru recenzie! Vom verifica conținutul și o vom publica în cel mai scurt timp posibil.": {
    en: "Thank you for the review! We will verify the content and publish it as soon as possible."
  },
  "Aprobă cererea de înrolare": {
    en: "Approve the enrollment request",
  },
  "Respinge cererea de înrolare": {
    en: "Reject the enrollment request",
  },
  "Aprobă recenzia": {
    en: "Approve the review",
  },
  "Respinge recenzia": {
    en: "Reject the review",
  },

  "Aprobat / Respins": {
    en: "Approved / Rejected",
  },
  "Înapoi la listă": {
    en: "Back to list"
  },
  Organizație: {
    en: "Organization",
  },
  "Adresă website": {
    en: "Website address",
  },
  "Vezi detalii pe website-ul locației": {
    en: "See details on the location's website"
  },
  "Vizualizare profil complet": {
    en: "View full profile",
  },
  "Nume și prenume (opțional)": {
    en: "Name and surname (optional)",
  },
  Contact: {
    en: "Contact",
  },
  "Persoana de contact": {
    en: "Contact person",
  },
  "Persoana de contact - email": {
    en: "Contact person - email",
  },
  "Vreau ca datele de contact să fie vizibile și pentru ceilalți antreprenori înregistrați în platformă ?":
  {
    en: "Do you want your contact details to be visible ?",
  },
  "Siglă organizație": {
    en: "Organization logo",
  },
  "Galerie foto": {
    en: "Photo gallery",
  },
  Aprobat: {
    en: "Approved",
  },
  Respins: {
    en: "Rejected",
  },
  Descriere: {
    en: "Description",
  },
  "Domenii de activitate": {
    en: "Activity domains",
  },
  "Domeniu de activitate principal": "Main activity domain",
  "— Selectează —": "— Select —",
  "Vă rugăm să analizați obiectivele dezvoltării durabile descrise mai jos și să bifați acele obiective la care afacerea dumneavoastră contribuie în mod activ":
  {
    en: "Please review the sustainable development goals described below and check those goals that your business actively contributes to",
  },
  Acceptate: {
    en: "Accepted",
  },
  Neevaluate: {
    en: "Not evaluated",
  },
  "Evaluează": {
    en: "Evaluate",
  },
  "Modifică": {
    en: "Modify",
  },
  Respinse: {
    en: "Rejected",
  },
  Activi: {
    en: "Active",
  },
  Suspendați: {
    en: "Suspended",
  },
  "Email neconfirmat": {
    en: "Email unconfirmed",
  },
  "Setări cont personal": {
    en: "Personal account settings",
  },
  Deconectare: {
    en: "Logout",
  },
  "Trage fișierele aici sau folosește": {
    en: "Drag and drop files here or use",
  },
  "Atașează fișiere": {
    en: "Attach files",
  },
  "Locația pe hartă": {
    en: "Location on the map",
  },
  "Alege locația pe hartă": {
    en: "Choose location on the map",
  },
  "Nici o locație selectată": {
    en: "No location selected",
  },
  "Link activare cont": {
    en: "Account activation link",
  },
  "Autentificare": {
    en: "Login",
  },
  "Sesiunea ta a expirat, trebuie să te conectezi din nou cu email și parolă": {
    en: "Your session has expired, you need to log in again with email and password",
  },
  "Adresa de email": {
    en: "Email address",
  },
  "Parola": {
    en: "Password",
  },
  "Conectare": {
    en: "Login",
  },
  "Ai uitat parola ?": {
    en: "Forgot your password ?",
  },
  "Nu te-ai înregistrat ?": {
    en: "Not registered ?",
  },
  "Crează-ți un cont": {
    en: "Create an account",
  },
  'Nu mai știi parola ? Accesează <a href="/password-recover" class="card-link">resetează parola</a>.': {
    en: 'Forgot your password? Access <a href="/password-recover" class="card-link">reset password</a>.',
  },
  'Nu ai activat contul !<br/> Verifică casuța de email ( <b>inbox și spam</b> ) pentru link-ul de activare.': {
    en: 'You have not activated your account! Check your email inbox and spam for the activation link.',
  },
  'Acest link a fost deja folosit!<hr/> Poți încerca să te <a href="/login"> autentifici </a> cu email și parolă, sau poți folosi opțiunea <a href="/password-recover">resetare parolă</a> dacă nu mai știi parola.</p>' : {
    en: 'This link has already been used! <hr/> You can try to <a href="/login"> log in </a> with email and password, or you can use the <a href="/password-recover">reset password</a> option if you forgot your password.</p>',
  },
  'Nu există cont asociat cu acest email !': {
    en: 'There is no account associated with this email!',
  },
  'Parola nu este corectă !': {
    en: 'The password is not correct!',
  },
  "Te-ai înregistrat cu succes!": {
    en: "You have successfully registered!",
  },
  "Pentru validarea contului, te rugăm să completezi chestionarul pentru înrolare.":
  {
    en: "To validate your account, please complete the enrollment questionnaire.",
  },
  "Cont creat cu succes !": {
    en: "Account created successfully !",
  },
  "Crează cont nou": {
    en: "Create new account",
  },
  "Crează o parolă": {
    en: "Create a password",
  },
  "Repetă parola": {
    en: "Repeat password",
  },
  "Nume și prenume": {
    en: "First and last name",
  },
  "Crează cont": {
    en: "Create account",
  },
  "Ai deja cont ?": {
    en: "Already have an account ?",
  },
  'Contul <b>@email</b> este deja înregistrat.<br/> Nu mai știi parola ? Folosește opțiunea <a href="/password-recover" class="card-link">resetare parolă</a>.':{
    en: 'The <b>@email</b> account is already registered.<br/> Forgot your password ? Use the <a href="/password-recover" class="card-link">reset password</a> option.',
  },
  'Contul nu a fost activat !<br/> Verifică căsuța de email ( <b>inbox și spam </b> ) pentru link-ul de activare.': {
    en: 'The account has not been activated !<br/> Check your email inbox and spam for the activation link.',
  },
  'Cont <b>tempor dezactivat</b>. Poți încerca din nou peste <b>@minute minute</b>.': {
    en: 'Account <b>temporarily disabled</b>. You can try again in <b>@minute minutes</b>.',
  },
  'Contul tău este <b>temporar blocat</b>. Poți încerca din nou peste <b>@minute minute</b>.' : {
    en: 'Your account is <b>temporarily blocked</b>. You can try again in <b>@minute minutes</b>.',
  },
  "Un email de confirmare a fost trimis la adresa <strong>@email</strong>.<br/>Pentru a finaliza crearea contului, accesează link-ul din email.": {
    en: "A confirmation email has been sent to the address <strong>@email</strong>.<br/>To complete the account creation, access the link from the email.",
  },
  "Caută adresă sau denumire obiectiv turistic...": "Search for address or tourist attraction name...",
  "Înregistrează-ți afacerea": "Register your business",
  "De ce vă solicităm datele de contact în cadrul rețelei Sustain Circuit? Atunci când vă împărtășiți datele de contact, le oferiți colegilor din rețea posibilitatea de a intra în contact cu dumneavoastră. Acest lucru ajută la crearea de sinergii și la amplificarea impactului eforturilor noastre colective în direcția sustenabilității. Fie că sunteți în căutare de colaboratori pentru proiecte, de resurse pentru a vă sprijini inițiativele sau pur și simplu doriți să faceți schimb de idei și de expertiză, rețeaua Sustain Circuit facilitează o comunicare și o colaborare fără probleme.":
    "Why are we asking for your contact details within the Sustain Circuit network? When you share your contact information, you give your fellow network members the opportunity to connect with you. This helps to create synergies and amplify the impact of our collective efforts towards sustainability. Whether you're looking for collaborators for projects, resources to support your initiatives, or you simply want to exchange ideas and expertise, the Sustain Circuit network makes communication and collaboration seamless.",
  "Chestionar înrolare": {
    en: "Enrollment questionnaire",
  },
  "Salvare reușită!": {
    en: "Successful save!",
  },
  "Eroare la salvarea formularului!": {
    en: "Error saving the form!",
  },
  "Momentan, site-ul Sustain Circuit se află în construcție, pentru a vă putea oferi o experiență îmbunătățită. Între timp, vă invităm să rămâneți informați în legătură cu ultimele noutăți ale proiectului prin intermediul rețelelor noastre de socializare: ": {
    en: "We're currently fine-tuning our website to deliver an enhanced experience. While our site undergoes its makeover, we appreciate your patience. In the meantime, we invite you to catch up with our latest news on our socials: "
  },
  "În timp ce ne străduim să prezentăm numai întreprinderi durabile pe platforma Sustain Circuit, nu ne putem asuma nici o responsabilitate, expresă sau implicită, cu privire la acuratețea datelor furnizate de acestea.": {
    en: "While we strive to showcase only sustainable businesses on the Sustain Circuit platform, we can’t take any responsibility, express or implied, about the accuracy of the data provided by them."
  },
  "Fă o rezervare": {
    en: "Make a reservation",
  },
  "Rezervări la telefon ": {
    en: "Phone reservations",
  },
  "Completează o recenzie": {
    en: "Complete a review"
  },
  "Alte impresii sau sugestii (opțional)": {
    en: "Other impressions or suggestions (optional)"
  },
  "Ceva nu a mers bine 😔 ... încercați refresh la pagina sau reveniți mai târziu!": {
    en: "Something went wrong 😔 ... try refreshing the page or come back later!"
  },
  "mult sub așteptări": {
    en: "far below"
  },
  "puțin sub așteptări": {
    en: "moderately below"
  },
  "îndepliniște așteptările": {
    en: "met expectations"
  },
  "moderat peste așteptări": {
    en: "moderately above"
  },
  "mult peste așteptări": {
    en: "far above"
  },
  "Nu sunt sigur/ă": {
    en: "I am not sure"
  },
  "Da/Nu": {
    en: "Yes/No"
  },
  "1 - total dezacord": {
    en: "1 - fully disagree"
  },
  "2 - dezacord": {
    en: "2 - disagree"
  },
  '3 - de acord': {
    en: '3 - agree'
  },
  '3 - neutru': {
    en: '3 - neutral'
  },
  '4 - de acord': {
    en: '4 - agree'
  },
  '4 - total de acord': {
    en: '4 - totally agree'
  },
  '5 - total de acord': {
    en: '5 – fully agree'
  },
  "În mare măsură": "To a great extent",
  "Într-o oarecare măsură": "Somewhat",
  "Foarte puțin": "Very little ",
  "Deloc": "Not at all",
  "Cât de mulțumit/ă sunteți de produsele/serviciile oferite de această afacere?":
    "How satisfied are you with the products/services provided by this business?",
  "Vă rugăm să ne ajutați să evaluăm cât de mult contribuie această afacere la Obiectivele de Dezvoltare Durabilă (ODD).":
    "Please help us evaluate how much this business contributes to the Sustainable Development Goals (SDGs).",
  "În ce măsură contribuie această afacere la asigurarea unor opțiuni alimentare nutritive și sustenabile? (oferă doar fructe/legume de sezon, își cultivă propriile legume și fructe, face alegeri responsabile în ceea ce privește fructele de mare, pune accent pe un meniu bazat pe plante)":
    "On which level does this business contribute to ensuring access to nutritious and sustainable food options (eat what's in season, grow their own food, make responsible seafood choices, emphasizes a plant-based menu)?",

  "În ce măsură contribuie această afacere pentru a reduce risipa de alimente? (donarea surplusului de alimente, compostarea, evitarea pregătirii unei cantități excesive, cum ar fi bufetele suedeze)":
    "Have you observed any efforts made by this business to reduce food waste (donating surplus food, composting, avoiding over-preparing such as buffets)?",

  "În ce măsură sprijină această afacere producătorii locali? (procurarea ingredientelor de la fermierii locali pentru a sprijini agricultura la scară mică și pentru a reduce distanța parcursă de alimente)":
    "Have you observed any efforts made by this business to support local food producers (source ingredients from local farmers to support small-scale farming and reduce food journey distance)?",

  "Pe baza observațiilor dumneavoastră, cât de divers era personalul acestei întreprinderi (număr echilibrat de femei și bărbați, persoane din categorii vulnerabile)?":
    "Based on your observations, how diverse was the staff of this business (balanced number of women and men, persons from vulnerable categories)?",

  "Această afacere are femei în poziții de conducere?":
    "Does this business have women in leading positions?",

  "În ce măsură credeți că această întreprindere acordă prioritate utilizării și conservării responsabile a apei? (are instalate echipamente de economisire a apei, cum ar fi robinete, toalete și dușuri cu debit redus, sisteme de colectare a apei de ploaie, utilizează apă reciclată în diverse scopuri nepotabile, cum ar fi amenajarea grădinii, spălarea toaletelor)":
    "Do you think this business prioritises responsible water usage and conservation (installed water-saving equipment such as low-flow faucets, toilets, and showerheads, rainwater harvesting systems, utilises recycled water for various non-potable purposes such as landscaping, toilet flushing, or industrial processes)?",

  "Credeți că această afacere ia măsuri pentru a minimiza poluarea apei? (evită utilizarea paielor, paharelor de unică folosință, a sticlelor de apă sau a tacâmurilor din plastic, încurajează oaspeții să refolosească prosoapele și lenjeria de pat, sensibilizează turiștii cu privire la importanța conservării apei și a prevenirii poluării, deține etichete ecologice și certificări de turism durabil)":
    "Do you think this business takes any actions to minimise water pollution (they avoid using straws, coffee cups, plastic water bottles or cutlery, encourage guests to reuse towels and linens, raise awareness among tourists about the importance of water conservation and pollution prevention, they have eco-labels and sustainable tourism certifications)?",

  "În ce măsură credeți că această afacere încorporează surse de energie curată și regenerabilă în operațiunile sale? (panouri solare, centrale geotermale, energie din biomasă generată din materiale organice, cum ar fi lemnul, reziduuri agricole, deșeuri de biomasă, turbine eoliene)":
    "Do you think this business incorporates clean and renewable energy sources into its operations (solar panels, geothermal power plants, biomass energy generated from organic materials such as wood, agricultural residues, waste biomass, water, or wind turbines)?",

  "În ce măsură credeți că această afacere face eforturi pentru a reduce consumul de energie sau pentru a promova eficiența energetică? (utilizarea de aparate și sisteme de iluminat eficiente din punct de vedere energetic, contoare inteligente disponibile, senzori și sisteme de automatizare pentru a monitoriza și controla consumul de energie în timp real - pentru sistemul de încălzire, ventilație)":
    "Have you noticed any efforts made by this business to reduce energy consumption or promote energy efficiency (use of energy-efficient appliances and lighting systems that meet high energy efficiency standards, available intelligent meters, sensors, and automation systems to monitor and control energy use in real-time - for the heating system, ventilation)?",

  "În ce măsură credeți că această afacere sprijină creșterea economică și crearea de locuri de muncă în cadrul comunității (angajații erau în principal rezidenți din acea regiune)?":
    "Have you observed any commitments by this business to support economic growth and job creation within the community (the employees were mainly residents from that region)?",

  "Ați observat dacă angajații din această întreprindere sunt expuși la riscuri sau protecție insuficientă la locul de muncă?":
    "Have you noticed if the employees from this business were exposed to risks or insufficient protection in the workplace?",

  "În ce măsură credeți că această afacere face investiții sau are inițiative desfășurate pentru a îmbunătăți infrastructura și pentru a sprijini dezvoltarea durabilă?":
    "Have you noticed any investments or initiatives conducted by this business to improve infrastructure and support sustainable development (investments in renewable energy, such as solar and wind power, to create more reliable and sustainable energy sources, donations for building roads, schools, supporting local non-profit organisations)?",

  "În ce măsură credeți că această afacere contribuie la crearea unui mediu durabil și favorabil incluziunii în zonele urbane?":
    "To what extent do you believe this business contributes to creating a sustainable and inclusive environment in urban areas?",

  "În ce măsură credeți că această afacere ia măsuri pentru a răspunde nevoilor comunității sau pentru a promova sustenabilitatea mediului?":
    "Do you believe this business is taking any measures to address community needs or promote environmental sustainability?",

  "Cât de ecologică credeți că este această afacere prin oferta sa de produse și servicii? (cafea, ceai și ciocolată din comerț echitabil provenite de la producători responsabili din punct de vedere ecologic și social; fructe și legume organice cultivate fără pesticide sau îngrășăminte sintetice; oferă biciclete, e-bikes și scutere de închiriat în locul mașinilor; produsele lor sunt fabricate din bumbac organic, cânepă, bambus sau materiale reciclate)?":
    "How environmentally conscious are the products or services provided by this business (fair trade coffee, tea, and chocolate sourced from environmentally and socially responsible producers, organic fruits and vegetables grown without synthetic pesticides or fertilizers, bicycles, e-bikes and scooters available for renting instead of cars, clothing made from organic cotton, hemp, bamboo, or recycled materials)?",

  "În ce măsură credeți că această afacere promovează consumul sustenabil și reducerea deșeurilor? (opțiuni prietenoase cu mediul înconjurător, cum ar fi bunuri organice, de proveniență locală sau produse în mod etic, utilizarea unui număr minim de ambalaje, optarea pentru materiale reciclabile sau biodegradabile sau oferirea de opțiuni fără ambalaje, programe de reciclare disponibile, inițiative de compostare sau eforturi pentru a minimiza generarea de deșeuri)":
    "To what extent do you believe this business promotes sustainable consumption and reduction of waste (environmentally friendly options such as organic, locally sourced, or ethically produced goods, use minimal packaging, opt for recyclable or biodegradable materials, or offer packaging-free options, available recycling programs, composting initiatives, or efforts to minimise waste generation)?",

  "1.	Care sunt activitățiile concrete prin care afacerea ta contribuie la îndeplinirea obiectivelor menționate mai sus?":
    "1. What are the specific activities through which your business contributes to achieving the objectives mentioned above?",

  "Te rugăm să le descrii. (ex. afacerea mea combate poluarea cu plastic prin folosirea exclusivă a paielor din carton în activitatea zilnică)":
    "Please describe them. (e.g. my business fights plastic pollution by using exclusively cardboard straws in daily activity)",

  "2.	Îți dorești ca în viitorul apropiat să implementezi alte activități corelate cu atitudinea sustenabilă a afacerii tale? Dacă da, care ar fi acestea?":
    "2. Do you want to implement other activities related to the sustainable attitude of your business in the near future? If yes, what would they be?",

  "3.	Dispune afacerea ta de o strategie în ceea ce privește sustenabilitatea?":
    "3. Does your business have a sustainability strategy?",

  "4.	Realizezi în prezent o analiză a impactului pe care îl are afacerea ta din punct de vedere social, economic și al mediului înconjurător?":
    "4. Are you currently conducting an analysis of the impact your business has on social, economic, and environmental aspects?",

  '5.	Ai vreo certificare care atestă demersurile sustenabile pe care le întreprinde afacerea ta? Daca da, care sunt acelea? (ex. <a target="_blank" href="https://environment.ec.europa.eu/topics/circular-economy/eu-ecolabel_en">EU Ecolabel - Home (europa.eu)</a> )':
    '5. Do you have any certifications that attest to the sustainable initiatives your business undertakes? If yes, what are they? (e.g. <a target="_blank" href="https://environment.ec.europa.eu/topics/circular-economy/eu-ecolabel_en">EU Ecolabel - Home (europa.eu)</a>)',
  'OBIECTIVUL 2: FĂRĂ FOAMETE':
    'GOAL 2: ZERO HUNGER',
  'OBIECTIVUL 5: EGALITATE DE GEN':
    'GOAL 5: GENDER EQUALITY',
  'OBIECTIVUL 6: APĂ CURATĂ ȘI SANITAȚIE':
    'GOAL 6: CLEAN WATER AND SANITATION',
  'OBIECTIVUL 7: ENERGIE CURATĂ ȘI LA PREȚURI ACCESIBILE':
    'GOAL 7: AFFORDABLE AND CLEAN ENERGY',
  'OBIECTIVUL 8: MUNCĂ DECENTĂ ȘI CREȘTERE ECONOMICĂ':
    'GOAL 8: DECENT WORK AND ECONOMIC GROWTH',
  'OBIECTIVUL 9: INDUSTRIE, INOVAȚIE ȘI INFRASTRUCTURĂ':
    'GOAL 9: INDUSTRY, INNOVATION, AND INFRASTRUCTURE',
  'OBIECTIVUL 11: ORAȘE ȘI COMUNITĂȚI SUSTENABILE':
    'GOAL 11: SUSTAINABLE CITIES AND COMMUNITIES',
  'OBIECTIVUL 12: CONSUM ȘI PRODUCȚIE RESPONSABILE':
    'GOAL 12: RESPONSIBLE CONSUMPTION AND PRODUCTION',
  '(Exemple de acțiuni: furnizorii tăi sunt producători locali, îți cultivi o parte din alimente singur(ă), ai o varietate de opțiuni vegetariene în meniu)':
    '(Examples of actions: your suppliers are local producers, you grow some of your food yourself, you have a variety of vegetarian options on the menu)',

  '(Exemple de acțiuni: ești conștient(ă) cu privire la stereotipurile de gen și cum să le eviți)':
    '(Examples of actions: you are aware of gender stereotypes and how to avoid them)',

  '(Exemple de acțiuni: depui eforturi pentru utilizarea eficientă a apei, tratarea apelor reziduale, tehnologii de reciclare și reutilizare)':
    '(Examples of actions: you make efforts for efficient water use, wastewater treatment, recycling and reuse technologies)',

  '(Exemple de acțiuni: utilizezi baterii reîncărcabile, ai programe automate de închidere a aerului condiționat/sistemelor de încălzire atunci când nu sunt necesare, utilizezi panouri solare pentru încălzire și electricitate)':
    '(Examples of actions: you use rechargeable batteries, have automatic programs to shut down air conditioning/heating systems when not needed, use solar panels for heating and electricity)',

  '(Exemple de acțiuni: promovezi turismul durabil care creează locuri de muncă și promovează cultura și produsele locale, oferi remunerare egală pentru munca de valoare egală, promovezi un mediu de lucru sigur și securizat pentru toți lucrătorii, inclusiv lucrătorii migranți)':
    '(Examples of actions: you promote sustainable tourism that creates jobs and promotes local culture and products, you offer equal pay for equal work, you promote a safe and secure working environment for all workers, including migrant workers)',

  '(Exemple de acțiuni: te implici în cercetarea, dezvoltarea și implementarea de produse, servicii și modele de afaceri pentru a furniza o infrastructură durabilă și rezistentă, investești în înbunătățirea și modernizarea infrastructurii și a activelor industriale în cadrul operațiunilor proprii și al lanțului de aprovizionare pentru a le face durabile și rezistente.)':
    '(Examples of actions: you are involved in research, development and implementation of products, services and business models to provide sustainable and resilient infrastructure, you invest in improving and modernizing infrastructure and industrial assets within your own operations and supply chain to make them sustainable and resilient.)',

  '(Exemple de acțiuni: sprijini inițiative, asociații sau fundații implicate în dezvoltarea orașelor și comunităților sustenabile prin donații sau alte resurse, participi la construcția sau renovarea locuințelor, la amenajarea spațiilor publice sau a spațiilor verzi, încurajezi transportul sustenabil – biciclete, mașini electrice)':
    '(Examples of actions: you support initiatives, associations or foundations involved in the development of sustainable cities and communities through donations or other resources, you participate in the construction or renovation of housing, public spaces or green spaces, you encourage sustainable transportation – bicycles, electric cars)',

  'Exemple de acțiuni: faci achiziții de la companii despre care știi că au practici sustenabile și care nu dăunează mediului, alegi/ oferi produse reutilizabile, reduci consumul de ambalaje, ești atent la managementul ecologic al substanțelor chimice și a tuturor deșeurilor pe parcursul ciclului de viață al acestora )':
    '(Examples of actions: you make purchases from companies you know have sustainable practices and do not harm the environment, you choose/offer reusable products, reduce packaging consumption, pay attention to the ecological management of chemicals and all waste throughout their life cycle)',

  'Pe baza informațiilor furnizate în acest process de evaluare, cum apreciați această afacere din punct de vedere al sustenabilității, pe o scară de la 1 la 6 (1 indicând niciun efort și 6 indicând un efort excelent)?':
    'Based on all the information provided in the previous assessment stage, how would you rate this business in terms of sustainability on a scale from 1 to 6? (1 indicating no effort and 6 indicating excellent effort)?',
  'Doriți să adăugați alte informații despre experiența cu serviciile / produsele oferite de această afacere?':
    'Would you like to add more information about your experience with this business?',
  '« Oferă un scor ca număr de frunze!': 'Give a score as a number of leaves!',
  'Alegeți o opțiune!': 'Choose an option!',
  "Pescuit și acvacultură": "Fisheries and aquaculture",
  "Industria alimentară": "Food industry",
  "Fabricarea băuturilor": "Manufacture of beverages",
  "Fabricarea produselor textile": "Manufacture of textiles",
  "Fabricarea articolelor de îmbrăcăminte": "Manufacture of clothing items",
  "Tăbăcirea și finisarea pieilor; fabricarea articolelor de voiaj și marochinărie":
    "Manufacture of leather, luggage and handbags",
  "Prelucrarea lemnului, fabricarea produselor din lemn și plută, cu excepția mobilei":
    "Manufacture of wood and products of wood and cork, except furniture",
  "Fabricarea hârtiei și a produselor din hârtie": "Manufacture of paper and paper products",
  "Transport": "Transport",
  "Hoteluri și alte facilități de cazare": "Hotels and other accommodation facilities",
  "Restaurante și alte activități de servicii de alimentație": "Restaurants and other food service activities",
  "Activități sportive, recreative și distractive": "Sporting, recreational and leisure activities",
  "Activități de creație si interpretare artistică": "Creative and performing arts activities",
  "Alt domeniu": "Other domain",
  "Asociația Europeană Association for Social Innovation (coordonator de proiect), România": "The European Association for Social Innovation (Project coordinator), Romania",
  "EaSI este o rețea europeană motivată să promoveze inovarea socială în rândul membrilor săi și al altor părți interesate în scopul găsirii de noi soluții pentru problemele societății. În prezent, EaSI are peste 30 de membri din 15 țări europene care au ca obiectiv promovarea inovării sociale în diferite domenii (social, educație, justiție, dezvoltare rurală, antreprenoriat și altele).":
    "EaSI is a European network driven by the motivation to promote social innovation among its members and other key stakeholders in the scope of finding new solutions for societal problems. Currently, it has more than 30 members from 15 European countries aiming to advance social innovation in different domains (social, educational, justice, rural development and others).",
  "CCF - Centrul de Cercetare si Formare a Universitatii de Nord Baia Mare, Romania": "CCF - Centrul de Cercetare si Formare a Universitatii de Nord Baia Mare, Romania",
  "Asociația Centrul de Cercetare și Formare a Universității de Nord Baia Mare are în statutul ei obiective de cercetare socială și s-a implicat în elaborarea și derularea de proiecte în domenii diverse ale realității sociale.":
    "CCF has social research objectives in its statute and has been involved in the development and implementation of projects in various areas of social reality.",
  "CSR Innovative Solutions, Norvegia": "CSR Innovative Solutions, Norway",
  "La CSR Innovative Solutions, obiectivul nostru este de a determina schimbări durabile în întreprinderi prin intermediul planificării și raportării ESG și, mai ales, prin metode inovatoare de colectare a datelor și tehnologii moderne. Ne străduim să atingem excelența, inovarea și implementarea sustenabilității în tot ceea ce facem. Misiunea noastră este de a evidenția impactul pozitiv al companiilor asupra mediului și de a promova transparența în industrie. Punem preț pe incluziunea socială și ne sprijinim partenerii cu strategii și consultanță pentru a le responsabiliza personalul și clienții țintă.":
    "At CSR Innovative Solutions, our goal is to drive sustainable change in businesses through ESG planning and reporting and more specifically through innovative data collection methods and modern technologies. We strive for excellence, innovation, and implementation of sustainability in all that we do. Our mission is to highlight the positive impact of companies on the environment and promote transparency in the industry. We value social inclusion and support our partners with strategies and consultation to empower their staff and target clients.",
  "😔 Nu am găsit nici un articol cu acest subiect sau conținut!": "😔 We didn't find any articles with this subject or content!",
  'cauta "sustenabilitate"': 'search for "sustainablity"',
  'Alte informații': 'Other information',
  'Recenzie': 'Review',
  'Vezi lista completă': 'See full list',
  "Platforma are un design atractiv (culori, design, fontul și culoarea textului, imagini).":
    "The platform has an attractive design (colors, design, font and color of the text, images).",

  "Platforma este ușor de navigat și de utilizat.":
    "The platform is easy to navigate and easy to use.",

  "Resursele disponibile pe platformă sunt utile și ușor de înțeles.":
    "The resources available on the platform are useful and easy to understand.",

  "Traseul sustenabilității și harta sunt ușor de navigat pe platformă.":
    "The sustainability circuit and the map are easy to navigate on the platform.",

  "Platforma este accesibilă și adaptată pentru persoanele cu dizabilități.":
    "The platform is accessible and adapted for people with disabilities.",

  "Vă rugăm să descrieți punctele forte și punctele slabe ale platformei care vizează promovarea de afaceri sustenabile.":
    "Please describe the strengths and weaknesses of the platform aimed at promoting sustainable business.",

  "Ce secțiuni ați îmbunătăți pe platformă și în ce mod?":
    "What sections would you improve on the platform and how?",

  "A fost ușor să vă înregistrați afacerea pe platformă.":
    "Registering your business on the platform was easy.",
  "Trimite formularul": "Submit form",
  'Mulțumim pentru feedback! Sugestiile primite ne ajută să aducem îmbunătățiri platformei "Sustain Circiut".':
    'Thank you for the feedback! The suggestions received help us make improvements to the "Sustain Circuit" platform.',
  'Punctele forte și punctele slabe ?': 'Strengths and weaknesses ?',
  'Ce ați îmbunătăți ?': 'What would you improve ?',
  'Valori selectate': 'Selected values',
  'Feedback pentru platforma Sustain Circuit': 'Feedback for the Sustain Circuit platform',
  'Recenzii pentru locații': 'Reviews for locations',
  'Ce vrei să afli?': 'What are you looking for?',
  'Folosește bara de căutare pentru a găsi știrile care te interesează.': 'Use the search bar to explore the news that interest you.',
  'Pornește într-o călătorie verde de neuitat!': 'Embark on a journey that is as eco-friendly as it is unforgettable!',
  'Explorează selecția noastră de destinații turistice durabile, unde peisaje uluitoare se întâlnesc cu practici de călătorie responsabile. De la cabane / pensiuni construite în mijlocul naturii, la proiecte deconservare conduse de comunități, fiecare destinație de pe lista noastră este un exemplu al sustenabilității.': 'Explore our selection of sustainable tourist destinations, where breathtaking landscapes meet responsible travel practices. From eco-lodges nestled in pristine wilderness to community-led conservation projects, every destination on our list is a beacon of sustainability. Discover products crafted with care, from earth-friendly materials to ethical practices, ensuring you can shop guilt-free. ',
  'Descoperă frumusețea țării noastre într-un mod care protejează și îmbunătățește mediul înconjurător.': 'Discover products crafted with care, from earth-friendly materials to ethical practices, ensuring you can shop guilt-free.',
  'Aventura ta începe aici!': 'Experience the beauty of our country while leaving only footprints behind. Your adventure awaits!',
  'Având un impact direct asupra mediului înconjurător, turismul joacă un rol important în menținerea peisajelor verzi și nealterate. Așadar, pentru ca generațiile viitoare să se poată bucura de aceleași cadre autohtone pline de viață și tradiție, o planificare responsabilă a călătoriilor, cât și dezvoltarea unor experiențe turistice sustenabile sunt necesare.': 'Having a direct impact on the environment, tourism plays an important role in maintaining green and unaltered landscapes. Therefore, responsible travel planning and the development of sustainable tourism experiences are necessary to ensure that future generations can experience the same vibrant and traditional local settings.',
  'Turismul durabil ține cont de trei aspecte centrale: susținerea beneficiilor economice și sociale ale comunităților locale, protejarea patrimoniului cultural și protejarea mediului natural. O activitate turistică responsabilă ar trebui să aibă un impact pozitiv asupra acestor trei direcții și în același timp, să reducă impactul negativ asupra resurselor naturale și a comunității locale.': 'Sustainable tourism considers three central aspects: sustaining the economic and social benefits of local communities, protecting cultural heritage and protecting the natural environment. Responsible tourism activity should have a positive impact on all of these areas and at the same time, reduce the negative impact on natural resources and the local community.',
  'Harta Sustain Circuit te poate ghida în realizarea unor alegeri sustenabile cu privire la elementele principale ale unei călătorii prin intermediul unei interfețe interactive ce cuprinde: unități de cazare, activități de recreere și experiențe culinare. Fiecare profil înregistrat pe platformă aparține unei afaceri care se ghidează după anumite principii sustenabile și contribuie activ prin activitatea sa la Obiectiectivele Dezvoltării Durabile. Explorând harta, vei avea posibilitatea să iți construiești propriul circuit sustenabil în funcție de interesele tale.': 'The Sustain Circuit map can assist and guide you in making sustainable choices about the main elements of a trip through an interactive interface including: accommodation facilities, leisure activities and culinary experiences. Each profile registered on the platform belongs to a business that is guided by certain sustainable principles and actively contributes to the Sustainable Development Goals through its activity. By exploring the map, you will be able to build your own sustainable circuit according to your interests.',
  'Știri și noutăți': 'News and updates',
  'Cursuri': 'Courses',
  'Aceste resurse de învățare sunt pentru TINE!': 'These learning resources are for YOU!',
  'Planifică-ți propria călătorie în mod sustenabil!': 'Plan your own sustainable journey!',
  'DESPRE SUSTAIN CIRCUIT': 'ABOUT SUSTAIN CIRCUIT',
  'Actualmente, IMM-urile, în special cele din zonele rurale, au un acces limitat la programe educaționale privind digitalizarea, la trecerea la servicii mai prietenoase cu planeta și la planuri de afaceri mai durabile.': 'SMEs, especially from rural areas, have limited access to educational programmes concerning digitalisation, the shift to more planet-friendly services and more sustainable business plans.',
  'Sustain Circuit este un demers cuprinzător, conceput pentru a permite IMM-urilor din zonele rurale, în special cele din din N-V României, să se dezvolte prin transformare digitală și să adopte practici durabile. Prin îmbinarea unor soluții digitale eficiente cu strategii sustenabile, creăm un traseu pentru ca întreprinderile să se dezvolte, reducând în același timp la minimum impactul lor asupra mediului.': 'Sustain Circuit is a comprehensive endeavour designed to empower SMEs from the N-W of Romania rural areas to thrive through digital transformation and embrace sustainable practices. By merging effective digital solutions with sustainable strategies, we are creating a roadmap for businesses to grow while minimising their environmental impact.',
  'Sustain Circuit va urma 3 direcții principale de acțiune: schimbul de bune practici în domeniul inovării ecologice și al economiei circulare pentru a promova noi modele de afaceri; dobândirea de competențe verzi pentru a introduce factori critici de sustenabilitate  și dezvoltarea de competențe digitale pentru a dezvolta noi oportunități, prezența pe noi piețe și segmente și pentru a crește veniturile.': 'Sustain circuit will follow 3 main directions of action: the exchange of good practices in green innovation and circular economy to foster new business models, the acquisition of green skills to introduce critical sustainability factors and the development of digital skills to enhance new opportunities, presence in new markets and segments and increase revenues.',
  'Resursele Sustain Circuit': 'Sustain Circuit resources',
  'Platforma Sustain Circuit cu scopul de a prezenta întreprinderile mici și mijlocii (IMM-uri) sustenabile și de a sensibiliza consumatorii cu privire la importanța acestora și a economiei circulare.': 'Sustain Circuit platform built to showcase sustainable small and medium-sized enterprises (SMEs), raise consumer awareness of their importance and of the circular economy.',
  'Harta sustenabilității Sustain Circuit construită pe baza cunoștințelor și experiențelor împărtășite': 'Sustain Circuit sustainability map built on shared knowledge and experiences',
  'Cursul de digitalizare pentru a ajuta IMM-urile să se extindă în online': 'Digitisation course designed to help SMEs expand online',
  'Cursul de inițiere în economie circulară și valori sustenabile': 'Introductory course on circular economy and sustainable values',
  'Programul de mentorat Sustain Circuit': 'Sustain Circuit mentoring programme',
  'Sustain Circuit este un proiect finanțat cu sprijinul granturilor acordate de Islanda, Liechtenstein și Norvegia prin mecanismul financiar SEE 2014-2021, în cadrul Programului Dezvoltarea IMM-urilor din România și cu sprijinul granturilor acordate prin mecanismul financiar Norvegian 2014-2021, în cadrul Programului Dezvoltarea IMM-urilor din România.': 'Sustain Circuit is a project supported by a grant from Iceland, Liechtenstein and Norway through the EEA Grants Romania 2014-2021 and Norway Grants 2014-2021, in the frame of the SME Growth Programme Romania.',
  'Află mai multe': 'Learn more',
  'Abonează-te la newsletter': 'Subscribe to our newsletter',
  'un viitor verde, competitiv și incluziv!': 'a green, competitive and inclusive future!',
  'SUNT ANTREPRENOR': 'I am an entrepreneur',
  'SUNT CONSUMATOR': 'I am a consumer',
  'Beneficii antreprenor': 'Benefits for entrepreneurs',
  'Prin crearea unui cont pe platforma Sustain Circuit veți avea acces la o gamă largă de beneficii, printre care:': 'By creating an account on the Sustain Circuit platform you will have access to a wide range of benefits, including:',
  '- Creșterea vizibilității: prezența pe platformă vă va expune afacerea unui public țintă interesat de produse și servicii sustenabile': '- Increased visibility: being on the platform will increase exposure of your business to a target audience interested in sustainable products and services',
  '- Acces la publicul țintă: platforma Sustain Circuit atrage utilizatori care caută în mod special întreprinderi și produse sustenabile': '- Access to your target audience: the Sustain Circuit platform attracts users specifically looking for sustainable businesses and products',
  '- Oportunități de colaborare și de networking: prin intermediul platformei Sustain Circuit, puteți intra în contact cu alte întreprinderi cu valori comune, cu potențiali parteneri și cu experți din industrie': '- Collaboration and networking opportunities: through the Sustain Circuit platform, you can connect with other businesses with shared values, potential partners and industry experts',
  '- Resurse și oportunități educaționale: oferim resurse și sprijin pentru a ajuta întreprinderile să își îmbunătățească practicile de sustenabilitate și digitalizare': '- Resources and educational opportunities: we provide resources and support to help businesses improve their sustainability and digitalisation practices',
  '- Alinierea la ODD (Obiectivele de Dezvoltare Durabilă) ale ONU: prin aderarea la platforma Sustain Circuit, afacerea dvs. se aliniază la valorile comune de promovare a sustenabilității și de gestionare a mediului': '- Alignment with the UN SDGs (Sustainable Development Goals): by joining the Sustain Circuit platform, your business aligns with shared values of promoting sustainability and environmental stewardship',
  'Beneficii consumator': 'Benefits for consumers',
  'În calitate de consumator, platforma Sustain Circuit vă oferă o gamă variată de beneficii printre care:': 'As a consumer, the Sustain Circuit platform offers you a range of benefits including:',
  '- Acces la furnizori aliniați valorilor sustenabile prezenți în zona de N-V a României': '- Access to suppliers aligned with sustainable values who are present in the North-West of Romania',
  '- Acces la resurse practice pentru organizarea unui circuit sustenabil personalizat în zona de N-V a României': '- Access to practical resources for organising a customised sustainable circuit in the North-West part of Romania',
  '- Resurse educaționale: platforma Sustain Circuit oferă resurse dedicate dobândirii informațiilor de bază în ceea ce privește valorile sustenabile': '- Educational resources: the Sustain Circuit platform offers dedicated resources to acquire basic information on sustainable values',
  '- Contribuție directă la crearea și menținerea unui turism românesc sustenabil': '- Direct contribution to the creation and maintenance of sustainable tourism in Romania',
  '- Oportunitatea de a evalua anumiți furnizori de servicii/produse sustenabile și de a consulta recenzii de la alți utilizatori': '- Opportunity to evaluate specific sustainable service/product providers and to consult reviews from other users',
  'Cursurile Sustain Circuit': 'Sustain Circuit Courses',
  'Folosește harta de mai jos pentru a defini traseul pe care vrei să îl urmezi. Ulterior, vei putea descărca și consulta informațiile despre fiecare locație selectată și în sistem offline.': 'Use the map below to define the route you want to follow. Later, you will be able to download and consult the information about each selected location in offline mode.',
  'Dacă dorești să îți creezi un circuit pe mai multe zile, poți utiliza această ': 'If you want to create a multi-day circuit, you can use this ',
  'resursă ': 'resource ',
  'pentru a pune la punct toate detaliile unei experiențe sustenabile.': 'to put together all the details of a sustainable experience.',
  "Adaugă la traseul meu": "Add to my circuit",
  "Elimină din traseul meu": "Remove from my circuit",
  "Descarcă traseul meu": "Download my circuit",
  "oră": "hour",
  "ore": "hours",
  "și": "and",
};
export type Labels = keyof typeof labels;

export const useTranslate = () => {
  const { lang } = useLang();
  return (label: Labels) =>
    lang === "ro"
      ? label
      : ((labels[label]
        ? (labels as any)[label][lang] || labels[label]
        : label) as string);
};
