import React, { useState } from "react";
import Lang from "./Lang";
import Fa from "./FontAwesome";

const AutoPlayVideo = () => {
  const [showModalEntrepreneur, setShowModalEntrepreneur] = useState(false);
  const [showModalConsumer, setShowModalConsumer] = useState(false);

  const toggleModalEntrepreneur = () => setShowModalEntrepreneur(!showModalEntrepreneur);
  const toggleModalConsumer = () => setShowModalConsumer(!showModalConsumer);

  return (
    <div style={{ position: "relative" }}>
      <video
        width="100%"
        height="auto"
        autoPlay
        muted
        loop
        style={{ width: "100%" }}
      >
        <source
          src={`${process.env.PUBLIC_URL}/Home-Page.mp4`}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>

      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 10,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <button
          className="w-btn us-btn-style_3"
          style={{ marginBottom: "20px", width: "200px" }}
          onClick={toggleModalEntrepreneur}
        >
          <Lang label="SUNT ANTREPRENOR" />
        </button>
        <button
          className="w-btn us-btn-style_3"
          style={{ width: "200px" }}
          onClick={toggleModalConsumer}
        >
          <Lang label="SUNT CONSUMATOR" />
        </button>
      </div>

      {/* Modal for Entrepreneur */}
      {showModalEntrepreneur && (
        <div
          className="modal show"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
          tabIndex={-1}
        >
          <div className="modal-dialog">
            <div className="modal-content" style={{ top:60, backgroundColor: "rgba(255, 255, 255, 0.7)" }}>  
              <div className="modal-header">
                <h5 className="modal-title text-center" style={{ width: "100%" }}>
                  <Lang label="Beneficii antreprenor" />
                </h5>
                <button
                  type="button"
                  className="close"
                  style={{ position: "absolute", top: 15, right: 10, border: 0, background: 'none', color: 'inherit' }}
                  onClick={toggleModalEntrepreneur}
                >
                 <Fa light="fa-solid fa-xmark" />
                </button>
              </div>
              <div className="modal-body" style={{ margin: "0 30px", textAlign: "justify" }}>
                <p>
                  <Lang label="Prin crearea unui cont pe platforma Sustain Circuit veți avea acces la o gamă largă de beneficii, printre care:" />
                </p>
                <p>
                  <Lang label="- Creșterea vizibilității: prezența pe platformă vă va expune afacerea unui public țintă interesat de produse și servicii sustenabile" />
                </p>
                <p>
                  <Lang label="- Acces la publicul țintă: platforma Sustain Circuit atrage utilizatori care caută în mod special întreprinderi și produse sustenabile" />
                </p>
                <p>
                  <Lang label="- Oportunități de colaborare și de networking: prin intermediul platformei Sustain Circuit, puteți intra în contact cu alte întreprinderi cu valori comune, cu potențiali parteneri și cu experți din industrie" />
                </p>
                <p>
                  <Lang label="- Resurse și oportunități educaționale: oferim resurse și sprijin pentru a ajuta întreprinderile să își îmbunătățească practicile de sustenabilitate și digitalizare" />
                </p>
                <p>
                  <Lang label="- Alinierea la ODD (Obiectivele de Dezvoltare Durabilă) ale ONU: prin aderarea la platforma Sustain Circuit, afacerea dvs. se aliniază la valorile comune de promovare a sustenabilității și de gestionare a mediului" />
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal for Consumer */}
      {showModalConsumer && ( <div
          className="modal show"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
          tabIndex={-1}
        >
          <div className="modal-dialog">
            <div className="modal-content" style={{ top:60, backgroundColor: "rgba(255, 255, 255, 0.7)" }}>  
              <div className="modal-header">
                <h5 className="modal-title text-center" style={{ width: "100%" }}>
                  <Lang label="Beneficii consumator" />
                </h5>
                <button
                  type="button"
                  className="close"
                  style={{ position: "absolute", top: 15, right: 10, border: 0, background: 'none', color: 'inherit' }}
                  onClick={toggleModalConsumer}
                >
                 <Fa light="fa-solid fa-xmark" />
                </button>
              </div>
              <div className="modal-body" style={{ margin: "0 30px", textAlign: "justify" }}>
              <p>
                  <Lang label="În calitate de consumator, platforma Sustain Circuit vă oferă o gamă variată de beneficii printre care:" />
                </p>
                
                <p>
                    <Lang label="- Acces la furnizori aliniați valorilor sustenabile prezenți în zona de N-V a României" />
                    </p>
                  <p>
                    <Lang label="- Acces la resurse practice pentru organizarea unui circuit sustenabil personalizat în zona de N-V a României" />
                    </p>
                  <p>
                    <Lang label="- Resurse educaționale: platforma Sustain Circuit oferă resurse dedicate dobândirii informațiilor de bază în ceea ce privește valorile sustenabile" />
                    </p>
                  <p>
                    <Lang label="- Contribuție directă la crearea și menținerea unui turism românesc sustenabil" />
                    </p>
                  <p>
                    <Lang label="- Oportunitatea de a evalua anumiți furnizori de servicii/produse sustenabile și de a consulta recenzii de la alți utilizatori" />
                    </p>
              </div>
            </div>
          </div>
        </div> )}
    </div>
  );
};

export default AutoPlayVideo;
