import { RecenzieEntity } from '../../../../backend/src/shared/entity';
import Lang from '../Lang';
import FormComposer from '../FormComposer/FormComposer';
import FileUpload from '../FileUpload/FileUpload';
import FormElement from '../FormComposer/FormElement';
import Fa from '../FontAwesome';
import Form from '../Form';
import { useEffect, useState } from 'react';
import { FormDataItem, Options } from '../../common/types';
import ReviewLeaf from '../ReviewLeaf';

const RecenzieAdaugare = ({ nume = "", model = {} as RecenzieEntity, setModel = (value: RecenzieEntity) => { }, handleCancel = () => { }, handleSave = () => { } }) => {
  const [overall, setOverall] = useState('');
  const [leaf, setLeaf] = useState(0); // review as number of leafs
  const handleSubmit = () => {
    const valoare = [overall, leaf, ...JSON.parse(model.Raspuns).map((e: FormDataItem) => e.value)].join(',');
    model.Valoare = valoare;
    handleSave();
  }
  useEffect(() => {
    // set focus on first input / textarea
    const input = document.querySelector('input, textarea');
    if (input) (input as HTMLElement).focus();
  }, [])

  return (
    <Form onSubmit={handleSubmit}>
      <p className='text-large'>
        <Lang label='Completează o recenzie' /> — {nume} 🌿🌿🌿🌿🌿
      </p>
      <FormElement label='Cât de mulțumit/ă sunteți de produsele/serviciile oferite de această afacere?'
        value={overall}
        formControl='options'
        options={Options.Overall}
        onChange={e => setOverall(e)} />
      <h4>
        <Lang label='Vă rugăm să ne ajutați să evaluăm cât de mult contribuie această afacere la Obiectivele de Dezvoltare Durabilă (ODD).' />
      </h4>
      <FormComposer items={JSON.parse(model.Raspuns)} onChange={e => setModel({ ...model, Raspuns: JSON.stringify(e) })} />
      <br />
      <Lang label='Pe baza informațiilor furnizate în acest process de evaluare, cum apreciați această afacere din punct de vedere al sustenabilității, pe o scară de la 1 la 6 (1 indicând niciun efort și 6 indicând un efort excelent)?' />
      <ReviewLeaf leaf={leaf} setLeaf={setLeaf} />

      <FileUpload files={JSON.parse(model.Galerie || '[]')} onChange={e => setModel({ ...model, Galerie: JSON.stringify(e) })} />
      <FormElement label="Nume și prenume (opțional)" required={false} value={model.Nume}
        onChange={e => setModel({ ...model, Nume: e })}
      />
      <FormElement label="Doriți să adăugați alte informații despre experiența cu serviciile / produsele oferite de această afacere?" value={model.Review}
        onChange={e => setModel({ ...model, Review: e })}
        formControl='tinymce'
      />

      <button type='submit'><Fa light='save fa-xl' /><Lang label='Salvează' /></button>
      <button className='btn btn-dark' onClick={e => [e.preventDefault(), handleCancel()]}><Fa light='ban fa-xl text-danger' /><Lang label='Renunță' /></button>
    </Form>
  )
}

export default RecenzieAdaugare
