import { Link, useNavigate } from "react-router-dom";
import Lang from "./Lang";
import { logoutUser } from "../common/user";
import { useUser } from "../hooks/useUser";

const AccountConfig = () => {
  const { setUser } = useUser();
  const navigate = useNavigate();
  return (
    <>
      <div className="row">
        <div className="col">
          <Link to="/" className="float-end" onClick={e => [e.preventDefault(), logoutUser().then(setUser).then(() => navigate('/'))]}>
            <i className="fa-solid fa-arrow-right-from-arc text-danger me-2"></i>
            <Lang label="Deconectare" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default AccountConfig;
