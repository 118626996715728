import { useQuery } from "@tanstack/react-query";
import React from "react";
import sql from "../../common/sql";
import { NoutatiEntity } from "../../../../backend/src/shared/entity";
import { useParams } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import "tinymce/tinymce";
import PageTitle from "../../components/PageTitle";
import Lang from "../../components/Lang";
import { useTranslate } from "../../common/lang";
import Loading from "../../components/Loading/Loading";
import FileUpload from "../../components/FileUpload/FileUpload";
import FormElement from "../../components/FormComposer/FormElement";
import Form from "../../components/Form";

const NoutatiAdminDetails = () => {
  const translate = useTranslate();
  const pageTitle = translate("Noutăți");
  PageTitle(pageTitle);
  const { user } = useUser();
  const { id } = useParams<{ id: string }>();
  const [model, setModel] = React.useState<NoutatiEntity>({} as NoutatiEntity);

  const { isLoading, data } = useQuery({
    queryKey: ["NoutatiEntity", id],
    queryFn: () =>
      sql.getEntity("dbo.Noutati", +(id || 0)) as Promise<NoutatiEntity>,
  });

  const handleSave = async () => {
    await sql.saveEntity("dbo.Noutati", model);
    window.location.href = "/admin/noutati";
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = event.target;
    setModel({ ...model, [name]: value });
  };

  if (!isLoading && data && model.titlu === undefined) {
    setModel({
      ...data,
      data_adaugarii: new Date(),
      adaugat_de: user.email,
      vizibil: 1,
    });
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Form onSubmit={handleSave}>
      <h1 className="text-center">
        <Lang label="Noutăți" />
      </h1>
      <button type="submit">
        <Lang label="Salvează"></Lang>
      </button>

      <Lang label="Imagine">
        <FileUpload
          files={JSON.parse(model.poza || "[]")}
          multiple={true}
          className="w-100"
          onChange={(files) =>
            setModel({ ...model, poza: JSON.stringify(files) })
          }
        />
      </Lang>
      <Lang label="Titlu">
        <textarea
          name="titlu"
          placeholder={translate("Titlu")}
          required
          rows={2}
          value={model.titlu || ""}
          onChange={handleChange}
        />
      </Lang>
      <Lang label="Titlu EN">
        <textarea
          name="titlu_en"
          placeholder={translate("Titlu EN")}
          required
          rows={2}
          value={model.titlu_en || ""}
          onChange={handleChange}
        />
      </Lang>
      <FormElement
        label="Descriere scurta RO"
        formControl="textarea"
        value={model.descriere_scurta || ""}
        onChange={(e) => setModel({ ...model, descriere_scurta: e })}
      />
      <Lang label="Descriere scurta EN">
        <textarea
          name="descriere_scurta_en"
          placeholder={translate("Descriere scurta EN")}
          required
          rows={3}
          value={model.descriere_scurta_en || ""}
          onChange={handleChange}
        />
      </Lang>
      <FormElement
        label={"Descriere lunga RO"}
        value={model.descriere_lunga || "Descriere lunga RO..."}
        formControl="tinymce"
        onChange={(e) => setModel({ ...model, descriere_lunga: e })}
      />
      <FormElement
        label={"Descriere lunga EN"}
        value={model.descriere_lunga_en || "Descriere lunga EN..."}
        formControl="tinymce"
        onChange={(e) => setModel({ ...model, descriere_lunga_en: e })}
      />
      <Lang label="Publicat">
        <select name="vizibil" value={model.vizibil} onChange={handleChange}>
          <option value={1}>
            <Lang label="Da"></Lang>
          </option>
          <option value={0}>
            <Lang label="Nu"></Lang>
          </option>
        </select>
      </Lang>
      <Lang label="Data adaugării">
        <input
          type="text"
          name="data_adaugarii"
          value={
            model.data_adaugarii ? model.data_adaugarii.toLocaleString() : ""
          }
          
        />
      </Lang>
      <Lang label="Adaugat de">
        <input
          type="text"
          name="adaugat_de"
          readOnly
          value={model.adaugat_de || ""}
        />
      </Lang>
      <button type="submit">
        <Lang label="Salvează"></Lang>
      </button>
    </Form>
  );
};

export default NoutatiAdminDetails;
