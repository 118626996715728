import { ChangeEvent, useState } from "react";
import Input from "../../components/Input";
import { RegisterInfo, registerUser } from "../../common/user";
import { Link } from "react-router-dom";
import LoginContainer from "../../components/LoginContainer";
import ErrorBox from "../../components/ErrorBox";
import Title from "../../components/Title";
import { SqlError } from "../../common/sql";
import Fa from "../../components/FontAwesome";
import Form from "../../components/Form";
import { useTranslate } from "../../common/lang";

const RegisterPage = () => {
  const [model, setModel] = useState<RegisterInfo>({
    name: "",
    email: "",
    password: "",
    passwordRepeat: "",
  });
  const translate = useTranslate();
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState<SqlError>();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setModel((model) => ({ ...model, [e.target.name]: e.target.value }));
    setError(undefined);
  };

  const translateError = (error: string) => {
    const template = (str: string) => {
      // prima variabilă dintr-un string de forma "bla bla @variabilă bla bla"
      // este de la prima apariție a caracterului "@" până la primul caracter care nu este literă sau cifră
      // sau până la sfârșitul șirului dacă nu există niciun caracter care nu este literă sau cifră
      const index = str.indexOf('@');
      if (index === -1) return '';
      const start = index + 1;
      let end = start;
      while (end < str.length && /[a-zA-Z0-9]/.test(str[end])) end++;
      return '@' + str.substring(start, end);
    }

    const [message, ...values] = error.split('⟣');
    console.log(message, values);
    error = translate(message as any);
    values.forEach((value) => {
      error = error.replace(template(error), value);
    });
    return error;
  }
  
  const handleSubmit = () => {
    registerUser(model)
      .then(() => {
        setCompleted(true);
      })
      .catch(e => setError(e.message ? translateError(e.message) : e));
  };
  const succesText = "Un email de confirmare a fost trimis la adresa <strong>@email</strong>.<br/>Pentru a finaliza crearea contului, accesează link-ul din email.";
  return (
    <LoginContainer>
      {completed ? (
        <div>

          <h5 className="card-title mb-4"><Fa light="user-check fa-xl me-2 text-success" /> {translate("Cont creat cu succes !")}</h5>
          <p className="card-text" dangerouslySetInnerHTML={{ __html: translate(succesText).replace('@email', model.email) }}></p>
          <a href="/home" className="card-link">{translate("Închide")}</a>
        </div>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Title icon="fa-user-plus">{translate("Crează cont nou")}</Title>
          <Input
            name="email"
            type="email"
            pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
            minLength={10}
            required
            value={model.email}
            onChange={handleChange}
          >
            {translate("Adresa de email")}
          </Input>

          <Input
            type="password"
            name="password"
            // pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
            required
            value={model.password}
            onChange={handleChange}
          >
            {translate("Crează o parolă")}
          </Input>

          <Input
            type="password"
            name="passwordRepeat"
            // pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
            required
            value={model.passwordRepeat}
            onChange={handleChange}
          >
            {translate("Repetă parola")}
          </Input>

          <Input
            type="text"
            name="name"
            required
            value={model.name}
            onChange={handleChange}
          >
            {translate("Nume și prenume")}
          </Input>

          <ErrorBox>{error}</ErrorBox>
          <button type="submit">
            {translate("Crează cont")}
          </button>

          <p className="my-3">
            <small>
              {translate("Ai deja cont ?")}<Link to="/login"> {translate("Autentificare")}</Link>
            </small>
          </p>
        </Form>
      )}
    </LoginContainer>
  );
};

export default RegisterPage;
