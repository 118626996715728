import Modal from "./components/Modal";
import { Toaster } from "sonner";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Router from "./components/Router";
import { LanguageProvider } from "./hooks/useLang";
import "./App.scss";
import { UserProvider } from "./hooks/useUser";

const queryClient = new QueryClient();

const App = () => {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <LanguageProvider>
            <Router />
            <Toaster
              // closeButton
              richColors
              position="top-right"
              theme="system"
              expand
            />
            <Modal />
          </LanguageProvider>
        </UserProvider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
