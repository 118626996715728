import { ChangeEvent, SyntheticEvent } from "react";

const Input = ({
  id,
  name,
  value,
  type = "text",
  placeholder,
  required,
  min,
  max,
  step,
  minLength,
  pattern,
  role,
  checked,
  disabled,
  onChange,
  onClick,
  children,
}: InputProps) => {
  if (~["text", "email", "password", "number"].indexOf(type)) {
    // required = required === undefined ? true : required;
    placeholder = placeholder === undefined ? " " : placeholder;
  }
  const invalid = required ? { "aria-invalid": value ? false : true } : {};
  return (
    <label htmlFor={name} onClick={onClick}>
      {type !== "checkbox" && children}
      <input
        id={id}
        name={name}
        value={formatValue(value, type)}
        type={type}
        placeholder={placeholder}
        min={min}
        max={max}
        step={step}
        minLength={minLength}
        pattern={pattern}
        role={role}
        checked={checked}
        required={required}
        disabled={disabled}
        onChange={onChange}
        {...invalid}
      />
      {type === "checkbox" && children}
    </label>
  );
};

type InputProps = {
  id?: string | undefined;
  name?: string | undefined;
  value?: Date | string | number | undefined;
  type?: InputType;
  placeholder?: string | undefined;
  min?: number | undefined;
  max?: string | undefined;
  step?: string | undefined;
  minLength?: number | undefined;
  pattern?: string | undefined;
  role?: string | undefined;
  checked?: boolean | undefined;
  required?: boolean | undefined;
  disabled?: boolean | undefined;
  onChange?: undefined | ((e: ChangeEvent<HTMLInputElement>) => void);
  onClick?: undefined | ((e: SyntheticEvent<HTMLElement>) => void);
  children?: string | JSX.Element | undefined;
};
type InputType =
  | "button"
  | "checkbox"
  | "color"
  | "date"
  | "datetime-local"
  | "email"
  | "file"
  | "hidden"
  | "image"
  | "month"
  | "number"
  | "password"
  | "radio"
  | "range"
  | "reset"
  | "search"
  | "submit"
  | "tel"
  | "text"
  | "time"
  | "url"
  | "week";
export default Input;
const formatValue = (
  value: Date | string | number | undefined,
  type: InputType
) => {
  if (value === undefined || value === null) return "";
  if (!value) return value;
  if (value instanceof Date || type === "date") {
    return (value instanceof Date ? value : new Date(value))
      .toISOString()
      .split("T")[0];
  }
  return value;
};
