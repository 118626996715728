import Fa from "./FontAwesome";

const Forbidden = () => {
  return (
    <>
      <p className="text-larger justify-content-start my-5">
        <Fa thin="ban fa-2xl text-danger me-2" /> Acces restrictionat
      </p>
      <blockquote>
        Pagina cerută nu este disponibilă. <br />
        Pentru a continua, te rugăm să te întorci la pagina anterioară. <br />
        Dacă ai un cont cu drepturi suplimentare, te rugăm să te autentifici cu contul respectiv.
        <footer>
          <cite>
            Poți de asemenea să ceri drepturi suplimentare de la un
            administrator al aplicației
          </cite>
        </footer>
      </blockquote>
      <div className="justify-content-between">
        <a href="/home" role="button" className="contrast mb-2">
          <Fa regular="home" /> Pagina de start
        </a>
        <a href="/login" role="button">
          <Fa regular="user-unlock" /> Conectare cu alt cont
        </a>
      </div>
    </>
  );
};

export default Forbidden;
