import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import sql from '../../common/sql';
import { QAEntity } from '../../../../backend/src/shared/entity';
import Lang from '../../components/Lang';
import PageTitle from '../../components/PageTitle';
import { useTranslate } from '../../common/lang';
import { useLang } from '../../hooks/useLang';
import Loading from '../../components/Loading/Loading';

// Să răspundă la întrebări și să actualizeze secțiunea de Întrebări și Răspunsuri
// Platforma va furniza o secțiune de întrebări și răspunsuri, prin intermediul căreia administratorii vor putea răspunde la întrebările apărute,
// respectiv actualiza secțiunea de întrebări și răspunsuri.
// Daca sunt admin sa pot adauga intrebari in Q&A folosind campurile de mai jos: (insert in tabela QA din DB)

const QAUserIndex = () => {
    const translate = useTranslate();
    const [expandedId, setExpandedId] = useState<number | null>(null);
    const pageTitle = translate('Intrebări');
    const { lang } = useLang();
    PageTitle(pageTitle);

    const [model, setModel] = useState([] as QAEntity[]);
    const { isLoading, data } = useQuery({
        queryKey: ["QAIndex"],
        queryFn: () => sql.QAListQuery() as Promise<QAEntity[]>,
    });



    if (!isLoading && data && data.length > 0 && !model.length) {
        setModel(data);
    }

    if (isLoading) {
        return <Loading />;
    }

    const toggleExpand = (id: number) => {
        setExpandedId(expandedId === id ? null : id);
    };

    return (
        <div>
            <h1>Q&A</h1>

            <table>
                <thead>
                    <tr>
                        <th><Lang label="Intrebări" /></th>
                    </tr>
                </thead>
                <tbody>
                    {model.map((item, index) => (
                        <React.Fragment key={index}>
                            <tr onClick={() => toggleExpand(item.id)}>
                                <td>{lang === 'ro' ? item.Intrebare : item.Intrebare_en}</td>
                            </tr>
                            {expandedId === item.id && (
                                <tr style={{ backgroundColor: '#f5f5f5', paddingLeft: '20px' }}>
                                    <td>
                                        <div style={{
                                            marginTop: '10px',
                                            borderLeft: '2px solid #007bff',
                                            paddingLeft: '10px',
                                            fontStyle: 'italic'
                                        }}>
                                            {lang === 'ro' ? item.Raspuns : item.Raspuns_en}
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default QAUserIndex;