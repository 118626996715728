import React from 'react'
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import Navbar from './Navbar'
import HomePage from "./../pages/HomePage";
import RegisterPage from "./../pages/user/RegisterPage";
import PasswordRecoverPage from "./../pages/user/PasswordRecoverPage";
import PasswordResetPage from "./../pages/user/PasswordResetPage";
import AccountIndex from "../pages/user/AccountIndex";
import ValidateEmailPage from "./../pages/user/ValidateEmailPage";
import ContactIndex from "./../pages/contact/ContactIndex";
import ContactDetail from "./../pages/contact/ContactDetail";
import RecenziiPage from "./../pages/RecenziiPage";
import NoutatiPage from "./../pages/Noutati/NoutatiIndex";
import QAIndex from "./../pages/QA/QAAdminIndex";
import QAUserIndex from "./../pages/QA/QAIndex";
import QADetail from "./../pages/QA/QAAdminDetail";
import NoutatiAdminIndex from "./../pages/Noutati/NoutatiAdminIndex";
import NoutatiDetail from "./../pages/Noutati/NoutatiDetail";
import NoutatiAdminDetails from "./../pages/Noutati/NoutatiAdminDetail";
import MapPage from "./../pages/MapPage";
import LoginPage from '../pages/user/LoginPage';
import { UserRole } from '../common/user';
import { useUser } from '../hooks/useUser';
import Forbidden from './Forbidden';
import ProfilOrganizatie from '../pages/user/ProfilOrganizatie';
import AdminEvaluareProfilSumar from '../pages/admin/AdminEvaluareProfilSumar';
import AdminIndexPage from '../pages/admin/AdminIndexPage';
import AdminEvaluareProfilOrganizatie from '../pages/admin/AdminEvaluareProfilOrganizatie';
import Footer from './Footer';
import ProfilOrganizatieVizualizare from '../pages/user/ProfilOrganizatieVizualizare';
import AdminEvaluareRecenzieSumar from '../pages/admin/AdminEvaluareRecenzieSumar';
import AdminEvaluareRecenzieOrganizatie from '../pages/admin/AdminEvaluareRecenzieOrganizatie';
import PartnerList from './Contact/PartnerList';
import FeedbackVizualizare from '../pages/Feedback/FeedbackVizualizare';
import FeedbackAdaugare from '../pages/Feedback/FeedbackAdaugare';
import CursuriIndex from '../pages/cursuri/CursuriIndex';
import NewsLetter from './NewsLetter';


function Auth(component: JSX.Element, ...roles: UserRole[]) {
    roles = roles.length ? roles : ["admin", "user"];
    const { user } = useUser();
    if (!user?.email) return <LoginPage />;
    if (!roles.includes(user.role)) return <Forbidden />;
    return component;
}

const Router = () => {
    return (
        <BrowserRouter>
            <Navbar />
            <main className='container pt-4 pb-5'>
                <Routes>
                    <Route path="/map" element={<MapPage />} />
                    <Route index element={<HomePage />} />
                    <Route path="/" element={<HomePage />} />
                    <Route path="/home" element={<HomePage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/register" element={<RegisterPage />} />
                    <Route
                        path="/password-recover"
                        element={<PasswordRecoverPage />}
                    />
                    <Route
                        path="/password-reset/:token"
                        element={<PasswordResetPage />}
                    />
                    <Route
                        path="/validate-email/:token"
                        element={<ValidateEmailPage />}
                    />
                    <Route path="/admin/evaluare-recenzie" element={<AdminEvaluareRecenzieSumar />} />
                    <Route path="/admin/evaluare-recenzie/:idRecenzie" element={<AdminEvaluareRecenzieOrganizatie />} />
                    <Route path="/admin/evaluare-profil" element={<AdminEvaluareProfilSumar />} />
                    <Route path="/admin/evaluare-profil/:idOrganizatie" element={<AdminEvaluareProfilOrganizatie />} />
                    <Route path="/admin/completare-profil/:idOrganizatie" element={<ProfilOrganizatie />} />
                    <Route path="/vizualizare-profil/:idOrganizatie" element={<ProfilOrganizatieVizualizare />} />
                    <Route path="user/config" element={<AccountIndex />} />
                    <Route path="/contact" element={<ContactIndex />} />
                    <Route path="/contact/:id" element={Auth(<ContactDetail />, "user", "admin")} />
                    <Route path="/recenzii" element={<RecenziiPage />} />
                    <Route path="/feedback" element={<FeedbackAdaugare />} />
                    <Route path="/admin/feedback" element={<FeedbackVizualizare />} />
                    <Route path="/noutati-search/:fullText" element={<NoutatiPage />} />
                    <Route path="/noutati" element={<NoutatiPage />} />
                    <Route path="/noutati/:id" element={<NoutatiDetail />} />
                    <Route path="/admin/noutati" element={Auth(<NoutatiAdminIndex />, "admin")} />
                    <Route path="/admin/noutati/:id" element={Auth(<NoutatiAdminDetails />, "admin")} />
                    <Route path="/admin/qa" element={Auth(<QAIndex />, "admin")} />
                    <Route path="/admin/qa/:id" element={Auth(<QADetail />, "admin")} />
                    <Route path="/qa" element={<QAUserIndex />} />
                    <Route path="/partners" element={<PartnerList />} />
                    <Route path="/cursuri" element={<CursuriIndex />} />
                    <Route path="/newsletter" element={<NewsLetter />} />
                    {/* ADMIN */}
                    <Route path="/admin" element={Auth(<AdminIndexPage />, "admin")} />
                    
                </Routes>
            </main>
            <Footer />
        </BrowserRouter>
    )
}

export default Router