import { Link, useLocation } from "react-router-dom";
import { useUser } from "../hooks/useUser";
import Fa from "./FontAwesome";
import ReactGA from "react-ga4";
import { useEffect, useState } from "react";
import { useLang } from "../hooks/useLang";
import Lang from "./Lang";
import { useTranslate } from "../common/lang";
import InProgressAlert from "./InProgressAlert";
import SearchContainer from "./SearchSite/SearchContainer";
import JourneyStory from "./JourneyStory";
import MapContainer from "./MapContainer";
import MapCircuit from "./MapCircuit";
import Disclaimer from "./Disclaimer";
import AutoPlayVideo from "./HomePageMovie";

ReactGA.initialize("G-JDEY5XDXCF");

const Navbar = () => {
  const { user } = useUser();
  const isAdmin = user?.role === "admin";
  const role = isAdmin ? "/admin" : "";
  const location = useLocation();
  const { lang, setLang } = useLang();
  const isMapPage = location.pathname === "/map";
  const isHomePage = ["/", "/home"].includes(location.pathname);
  const isSearchablePage = ["/noutati"].find((e) =>
    location.pathname.startsWith(e)
  );
  const isContactPage = ["/partners"].find((e) =>
    location.pathname.startsWith(e)
  );
  const backgroundImage = isHomePage
    ? "/images/backgroud-search.png"
    : "https://img.bunadimineata.ro/uploads/2015/07/toamna-in-maramures_61127305-1068x470.jpg";

  useEffect(() => {
    document.querySelector("#navbarToggler")?.classList.remove("show");
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
    // scroll to top on route change
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <nav className="navbar sticky-top navbar-expand-lg navbar-dark bg-success">
        <div className="container">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item text-bold">
              <Link to="/" className="p-0">
                <img
                  className="img-fluid img-logo"
                  src="/images/sustain-circuit-logo-04.png"
                  alt=""
                />
              </Link>
            </li>
          </ul>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarToggler"
            aria-controls="navbarToggler"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={(e) => [
              e.preventDefault(),
              document
                .querySelector("#navbarToggler")
                ?.classList.toggle("show"),
            ]}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse`} id="navbarToggler">
            <ul className="navbar-nav ms-auto">
              <li>
                <Link to="/">
                  <Fa light="home fa-lg" />
                  <Lang label="Acasă" />
                </Link>
              </li>
              <li>
                <Link to="/map">
                  <Fa light="location-dot fa-lg" />
                  <Lang label="Traseu" />
                </Link>
              </li>
              <li>
                <Link to={`/cursuri `}>
                  <Lang label="Cursuri" />
                </Link>
              </li>
              <li>
                <Link to={`${role}/noutati`}>
                  <Lang label="Noutăți" />
                </Link>
              </li>
              <li>
                <Link to={`${role}/qa`}>FAQ</Link>
              </li>
              <li>
                <Link to={`${role}/feedback`}>Feedback</Link>
              </li>

              {isAdmin && (
                <li>
                  <Link to="/admin">
                    <Fa solid="folder-gear fa-xl" />
                  </Link>
                </li>
              )}
              <li>
                <UserInfo />
              </li>
              <li>
                <Link to="/partners">Contact</Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/"
                  className="d-flex justify-content-center"
                  onClick={(e) => [
                    e.preventDefault(),
                    setLang(lang === "ro" ? "en" : "ro"),
                  ]}
                >
                  <span className={lang === "ro" ? "text-underline" : ""}>
                    RO
                  </span>
                  &nbsp;|&nbsp;
                  <span className={lang === "en" ? "text-underline" : ""}>
                    EN
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <InProgressAlert />
      {isMapPage && (
        <>
        <div style={{ textAlign: "center", margin: "20px 0" }}>
      <img
        src="images/Harta_Romaniei.png"
        alt="Harta Romaniei"
        style={{ width: "100%", maxWidth: "80%", height: "auto" }}
      />
    </div>
          
          <div
            style={{
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Lang label="Folosește harta de mai jos pentru a defini traseul pe care vrei să îl urmezi. Ulterior, vei putea descărca și consulta informațiile despre fiecare locație selectată și în sistem offline." />{" "}
            <br />
            <Lang label="Dacă dorești să îți creezi un circuit pe mai multe zile, poți utiliza această " />
            <Link
              target="_blank"
              to={`/Plan your sustainable journey-${lang}.pdf`}
            >
              {" "}
              <Lang label="resursă " />{" "}
            </Link>
            <Lang label="pentru a pune la punct toate detaliile unei experiențe sustenabile." />
            <Link
              target="_blank"
              to={`/Plan your sustainable journey-${lang}.pdf`}
            >
              {" "}
            </Link>
          </div>
          <MapContainer>
            <MapCircuit />
          </MapContainer>
          <Disclaimer />
          <JourneyStory />
        </>
      )}
      {isSearchablePage && (
        <SearchContainer backgroundImage={backgroundImage} />
      )}
      {isContactPage && <div className="bg-img-partners"></div>}

      {isHomePage && <AutoPlayVideo></AutoPlayVideo>}
    </div>
  );
};

export default Navbar;

const UserInfo = () => {
  const { user } = useUser();
  const translate = useTranslate();
  if (!user?.email)
    return <Link to="/login">{translate("Înregistrează-ți afacerea")}</Link>;
  return (
    <Link to="user/config">
      <Fa regular="user" />
      {user.name}
    </Link>
  );
};
