const LoginContainer = ({ children }: any) => {
  return (
    <div className="loading">
      <div className="card p-5">
        <div className="card-body">
        {children}
        </div>
      </div>
    </div>
  );
};

export default LoginContainer;
