import React from 'react'
import { useTranslate } from '../../common/lang'

const SearchBox = ({value="", placeholder="", onChange=(value:string)=>{}}) => {
  const translate = useTranslate();
  return (
    <div>
        <input type="text" className='w-100 rounded-5' role='search' 
         value={value} 
         placeholder={placeholder || translate('cauta "sustenabilitate"')} 
         onChange={e=>onChange(e.target.value)} />
    </div>
  )
}

export default SearchBox