import React from 'react'
import { useLang } from '../hooks/useLang'

const JourneyStory = () => {
    const { lang } = useLang();

    if (lang === 'ro') return (

        <div
            className="journey-story"
        >
            <iframe
                loading="lazy"
                src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAF_3nCYTJE&#x2F;OPE4I1_Xliru7AUwg3PO_w&#x2F;view?embed"
                allowFullScreen={true}
                allow="fullscreen"
            >
            </iframe>
        </div>
    );

    return (
        <div
            className="journey-story"
        >
            <iframe loading="lazy" 
                src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAF_3gZikaY&#x2F;dF0GmP-5WBVzEEY4ggOwKg&#x2F;view?embed" 
                allowFullScreen={true}
                allow="fullscreen">
            </iframe>
        </div>
    )
}

export default JourneyStory