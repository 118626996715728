import React from 'react'
import Fa from '../../components/FontAwesome'
import Lang from '../../components/Lang'
import { useQuery } from '@tanstack/react-query';
import sql from '../../common/sql';
import Loading from '../../components/Loading/Loading';
import { Link } from 'react-router-dom';
import { useTranslate } from '../../common/lang';
import PageTitle from '../../components/PageTitle';

const AdminIndexPage = () => {
    const translate = useTranslate();
    const pageTitle = translate("Sustain Circuit Admin");
    PageTitle(pageTitle);
    const { isLoading, data } = useQuery({
        queryKey: ["adminDashboardQuery"],
        queryFn: () => sql.adminDashboardQuery() as Promise<[any[], any[], any[], any[]]>,
        refetchOnWindowFocus: false,
    });
    if (isLoading || !data?.length) return <Loading />;
    const [[organizatie], [recenzie], [user], [feedback]] = data;
    return (
        <form>
            <legend><Fa light='folder-gear fa-xl' /> <Lang label="Panou de administrare" /></legend>
            <div className="row">
                <div className='col-md-6 mb-5'>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title"><Lang label='Organizații înrolate' /> ( {organizatie.total} )</h5>
                            <hr />
                            <p className="card-text">
                                <Fa solid='check-double fa-xl fa-fw text-success' /><Lang label='Acceptate' > ( {organizatie.acceptat} )</Lang>
                                <Fa light='hand fa-xl fa-fw text-danger ms-3' /><Lang label='Respinse'> ( {organizatie.respins} )</Lang>
                                <Fa solid='circle-question fa-xl fa-fw ms-3' /><Lang label='Neevaluate'> ( {organizatie.asteapta} )</Lang>
                            </p>
                            <Link to='/admin/evaluare-profil' className="card-link">
                                <Lang label='Aprobă / Respinge' />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 mb-5'>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title"><Lang label='Recenzii pentru locații' /> ( {recenzie.total} )</h5>
                            <hr />
                            <p className="card-text">
                                <Fa solid='check-double fa-xl fa-fw text-success' /><Lang label='Acceptate' > ( {recenzie.acceptat} )</Lang>
                                <Fa light='hand fa-xl fa-fw text-danger ms-3' /><Lang label='Respinse'> ( {recenzie.respins} )</Lang>
                                <Fa solid='circle-question fa-xl fa-fw ms-3' /><Lang label='Neevaluate'> ( {recenzie.asteapta} )</Lang>
                            </p>
                            <Link to='/admin/evaluare-recenzie' className="card-link">
                                <Lang label='Aprobă / Respinge' />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 mb-5'>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title"><Lang label='Conturi de utilizator' /> ( {user.total} )</h5>
                            <hr />
                            <p className="card-text">
                                <Fa solid='check-double fa-xl fa-fw text-success' /><Lang label='Activi'> ( {user.activat} )</Lang>
                                <Fa light='hand fa-xl fa-fw text-danger ms-3' /><Lang label='Suspendați'> ( {user.suspendat} )</Lang>
                                <Fa light='circle-envelope text-warning fa-xl fa-fw ms-3' /><Lang label='Email neconfirmat'> ( {user.neactivat} )</Lang>
                            </p>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 mb-5'>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title"><Lang label='Feedback pentru platforma Sustain Circuit' /> ( {feedback.total} )</h5>
                            <hr />
                            <p className="card-text">
                                <Link to='/admin/feedback' className="card-link">
                                    <Lang label='Vezi lista completă' />
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default AdminIndexPage