import { useQuery } from '@tanstack/react-query';
import React from 'react'
import sql from '../../common/sql';
import { NoutatiEntity } from '../../../../backend/src/shared/entity';
import { Link } from 'react-router-dom';
import Fa from '../../components/FontAwesome';
import PageTitle from '../../components/PageTitle';
import Lang from '../../components/Lang';
import { useTranslate } from '../../common/lang';
import Loading from '../../components/Loading/Loading';
import ImageViewer from '../../components/ImageViewer/ImageViewer';

// Să încarce informații actualizate pe platformă (știri, articole)
// Platforma va permite încărcarea de conținut, precum știri, articole, informații pentru public.

const NoutatiAdminIndex = () => {
  const translate = useTranslate();
    const pageTitle = translate('Noutăți');
    PageTitle(pageTitle);
  const [model, setModel] = React.useState([] as NoutatiEntity[]);
  const { isLoading, data } = useQuery({
    queryKey: ["NoutatiIndex"],
    queryFn: () => sql.NoutatiListQuery() as Promise<NoutatiEntity[]>,
  });

  const handleDelete = async (id: number) => {

    await sql.NoutatiUpdateQuery({ idNoutati: id });
    window.location.href = "/admin/noutati";
  };

  if (!isLoading && data && data.length > 0 && !model.length) {
    setModel(data);
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
     <h1 className="text-center"><Lang label="Noutăți Admin" /></h1>
      <table>
        <thead>
          <tr>
            <td colSpan={3}>
              <Link to="/admin/noutati/0" role='button'><Fa light='user-plus' />Adaugă stire</Link>
            </td>
          </tr>
          <tr>
            <th>Id</th>
            <th>Poza</th>
            <th>Titlu</th>
            <th>Titlu EN</th>
            <th>Descriere Scurta RO</th>
            <th>Descriere Scurta EN</th>
            <th>Descriere Lunga RO</th>
            <th>Descriere Lunga EN</th>
            <th>Data adaugarii</th>
            <th>Publicat</th>
          </tr>
        </thead>
        <tbody>
          {model.map((item, index) => (
            <tr key={index}>
              <td>
                <Link to={`/admin/noutati/${item.id}`}>{item.id}</Link>
              </td>
              <td><ImageViewer images={JSON.parse(item.poza || "[]")} /></td>
              <td>{item.titlu && item.titlu.length > 50 ? item.titlu.substring(0, 50) + '...' : item.titlu}</td>
              <td>{item.titlu_en && item.titlu_en.length > 50 ? item.titlu_en.substring(0, 50) + '...' : item.titlu_en}</td>
              <td>{item.descriere_scurta && item.descriere_scurta.length > 50 ? item.descriere_scurta.substring(0, 50) + '...' : item.descriere_scurta}</td>
              <td>{item.descriere_scurta_en && item.descriere_scurta_en.length > 50 ? item.descriere_scurta_en.substring(0, 50) + '...' : item.descriere_scurta_en}</td>
              <td  dangerouslySetInnerHTML={{__html:item.descriere_lunga||''}}></td>
              <td  dangerouslySetInnerHTML={{__html:item.descriere_lunga_en||''}}></td>
              <td>
                {item.data_adaugarii && typeof item.data_adaugarii === 'string'
                  ? item.data_adaugarii
                  : item.data_adaugarii && new Date(item.data_adaugarii).toLocaleDateString(navigator.language, { day: '2-digit', month: 'short' })}
              </td>
              <td>{item.vizibil}</td>
              <td onClick={() => handleDelete(item.id)} style={{ cursor: 'pointer', color: 'red' }}>
                <Fa solid="times" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default NoutatiAdminIndex
