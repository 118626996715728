import React from 'react'
import { Link } from 'react-router-dom';
import { useData } from '../../hooks/useData';
import { useTranslate } from '../../common/lang';

const ObjectiveSelector = ({ readonly = false as false | true | 'icons', value = "", onChange = (values: string) => { } }) => {
    const values = (value || "").split(',').filter(x => x !== "").map(x => parseInt(x));
    const { goals } = useData();
    const translate = useTranslate();

    if ((typeof readonly === 'string') && readonly === 'icons') return (
        <ul className='nav flex-row img-logo'>
            {
                goals.filter((_, id) => values.includes(id)).map((item, id) =>
                    <li className='nav-item m-2' key={id}>
                        <img src={item.logo} alt="" className='img-thumbnail' />
                    </li>)
            }
        </ul>
    )

    if (readonly) return <ul className='nav flex-column img-logo'>
        {goals.filter((_, id) => values.includes(id)).map((item, id) =>
            <li className='nav-item mt-3' key={id}>
                <div className="d-flex flex-row">
                    <div className=''>
                        <img src={item.logo} alt="" className='img-thumbnail me-3' />
                    </div>
                    <div className='w-90'>
                        <Link className='nav-link p-0 disabled' to="">{item.title}</Link>
                        <small className='text-muted'>{translate(item.sample)}</small>
                    </div>
                </div>
            </li>)}
    </ul>
    const handleChange = (id: number) => {
        console.log({ id });
        if (values.includes(id)) {
            values.splice(values.indexOf(id), 1);
        } else {
            values.push(id);
        }
        onChange(values.join(','));
    }

    return (
        <ul className='nav d-flex flex-column img-logo'>
            {
                goals.map((item, id) =>
                    <li className='nav-item mt-3' key={id}>
                        <div className="d-flex flex-row">
                            <div className=''>
                                <input type="checkbox" checked={values.includes(id)} onChange={e => [handleChange(id)]} />
                                <img src={item.logo} alt="" className='img-thumbnail mx-3' onClick={e => [e.preventDefault(), handleChange(id)]} />
                            </div>
                            <div className='w-90'>
                                <Link className='nav-link p-0' to="/" onClick={e => [e.preventDefault(), handleChange(id)]}>{translate(item.title)}</Link>
                                <small className='text-muted' onClick={e => [e.preventDefault(), handleChange(id)]}>{translate(item.sample)}</small>
                            </div>
                        </div>
                    </li>)
            }
        </ul>
    )
}

export default ObjectiveSelector