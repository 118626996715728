import React from "react";
import { useTranslate } from "../common/lang";
import PageTitle from "../components/PageTitle";
import { useQuery } from "@tanstack/react-query";
import sql from "../common/sql";
import { NoutatiEntity } from "../../../backend/src/shared/entity";
import ImageViewer from "../components/ImageViewer/ImageViewer";
import { FileAttach } from "../common/types";
import Lang from "../components/Lang";
import { Link } from "react-router-dom";
import { useLang } from "../hooks/useLang";
import AboutSustainCircuit from "../components/AboutSustainCircuit";
import AboutSustainCircuit2 from "../components/AboutSustainCircuit2";
import WordSlider from "../components/WordSlider";

const HomePage = () => {
  const translate = useTranslate();
  const pageTitle = translate("Home");
  PageTitle(pageTitle);
  const { lang } = useLang();
  const { data: images } = useQuery({
    queryKey: ["HomePageImagesQuery"],
    queryFn: () => sql.HomePageImagesQuery() as Promise<NoutatiEntity[]>,
  });

  const { data: model } = useQuery({
    queryKey: ["HomePageNoutatiQuery"],
    queryFn: () => sql.HomePageNoutatiQuery() as Promise<NoutatiEntity[]>,
  });
  const words = ["Susține", "Dezvoltă", "Contribuie la", "Susține"];
  return (
    <div>
      <AboutSustainCircuit />
      <br></br>

      <h3>
        <Lang label="Știri și noutăți" />
      </h3>

      <br></br>

      {/* {images && <ImageCarusel images={JSON.parse(images[0].poza || "[]")} />} */}

      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
        {model && model.length ? (
          model.map((item, index) => (
            <div className="col" key={index}>
              <div className="card h-100">
                <ImageViewer
                  images={(
                    JSON.parse(item.poza || "[]") as FileAttach[]
                  ).filter((e, i) => i == 0)}
                />
                <div className="card-body">
                  <h5 className="card-title fw-bold font-arial">
                    <Link
                      to={`/noutati/${item.id}`}
                      className="fw-bold text-primary title-font font-arial"
                      dangerouslySetInnerHTML={{
                        __html:
                          lang === "ro"
                            ? item.titlu ?? ""
                            : item.titlu_en ?? "",
                      }}
                    ></Link>
                  </h5>
                  <p className="card-text">
                    {new Date(item.data_adaugarii).toLocaleDateString(
                      navigator.language,
                      { day: "2-digit", month: "long" }
                    )}
                  </p>
                  <p className="my-custom-text-style body-font font-arial text-medium">
                    {lang === "ro"
                      ? item.descriere_scurta ?? ""
                      : item.descriere_scurta_en ?? ""}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <h5>
            <Lang label="😔 Nu am găsit nici un articol cu acest subiect sau conținut!" />
          </h5>
        )}
      </div>
      <br></br>
      {/* Link to the Newsletter */}
      

      {/* <NewsLetter /> */}
    </div>
  );
};

export default HomePage;
