import React from 'react'
import './FormComposer.scss'
import FormElement from './FormElement'
import { FormDataItem, Options } from '../../common/types'
const FormComposer = ({ readonly = false, items = [] as FormDataItem[] | string, onChange = (items: Array<FormDataItem>) => { }, hideWhen = false }) => {
    if (typeof items === 'string') items = JSON.parse(items || '[]');
    if (hideWhen || !Array.isArray(items)) return null;
    return <>
        {items.map((item, index) => <FormElement key={index}
            label={item.label as any} description={item.description} value={item.value} options={item.options || []} formControl={readonly ? 'readonly' : item.control}
            onChange={value => onChange((items as FormDataItem[]).map((i, iIndex) => iIndex === index ? { ...item, value } : i))} />)}
    </>

}

export default FormComposer

