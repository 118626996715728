import React from "react";
import { useLang } from "../hooks/useLang";

const Cursuri = () => {
  const { lang } = useLang();

  if (lang === "ro")
    return (
      <div className="journey-story">
        <iframe
          loading="lazy"
          src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAF6OQdJF9s&#x2F;aVM3s0oG3eE0nPWgbzFU3Q&#x2F;view?embed"
          allowFullScreen={true}
          allow="fullscreen"
          title="Cursuri"
        ></iframe>
      </div>
    );

  return (
    <div className="journey-story">
      <iframe
        loading="lazy"
        src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAF-55BuvyI&#x2F;ZtUs7uBBrFLXdCzktPGidg&#x2F;view?embed"
        allowFullScreen={true}
        allow="fullscreen"
        title="Cursuri"
      ></iframe>
    </div>
  );
};

export default Cursuri;
