import React from 'react'
import FormComposer from '../../components/FormComposer/FormComposer'
import { useQuery } from '@tanstack/react-query';
import sql from '../../common/sql';
import { ActivitateEntity, DomeniuEntity, OrganizatieEntity } from '../../../../backend/src/shared/entity';
import { useUser } from '../../hooks/useUser';
import Loading from '../../components/Loading/Loading';
import Lang from '../../components/Lang';
import Fa from '../../components/FontAwesome';
import FormElement from '../../components/FormComposer/FormElement';
import FileUpload from '../../components/FileUpload/FileUpload';
import LocationPicker from '../../components/LocationPicker/LocationPicker';
import ForeignKeySelector from '../../components/Selectors/ForeignKeySelector';
import ObjectiveSelector from '../../components/Selectors/ObjectiveSelector';
import { FormDataItem } from '../../common/types';
import Form from '../../components/Form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from "sonner";
import { useTranslate } from '../../common/lang';
import { useData } from '../../hooks/useData';
import DisclaimerProfile from '../../components/DisclaimerProfile';

const ProfilOrganizatie = () => {
  const { idOrganizatie = null } = useParams<{ idOrganizatie: string }>();
  const { profile } = useData();
  const [profil, setProfil] = React.useState<Array<FormDataItem>>(profile)
  const navigate = useNavigate();
  const { user } = useUser();
  const translate = useTranslate();
  const [model, setModel] = React.useState<OrganizatieEntity>({} as OrganizatieEntity);
  const [saving, setSaving] = React.useState(false);
  const { isLoading, data } = useQuery({
    queryKey: ["profilOrganizatieQuery", idOrganizatie],
    queryFn: () => sql.completareProfilOrganizatieQuery({ idOrganizatie }) as Promise<[OrganizatieEntity[], ActivitateEntity[], DomeniuEntity[]]>,
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });
  const domenii = useQuery({
    queryKey: ['domeniiQuery'],
    queryFn: () => (sql.getEntity("dbo.Domeniu", -1) as any) as Promise<DomeniuEntity[]>,
    refetchOnWindowFocus: false,
  });
  const handleSave = () => {
    setSaving(true);
    if (!(model.Inrolat || idOrganizatie)) model.Inrolat = null;
    model.Profil = JSON.stringify(profil);
    const domeniuPrincipal = domenii.data?.find(({ Marker }) => Marker === model.Marker);
    model.Domeniu = model.Domeniu || domeniuPrincipal?.Id.toString() || "";
    sql.saveEntity("dbo.Organizatie", model)
      .then(model => [idOrganizatie ? navigate('/admin/evaluare-profil') : setModel({ ...model, Profil: JSON.parse(model.Profil) }), toast.success(translate("Salvare reușită!"))])
      .catch(e => [console.error(e), toast.error(translate("Eroare la salvarea formularului!"))])
      .finally(() => setSaving(false));
  }

  if (!isLoading && data && !model.User) {
    const model = data[0][0] || {} as OrganizatieEntity;
    setProfil(prev => (model.Profil ? JSON.parse(model.Profil) : prev as Array<FormDataItem>));
    setModel({ ...model, User: model.User || user.email || model.Email });
  }
  if (isLoading) return <Loading />;

  const profilAprobat = !!model.Inrolat;
  const readOnly = (!idOrganizatie && model.Inrolat === null) ? 'readonly' : undefined;

  return (
    <Form onSubmit={handleSave}>
      <legend><Fa regular='address-card fa-fw' /> <Lang label="Profil Organizație" /></legend>
      <ProfileState {...model} />
      <FormElement label="Denumire organizație" value={model.Nume}
        formControl={readOnly || "input"}
        onChange={e => setModel({ ...model, Nume: e })} />
      <FormElement label="Adrese de email (separate prin virgulă)"
        value={model.Email}
        formControl={readOnly || "input"}
        onChange={e => setModel({ ...model, Email: e })}
      />
      <FormElement label="Numere de telefon (separate prin virgulă)" value={model.Telefon}
        formControl={readOnly || "input"}
        onChange={e => setModel({ ...model, Telefon: e })}
      />
      <FormElement label="Adresa" value={model.Adresa}
        formControl={readOnly || "input"}
        onChange={e => setModel({ ...model, Adresa: e })}
      />
      <Lang label='Locația pe hartă'>
        <LocationPicker location={JSON.parse(model.Locatie || '{}')} readonly={!!readOnly} onChange={e => setModel({ ...model, Locatie: JSON.stringify(e) })} />
      </Lang>
      <Lang label='Siglă organizație'>
        <FileUpload files={JSON.parse(model.Sigla || '[]')} multiple={false} onChange={files => setModel({ ...model, Sigla: JSON.stringify(files) })} />
      </Lang>
      <Lang label='Domenii de activitate' hideWhen={profilAprobat && !idOrganizatie}>
        <ForeignKeySelector tableSource='dbo.Domeniu' readonly={!!readOnly} value={model.Domeniu} onChange={e => setModel({ ...model, Domeniu: e })} />
      </Lang>
      <Lang label='Domeniu de activitate principal' hideWhen={profilAprobat && !idOrganizatie}>
        <select className='form-select' value={model.Marker} onChange={e => setModel({ ...model, Marker: e.target.value })} required>
          <option value=''><Lang label='— Selectează —' /></option>
          {
            domenii.data?.map(({ Id, Marker, Nume }) => <option key={Id} value={Marker}>{Nume}</option>)
          }
        </select>
      </Lang>

      <FormElement label={"Scurtă descriere a afacerii (150 cuvinte)"}
        value={model.Promovare}
        formControl={readOnly || "tinymce"}
        onChange={e => setModel({ ...model, Promovare: e })} />
      <FormElement label={"Descriere detaliată/completă a afacerii și serviciilor oferite"}
        value={model.Descriere}
        formControl={readOnly || "tinymce"}
        onChange={e => setModel({ ...model, Descriere: e })} />
      <FormElement label="Adresă website"
        value={model.Website}
        formControl={readOnly || "input"}
        required={false}
        onChange={e => setModel({ ...model, Website: e })}
      />
      <FormElement label="Adresă website rezervări(opțional)"
        value={model.Rezervare}
        formControl={readOnly || "input"}
        required={false}
        onChange={e => setModel({ ...model, Rezervare: e })}
      />
      <FormElement label="Persoana de contact"
        value={model.ContactPersoana}
        formControl={readOnly || "input"}
        onChange={e => setModel({ ...model, ContactPersoana: e })}
      />
      <FormElement label="Persoana de contact - email"
        value={model.ContactEmail}
        formControl={readOnly || "input"}
        inputType="email"
        onChange={e => setModel({ ...model, ContactEmail: e })}
      />
      <FormElement label="Vreau ca datele de contact să fie vizibile și pentru ceilalți antreprenori înregistrați în platformă ?"
        value={model.ContactPublic ? '1' : '-1'}
        formControl={readOnly || "yesno"}
        onChange={e => setModel({ ...model, ContactPublic: e === '1' })}
      />
      <DisclaimerProfile />
      {/* Date care se vor completa doar daca este inrolat */}
      <Lang label='Galerie foto' hideWhen={!profilAprobat && !idOrganizatie}>
        <FileUpload accept='' className='w-100' files={JSON.parse(model.Galerie || '[]')} onChange={files => setModel({ ...model, Galerie: JSON.stringify(files) })} />
      </Lang>
      <Lang label='Vă rugăm să analizați obiectivele dezvoltării durabile descrise mai jos și să bifați acele obiective la care afacerea dumneavoastră contribuie în mod activ' hideWhen={profilAprobat && !idOrganizatie}>
        <ObjectiveSelector value={model.Obiectiv} readonly={!!readOnly} onChange={e => setModel({ ...model, Obiectiv: e })} />
      </Lang>
      <FormComposer readonly={!!readOnly} items={profil} onChange={e => setProfil(e)} hideWhen={profilAprobat && !idOrganizatie} />

      {
        idOrganizatie ?
          <>
            <button type='submit' className='btn btn-success my-4' onClick={() => model.Inrolat = true}><Fa light='check-double fa-xl' /> <Lang label='Aprobă cererea de înrolare' /></button>
            <button type='submit' className='btn btn-danger' onClick={() => model.Inrolat = false}><Fa light='ban fa-xl' /> <Lang label='Respinge cererea de înrolare' /></button>
          </> : !readOnly &&
          <button type='submit'><Fa light='save fa-xl' /><Lang label='Salvează' /></button>
      }


      {saving && <Loading />}
    </Form>
  )
}

export default ProfilOrganizatie

const ProfileState = ({ Id, Inrolat }: OrganizatieEntity) => {
  if (!Id) return <Lang label='Te-ai înregistrat cu succes! Pentru validarea contului, te rugăm să completezi următorul chestionar' />
  if (Inrolat) return <i className='text-success'><Lang label='Cererea dumneavoastră de înrolare a fost aprobată.' /></i>
  if (Inrolat === null) return <i className='text-warning'><Lang label='Cererea dumneavoastră de înrolare a fost trimisă, veți fi notificat când va fi aprobată/respinsă.' /></i>
  return <i className='text-danger'><Lang label='Cererea dumneavoastră de înrolare a fost respinsă, puteți să o modificați și să o trimiteți din nou.' /></i>
}

