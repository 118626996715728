import React from 'react'
import PartnerCard from './PartnerCard'
import PartnerLink from './PartnerLink'

const PartnerList = () => {
    return (

        

        <div className='row me-3'>
            
            <div className="col">
                <PartnerCard img="/images/partners/easi.png" title="Asociația Europeană Association for Social Innovation (coordonator de proiect), România" details="EaSI este o rețea europeană motivată să promoveze inovarea socială în rândul membrilor săi și al altor părți interesate în scopul găsirii de noi soluții pentru problemele societății. În prezent, EaSI are peste 30 de membri din 15 țări europene care au ca obiectiv promovarea inovării sociale în diferite domenii (social, educație, justiție, dezvoltare rurală, antreprenoriat și altele)." >
                    <div className='row'>
                        <p>
                            <PartnerLink icon='envelope' link='easi@easi-socialinnovation.org' href="mailto:easi@easi-socialinnovation.org" />
                            <PartnerLink icon='phone-office' link='+4 0790 048 681' href="tel:+40790048681" />
                        </p>
                        <p className='d-flex'>
                            <PartnerLink icon='earth-europe' link='Website' href="https://easi-socialinnovation.org/" />
                            <PartnerLink icon='square-facebook fa-brands' link='Facebook' href="https://www.facebook.com/easiromania" />
                            <PartnerLink icon='linkedin fa-brands' link='LinkedIn' href="https://www.linkedin.com/company/easi-european-association-for-social-innovation" />
                            <PartnerLink icon='instagram fa-brands' link='Instagram' href="https://www.instagram.com/easiassociation/" />
                        </p>
                    </div>
                </PartnerCard>
            </div>
            <div className="col">
                <PartnerCard img="/images/partners/csr.png" title="CSR Innovative Solutions, Norvegia" details="La CSR Innovative Solutions, obiectivul nostru este de a determina schimbări durabile în întreprinderi prin intermediul planificării și raportării ESG și, mai ales, prin metode inovatoare de colectare a datelor și tehnologii moderne. Ne străduim să atingem excelența, inovarea și implementarea sustenabilității în tot ceea ce facem. Misiunea noastră este de a evidenția impactul pozitiv al companiilor asupra mediului și de a promova transparența în industrie. Punem preț pe incluziunea socială și ne sprijinim partenerii cu strategii și consultanță pentru a le responsabiliza personalul și clienții țintă." >
                    <div className='row'>
                        <p>
                            <PartnerLink icon='envelope' link='martin@csr-innosolutions.com' href="mailto:martin@csr-innosolutions.com" />
                            <PartnerLink icon='phone-office' link='+4791923656' href="tel:+4791923656" />
                        </p>
                        <p className='d-flex'>
                            <PartnerLink icon='earth-europe' link='Website' href="https://csr-innosolutions.com/" />
                            <PartnerLink icon='square-facebook fa-brands' link='Facebook' href="https://www.facebook.com/CSR.Innovative.Solutions/" />
                            <PartnerLink icon='linkedin fa-brands' link='LinkedIn' href="https://www.linkedin.com/company/csr-innovative-solutions/" />
                        </p>
                    </div>
                </PartnerCard>
            </div>
            <div className="col">
                <PartnerCard img="/images/partners/ccf.png" title="CCF - Centrul de Cercetare si Formare a Universitatii de Nord Baia Mare, Romania" details="Asociația Centrul de Cercetare și Formare a Universității de Nord Baia Mare are în statutul ei obiective de cercetare socială și s-a implicat în elaborarea și derularea de proiecte în domenii diverse ale realității sociale." >
                    <div className='row'>
                        <p>
                            <PartnerLink icon='envelope' link='accfunbm@yahoo.com' href="mailto:accfunbm@yahoo.com" />
                            <PartnerLink icon='phone-office' link='+40 362 412 929' href="tel:+40362412929" />
                        </p>
                        <p className='d-flex'>
                            <PartnerLink icon='earth-europe' link='Website' href="https://www.accf.ro/" />
                            <PartnerLink icon='square-facebook fa-brands' link='Facebook' href="https://www.facebook.com/ccfasociatia" />
                        </p>
                    </div>
                </PartnerCard>
            </div>
        </div>
    )
}

export default PartnerList

