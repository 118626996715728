import { useQuery } from '@tanstack/react-query';
import React from 'react'
import sql from '../common/sql';

// Secțiune de recenzii pentru utilizatori (clienți) disponibilă pe pagina de profil a  întreprinderilor
// Aplicația va furniza o secțiune de recenzii pentru utilizatori, pe pagina de profil a întreprinderilor.
// Această pagină va permite acordarea unui scor și completarea unor comentarii, opinii, păreri.

const RecenziiPage = () => {

    // useQuery({
    //     queryKey: ["DosarDetails", idDosar],
    //     queryFn: () => sql.dosarDetailsQuery({ idDosar }),
    //   });
    
  return (
    <form>
   Secțiune de recenzii pentru utilizatori (clienți) disponibilă pe pagina de profil a  întreprinderilor. <br />
   Aplicația va furniza o secțiune de recenzii pentru utilizatori, pe pagina de profil a întreprinderilor. <br />
   Această pagină va permite acordarea unui scor și completarea unor comentarii, opinii, păreri. <br />
   
  </form>
  )
}

export default RecenziiPage