import React from 'react'
import ImageGallery from "react-image-gallery";
import { FileAttach } from '../../common/types';

const ImageCarusel = ({ images = [] as FileAttach[] | string, className = "img-thumbnail" }) => {
  if (typeof images === 'string') images = JSON.parse(images || '[]') as FileAttach[];
  const items = images.filter((f: FileAttach) => f.filepath.match(/\.(jpg|jpeg|png|gif)$/i)).map(f => ({ original: '/' + f.filepath, thumbnail: '/' + f.filepath }));
  return items.length ? <ImageGallery autoPlay showPlayButton={false} showFullscreenButton={false} items={items} /> : null;
}

export default ImageCarusel