import { useQuery } from '@tanstack/react-query';
import React from 'react'
import sql from '../../common/sql';
import { QAEntity } from '../../../../backend/src/shared/entity';
import { Link } from 'react-router-dom';
import Fa from '../../components/FontAwesome';
import PageTitle from '../../components/PageTitle';
import { useTranslate } from '../../common/lang';
import Loading from '../../components/Loading/Loading';
// Să răspundă la întrebări și să actualizeze secțiunea de Întrebări și Răspunsuri
// Platforma va furniza o secțiune de întrebări și răspunsuri, prin intermediul căreia administratorii vor putea răspunde la întrebările apărute,
// respectiv actualiza secțiunea de întrebări și răspunsuri.
// Daca sunt admin sa pot adauga intrebari in Q&A folosind campurile de mai jos: (insert in tabela QA din DB)
const QAAdminIndex = () => {
 const translate = useTranslate();
    const pageTitle = translate('Intrebări Admin');
    PageTitle(pageTitle);
    const [model, setModel] = React.useState([] as QAEntity[]);
    const { isLoading, data } = useQuery({
      queryKey: ["QAIndex"],
      queryFn: () => sql.QAListQuery() as Promise<QAEntity[]>,
    });

   

    if (!isLoading && data && data.length > 0 && !model.length) {
      setModel(data);
    }
  
    if (isLoading) {
      return <Loading />;
    }
  


    return (
      <div>
        <h1>Q&A</h1>
        <table>
          <thead>
          <tr>
              <td colSpan={3}>
                <Link to="/admin/qa/0" role='button'><Fa light='user-plus'/>Adaugă intrebare noua</Link>
              </td>
            </tr>
            <tr>
              <th>Id</th>
              <th>Intrebare RO</th>
              <th>Raspuns RO</th>
              <th>Intrebare EN</th>
              <th>Raspuns EN</th>
            </tr>
          </thead>
          <tbody>
            {model.map((item, index) => (
              <tr key={index}>
                <td>
                  <Link to={`/admin/qa/${item.id}`}>{item.id}</Link>
                </td>
                <td>{item.Intrebare && item.Intrebare.length > 50 ? item.Intrebare.substring(0, 50) + '...' : item.Intrebare}</td>
                <td>{item.Raspuns && item.Raspuns.length > 50 ? item.Raspuns.substring(0, 50) + '...' : item.Raspuns}</td>
                <td>{item.Intrebare_en && item.Intrebare_en.length > 50 ? item.Intrebare_en.substring(0, 50) + '...' : item.Intrebare_en}</td>
                <td>{item.Raspuns_en && item.Raspuns_en.length > 50 ? item.Raspuns_en.substring(0, 50) + '...' : item.Raspuns_en}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
  
  export default QAAdminIndex

