import React, { useState } from 'react';
import sql from '../../common/sql';
import { FeedbackEntity } from '../../../../backend/src/shared/entity';
import Lang from '../../components/Lang';
import PageTitle from '../../components/PageTitle';
import { useTranslate } from '../../common/lang';
import Loading from '../../components/Loading/Loading';
import FormComposer from '../../components/FormComposer/FormComposer';
import { useData } from '../../hooks/useData';
import { FormDataItem } from '../../common/types';
import Form from '../../components/Form';
import { useModal } from '../../hooks/useModal';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';

const FeedbackAdaugare = () => {
  const translate = useTranslate();
  const { review } = useData();
  const pageTitle = translate('Formular feedback');
  const [model, setModel] = useState({ Raspuns: JSON.stringify(review), Valoare: "" } as FeedbackEntity);
  const { setModal } = useModal();
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();

  PageTitle(pageTitle);

  if (saving) {
    return <Loading />;
  }

  const handleChange = (items: FormDataItem[]) => {
    setModel({ ...model, Raspuns: JSON.stringify(items), Valoare: items.map(e => e.value).join(',') });
  }
  const handleSubmit = () => {
    setSaving(true);
    sql.saveEntity("dbo.Feedback", model)
      .then(e => [navigate('/'), setModal('Mulțumim pentru feedback! Sugestiile primite ne ajută să aducem îmbunătățiri platformei "Sustain Circiut".')])
      .catch(e => [console.error(e), toast.error(translate("Eroare la salvarea formularului!"))])
      .finally(() => setSaving(false));
  }

  return (
    <div>
      <h1><Lang label="Formular feedback" /></h1>
      <Form onSubmit={handleSubmit}>
        <FormComposer items={JSON.parse(model.Raspuns)} onChange={handleChange} />
        <button type="submit" className="btn btn-primary">{translate('Trimite formularul')}</button>
      </Form>
    </div>
  );
};

export default FeedbackAdaugare;