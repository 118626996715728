import React from "react";
import { useTranslate } from "../common/lang";
import PageTitle from "../components/PageTitle";
import { useLang } from "../hooks/useLang";
import Lang from "../components/Lang";
import { Link } from "react-router-dom";
import HoverBoxSection from "../components/HoverBoxSection";

const MapPage = () => {
  const translate = useTranslate();
  const pageTitle = translate("Harta");
  const { lang } = useLang();

  const sectionsData = [
    {
      id: 1,
      title: 'Foamete "Zero"',
      description: 'Asigurarea securitatii alimentare si a unei nutritii imbunatatite si promovarea agriculturii durabile.',
      backgroundImage: `/images/sdg-${lang}-2.png`,
    },
    {
      id: 2,
      title: 'Egalitate de Gen',
      description: 'Promovarea egalitatii de gen si autonomizarea tuturor femeilor si fetelor prin eliminarea discriminarii si asigurarea accesului egal la educatie si oportunitati economice.',
      backgroundImage: `/images/sdg-${lang}-5.png`,
    },
    {
      id: 3,
      title: 'Apa Curata si Sanitatie',
      description: 'Imbunatatirea calitatii apei prin reducerea poluarii, eliminarea deversarii si minimizarea eliberarii de produse chimice si materiale periculoase, reducand la jumatate proportia de ape uzate netratate.',
      backgroundImage: `/images/sdg-${lang}-6.png`,
    },
    {
      id: 4,
      title: 'Energie Curata si la Preturi Accesibile',
      description: 'Asigurarea accesului la surse de energie fiabile, sustenabile si la preturi accesibile pentru toti, sporind cota de energie regenerabila in mixul energetic global si imbunatatind eficienta energetica.',
      backgroundImage: `/images/sdg-${lang}-7.png`,
    },
    {
      id: 5,
      title: 'Munca Decenta si Crestere Economica',
      description: 'Sustinerea cresterii economice durabile prin angajarea deplina si productiva si asigurarea muncii decente pentru toti, inclusiv pentru tineri si persoanele cu dizabilitati.',
      backgroundImage: `/images/sdg-${lang}-8.png`,
    },
    {
      id: 6,
      title: 'Industrie, Inovatia si Infrastructura',
      description: 'Dezvoltarea unei infrastructuri de calitate, fiabile, sustenabile si reziliente, inclusiv infrastructura regionala si transfrontaliera, pentru a sprijini dezvoltarea economica si bunastarea umana.',
      backgroundImage: `/images/sdg-${lang}-9.png`,
    },
    {
      id: 7,
      title: 'Orase si Comunitati Durabile',
      description: 'Facerea oraselor si asezarilor umane incluzive, sigure, reziliente si durabile prin asigurarea accesului tuturor la locuinte adecvate, sigure si la preturi accesibile si imbunatatirea asezarilor de tip slum.',
      backgroundImage: `/images/sdg-${lang}-11.png`,
    },
    {
      id: 8,
      title: 'Consum si Productie Responsabile',
      description: 'Asigurarea unor modele de consum si productie durabile, implementand un cadru de politici pentru dezvoltarea durabila, care promoveaza resursele si gestionarea eficienta a deseurilor.',
      backgroundImage: `/images/sdg-${lang}-12.png`,
    },
  ];
  
  

  PageTitle(pageTitle);
  return (
    <>
      <br />
      <div className="w-btn-wrapper align_none text-center ">
        <Link
          target="_blank"
          className="w-btn us-btn-style_2"
          to={`/Plan your sustainable journey-${lang}.pdf`}
        >
          {" "}
          <Lang label="Planifică-ți propria călătorie în mod sustenabil!"></Lang>{" "}
        </Link>
      </div>

      <br />
      <br />
      {/* <ImageCarusel images={images} /> */}
      <HoverBoxSection sections={sectionsData} />
    </>
  );
};

export default MapPage;
