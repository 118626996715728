import React from 'react'
import Fa from './FontAwesome'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer>
      <nav className="navbar bg-dark border-bottom border-body py-4" data-bs-theme="dark">
        <div className="container">
          <div className="d-flex align-items-center">
            <img className='img-responsive img-footer' src="/images/EEA-and-Norway_grants_White@4x-1024x421.png" alt="" />
            <p className='text-muted text-justify m-0 p-0 ms-md-5'>
              Sustain Circuit is a project supported by a grant from Iceland, Liechtenstein and Norway through the EEA Grants Romania 2014-2021 and Norway Grants 2014-2021, in the frame of the SME Growth Programme Romania.
            </p>
          </div>
        </div>
      </nav>
      <nav className='partners-area'>
        <div className="container d-flex justify-content-between">
          <Link className='nav-link' to="/partners">
            <img className='img-responsive img-footer my-2' src="/images/partners/easi.png" alt="poză lipsă" />
          </Link>
          <Link className='nav-link' to="/partners">
            <img className='img-responsive img-footer my-2' src="/images/partners/csr.png" alt="poză lipsă" />
          </Link>
          <Link className='nav-link' to="/partners">
            <img className='img-responsive img-footer my-2' src="/images/partners/ccf.png" alt="poză lipsă" />
          </Link>
        </div>
      </nav>
      <nav className="navbar py-5 bg-dark border-bottom border-body" data-bs-theme="dark">
        <div className="container">
          <ul className='nav'>
            <li className='nav-item'><a className='nav-link' href="https://www.facebook.com/sustaincircuit" target='_blank' rel="noreferrer"><Fa regular='square-facebook fa-brands' />Facebook</a></li>
            <li className='nav-item'><a className='nav-link' href="https://www.linkedin.com/company/sustain-circuit/" target='_blank' rel="noreferrer"><Fa regular='linkedin fa-brands' />LinkedIn</a></li>
            <li className='nav-item'><Link className='nav-link' to="/newsletter"><Fa regular='newspaper' />Newsletter</Link></li>
            <li className='nav-item'><Link className='nav-link' to="/partners"><Fa regular='handshake' />Contact</Link></li>

          </ul>
        </div>
      </nav>
    </footer>
  )
}

export default Footer
