import { useState } from "react";
import { loginUser } from "../../common/user";
import { useUser } from "../../hooks/useUser";
import { Link, useParams } from "react-router-dom";
import LoginContainer from "../../components/LoginContainer";
import ErrorBox from "../../components/ErrorBox";
import Lang from "../../components/Lang";
import Loading from "../../components/Loading/Loading";
import { useQuery } from "@tanstack/react-query";

const ValidateEmailPage = () => {
  const { token } = useParams();
  const { setUser } = useUser();
  const [completed, setCompleted] = useState(false);
  const { isLoading, data, error } = useQuery({
    queryKey: ["ValidateEmailPage", token],
    queryFn: () => loginUser({ email: token || "" }),
    refetchOnWindowFocus: false,
    retry: false,
  });

  if (isLoading) return <Loading />;
  if (!error && data && !completed) {
    setUser();
    setCompleted(true);
  }

  return (
    <LoginContainer>
      {error
        ? (
          <div>
            <h5 className="card-title">
              <Lang label="Link activare cont" />
            </h5>
            <ErrorBox>{error}</ErrorBox>
          </div>)
        : (<div>
          <h5 className="card-title">
            <Lang label="Te-ai înregistrat cu succes!" />
          </h5>
          <hr />
          <Lang label="Pentru validarea contului, te rugăm să completezi chestionarul pentru înrolare.">
            <p>
              <Link to="/user/config" className="btn btn-primary">
                <Lang label="Chestionar înrolare" />
              </Link>
            </p>
          </Lang>
        </div>
        )}
    </LoginContainer>
  );
};

export default ValidateEmailPage;
