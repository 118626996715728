import { useState } from "react";
import { subscribe } from "../common/events";
import { Labels, useTranslate } from "../common/lang";

const Modal = () => {
  const [message, setMessage] = useState<null | {
    title: Labels;
    content: JSX.Element | Labels;
  }>(null);
  const translate = useTranslate();
  subscribe("DialogMessageChanged", (e) => setMessage(e.detail));
  if (!message) return <></>;
  return (
    <div className="modal fade show d-block" tabIndex={-1}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{translate(message.title || "Notificare")}</h5>
            <button type="button" className="btn-close" aria-label="Close" onClick={(e) => [e.preventDefault(), setMessage(null)]}></button>
          </div>
          <div className="modal-body">
            {
              (typeof message.content === 'string') ? <p>{translate(message.content as Labels)}</p> : message.content
            }
          </div>
          <p className="mt-0 pe-3">
          <button type="button" className="btn btn-dark float-end" onClick={(e) => [e.preventDefault(), setMessage(null)]}>{translate("Închide")}</button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Modal;
