import React from 'react'
import { Labels, useTranslate } from '../../common/lang'

const PartnerCard = ({ img = '', title = '' as Labels, details = '' as Labels, children = <></> as JSX.Element }) => {
    const translate = useTranslate()
    return (
        <div className="card">
            <img src={img} className="card-img-top" alt="..." />
            <div className="card-body">
                <h5 className="card-title">{translate(title)}</h5>
                <hr />
                <p className="card-text">{translate(details)}</p>
                {children}
            </div>
        </div>
    )
}

export default PartnerCard