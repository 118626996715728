import React from "react";
import { Labels, useTranslate } from "../common/lang";

const Title = ({ children = null as React.ReactNode, icon = "", text = "" as Labels, iconStyle = "fa-regular" }) => {
  const translate = useTranslate();
  return (
    <h4>
      {icon && <i className={`fa-lg ${iconStyle} ${icon} mx-2`}></i>}
      <span> {children || translate(text)} </span>
      <hr />
    </h4>
  );
};

export default Title;
