
import { EntityName, EntityType } from "../../../backend/src/shared/entity";
import {API_URL} from "./env";

export type SqlError = { message: string, number: string | number };

export class SqlService {
  async handleResponse(response: Response) {
    let responseJson, responseText;
    const contentType = response.headers.get("content-type");
    if (contentType && ~contentType.indexOf("application/json")) {
      responseJson = await response.json();
    } else {
      responseText = await response.text();
    }
    if (response.status === 401) {
      if (window.location.pathname.includes("/login")) return;
      window.location.href = `/login/${encodeURIComponent(window.location.pathname)}/401`;
    } else if (response.status >= 400) {
      throw responseJson || responseText;
    }
    return responseJson || responseText;
  }
  async deleteEntity(
    table: EntityName,
    id: string | number
  ) {
    const response = await fetch(`${API_URL}/entity?table=${encodeURIComponent(table)}&id=${encodeURIComponent(id)}`, {
      method: "DELETE",
      credentials: 'include'
    });
    return this.handleResponse(response);
  }
  async saveEntity<T extends EntityName>(
    table: T,
    params: { [key: string]: string | number | Date | boolean | undefined | null }
  ): Promise<EntityType<T>> {
    const response = await fetch(`${API_URL}/entity`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ table, params }),
    });
    return this.handleResponse(response);
  }
  async query(
    query: string,
    params?: { [key: string]: string | number | Date | boolean | undefined | null }
  ) {
    const response = await fetch(`${API_URL}/query`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query, params }),
    });
    return this.handleResponse(response);
  }
  getEntity<T extends EntityName>(entityName: T, entityKey: number) {
    return this.query('getEntity', { entityName, entityKey }) as Promise<EntityType<T>>;
  } 
  loginUserQuery(params: { email: string | number | Date | boolean | undefined | null, password: string | number | Date | boolean | undefined | null, auto: string | number | Date | boolean | undefined | null, role: string | number | Date | boolean | undefined | null }) {
    return this.query('loginUserQuery', params);
  }
  registerUserQuery(params: { name: string | number | Date | boolean | undefined | null, email: string | number | Date | boolean | undefined | null, password: string | number | Date | boolean | undefined | null, role: string | number | Date | boolean | undefined | null }) {
    return this.query('registerUserQuery', params);
  }
  passwordRecoverQuery(params: { email: string | number | Date | boolean | undefined | null }) {
    return this.query('passwordRecoverQuery', params);
  }
  validateTokenQuery(params: { token: string | number | Date | boolean | undefined | null }) {
    return this.query('validateTokenQuery', params);
  }
  loggedUserQuery() {
    return this.query('loggedUserQuery');
  }
  adminDashboardQuery() {
    return this.query('adminDashboardQuery');
  }
  getRecenzieListQuery() {
    return this.query('getRecenzieListQuery');
  }
  getRecenzieByIdOrganizatieQuery(params: { idOrganizatie: string | number | Date | boolean | undefined | null }) {
    return this.query('getRecenzieByIdOrganizatieQuery', params);
  }
  completareProfilOrganizatieQuery(params: { idOrganizatie: string | number | Date | boolean | undefined | null }) {
    return this.query('completareProfilOrganizatieQuery', params);
  }
  defaultCircuitQuery() {
    return this.query('defaultCircuitQuery');
  }
  profilAprobatOrganizatieQuery() {
    return this.query('profilAprobatOrganizatieQuery');
  }
  evaluareProfilOrganizatieQuery() {
    return this.query('evaluareProfilOrganizatieQuery');
  }
  contactListQuery() {
    return this.query('contactListQuery');
  }
  QAListQuery() {
    return this.query('QAListQuery');
  }
  HomePageImagesQuery() {
    return this.query('HomePageImagesQuery');
  }
  HomePageNoutatiQuery() {
    return this.query('HomePageNoutatiQuery');
  }
  NoutatiListQuery() {
    return this.query('NoutatiListQuery');
  }
  NoutatiUpdateQuery(params: { idNoutati: string | number | Date | boolean | undefined | null }) {
    return this.query('NoutatiUpdateQuery', params);
  }
  FeedbackListQuery() {
    return this.query('FeedbackListQuery');
  }
  fulltextNoutatiQuery(params: { fullText: string | number | Date | boolean | undefined | null, lang: string | number | Date | boolean | undefined | null }) {
    return this.query('fulltextNoutatiQuery', params);
  }
  
}

const sql = new SqlService()
export default sql;
