import { useQuery } from '@tanstack/react-query';
import React from 'react'
import sql from '../../common/sql';
import { ContactEntity } from '../../../../backend/src/shared/entity';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import Lang from '../../components/Lang';
import Cursuri from '../../components/Cursuri';

const CursuriIndex = () => {
  const [model, setModel] = React.useState([] as ContactEntity[]);
  const { isLoading, data } = useQuery({
    queryKey: ["ContactIndex"],
    queryFn: () => sql.contactListQuery() as Promise<ContactEntity[]>,
  });

  if (!isLoading && data && data.length > 0 && !model.length) {
    setModel(data);
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      
      <h1> <Lang label='Aceste resurse de învățare sunt pentru TINE!'></Lang></h1>
      <p></p>
        <h2><Link to="https://sustaincircuit-empowering.talentlms.com/catalog"> <Lang label='Cursurile Sustain Circuit'></Lang> </Link></h2>
              <br></br>  
              <Cursuri />
    </div>
  )
}

export default CursuriIndex