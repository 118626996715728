import { createContext, useContext, useState } from "react";
import { language } from "../common/lang";
import { API_URL } from "../common/env";

const LanguageContext = createContext<{
  lang: language;
  setLang: (lang: language) => void;
}>(null as any);

export function LanguageProvider({ children }: { children: React.ReactNode }) {
  const [lang, setLang] = useState<language>(getCookieLang());
  return (
    <LanguageContext.Provider value={{ lang, setLang }}>
      {children}
    </LanguageContext.Provider>
  );
}

export const useLang = () => {
  const { lang, setLang } = useContext(LanguageContext);
  return { lang, setLang: (lang: language) => setCookieLang(lang).then(() => setLang(getCookieLang())) };
};

const getCookieLang = () =>
  (document.cookie
    .split("; ")
    .find((row) => row.startsWith(`language=`))
    ?.split("=")[1] || "ro"
  ) as language;

const setCookieLang = async (lang: "en" | "ro") => {
  const response = await fetch(`${API_URL}/language/${lang}`);
  return await response.json();
};
