import React from 'react'

const Phones = ({ phones = "" }) => {
    return (
        <div>
            {phones.split(',').filter(e => e.trim()).map((e, i) =>
                <a key={i} href={` tel:${e}`} className='btn btn-link btn-link-list'>{e}</a>)
            }
        </div>
    )
}

export default Phones