import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoginContainer from "../../components/LoginContainer";
import Input from "../../components/Input";
import ErrorBox from "../../components/ErrorBox";
import Title from "../../components/Title";
import { SqlError } from "../../common/sql";
import { useUser } from "../../hooks/useUser";
import { LoginInfo, loginUser, logoutUser } from "../../common/user";
import { useTranslate } from "../../common/lang";

const LoginPage = () => {
  const { redirect, status } = useParams();
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const translate = useTranslate();
  const [error, setError] = useState<SqlError>();
  const [model, setModel] = useState<LoginInfo>({
    email: "",
    password: "",
  });
  const expiredSession = useRef(false);
  useEffect(() => {
    expiredSession.current = status === "401" && !!user.email;
    if (user.email) logoutUser().then((setUser));
  }, [setUser, expiredSession, status, user.email]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setModel((model) => ({ ...model, [e.target.name]: e.target.value }));
    setError(undefined);
  };
  const translateError = (error: string) => {
    const template = (str: string) => {
      // prima variabilă dintr-un string de forma "bla bla @variabilă bla bla"
      // este de la prima apariție a caracterului "@" până la primul caracter care nu este literă sau cifră
      // sau până la sfârșitul șirului dacă nu există niciun caracter care nu este literă sau cifră
      const index = str.indexOf('@');
      if (index === -1) return '';
      const start = index + 1;
      let end = start;
      while (end < str.length && /[a-zA-Z0-9]/.test(str[end])) end++;
      return '@' + str.substring(start, end);
    }

    const [message, ...values] = error.split('⟣');
    console.log(message, values);
    error = translate(message as any);
    values.forEach((value) => {
      error = error.replace(template(error), value);
    });
    return error;
  }
  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    loginUser(model)
      .then(() => {
        setUser();
        navigate(decodeURIComponent(redirect || "/user/config"));
      })
      .catch(e => setError(e.message ? translateError(e.message) : e));
  };

  return (
    <LoginContainer>
      <form>
        <Title icon="fa-user-unlock" text="Autentificare" />
        {expiredSession.current && (
          <p>
            <small style={{ color: "orangered" }}>
              {translate("Sesiunea ta a expirat, trebuie să te conectezi din nou cu email și parolă")}
            </small>
          </p>
        )}
        <Input
          type="email"
          name="email"
          required
          value={model.email}
          onChange={handleChange}
        >
          {translate("Adresa de email")}
        </Input>
        <Input
          type="password"
          name="password"
          required
          value={model.password}
          onChange={handleChange}
        >
          {translate("Parola")}
        </Input>
        <ErrorBox>{error}</ErrorBox>

        <button type="submit" onClick={handleSubmit}>
          {translate("Conectare")}
        </button>
      </form>
      <p className="justify-content-between">
        <small>
          <Link to="/password-recover"> {translate("Ai uitat parola ?")}</Link>
        </small>
      </p>
      <p>
        <small>
          {translate("Nu te-ai înregistrat ?")}<Link to="/register"> {translate("Crează-ți un cont")}</Link>
        </small>
      </p>
    </LoginContainer>
  );
};

export default LoginPage;
