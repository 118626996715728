import React from 'react'
import sql from '../../common/sql';
import { useQuery } from '@tanstack/react-query';
import { FeedbackEntity } from '../../../../backend/src/shared/entity';
import Loading from '../../components/Loading/Loading';
import FormComposer from '../../components/FormComposer/FormComposer';
import Lang from '../../components/Lang';
import { Link } from 'react-router-dom';
import Form from '../../components/Form';

const FeedbackVizualizare = () => {

    const [seleted, setSelected] = React.useState<FeedbackEntity>();

    const { isLoading, data } = useQuery({
        queryKey: ["FeedbackIndex"],
        queryFn: () => sql.FeedbackListQuery() as Promise<FeedbackEntity[]>,
    });
    const handleSelect = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, item: FeedbackEntity) => {
        e.preventDefault();
        setSelected(item);
    }
    if (isLoading) {
        return <Loading />;
    }

    if (seleted) {
        return <FeedbackVizualizareDetails selected={seleted} setSelected={setSelected} />;
    }

    return (
        <div>
            <h1>Feedback</h1>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th>#</th>
                        <th><Lang label="Valori selectate" /></th>
                        <th><Lang label="Punctele forte și punctele slabe ?" /></th>
                        <th><Lang label="Ce ați îmbunătăți ?" /></th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((item, i) => (
                        <tr key={item.Id}>
                            <td><b>{i + 1}</b></td>
                            <td><Link to={`/feedback/${item.Id}`} onClick={e => handleSelect(e, item)}>
                                {item.Valoare.split(',').filter((e, i) => [0, 1, 2, 3, 4, 7].includes(i)).join(', ')}
                            </Link></td>
                            <td><div dangerouslySetInnerHTML={{ __html: item.Valoare.split(',')[5] }}></div></td>
                            <td><div dangerouslySetInnerHTML={{ __html: item.Valoare.split(',')[6] }}></div></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div >
    )
}

export default FeedbackVizualizare

const FeedbackVizualizareDetails = ({ selected, setSelected }: { selected: FeedbackEntity, setSelected: (e: undefined | FeedbackEntity) => void }) => {
    return (
        <Form>
            <FormComposer items={JSON.parse(selected.Raspuns)} readonly />
            <button onClick={e => setSelected(undefined)}>
                <Lang label="Închide" />
            </button>
        </Form>
    );
}